import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  bankList: getCommonInitialState(),
  createBeneficiary: getCommonInitialState(),
  beneficiaryList: getCommonInitialState(),
  singleBeneficiaryView: getCommonInitialState(),
  updateBeneficiaryStatus: getCommonInitialState(),
  blockUnblockBeneficiary: getCommonInitialState(),
  deleteBeneficiary: getCommonInitialState(),
  quotesList: getCommonInitialState(),
  createQuote: getCommonInitialState(),
  singleQuoteView: getCommonInitialState(),
  beneficiaryTransactionList: getCommonInitialState(),
  beneficiaryTransactionListDownload: getCommonInitialState(),
  createTransaction: getCommonInitialState(),
  singleTransactionView: getCommonInitialState(),
  countryList: getCommonInitialState(),
  beneficiaryChart: getCommonInitialState(),
  paymentPurposeList: getCommonInitialState(),
  currencyConversion: getCommonInitialState(),
  accountStatementsList: getCommonInitialState(),
  accountStatementsDownload: getCommonInitialState(),
  singleTransactionDownload: getCommonInitialState(),
  mobileCodesList: getCommonInitialState(),
  individualCurrencyList: [],
  businessCurrencyList: [],
  currentIndividualQuoteData: {},
  currentBusinessQuoteData: {},
  sendMoneyData: {
    recipient_type: 1,
    receiving_currency: "",
    recipient_country: "",
    amount: "",
    recipient_amount: "",
    quote_type: "FORWARD",
  }
};

const BeneficiariesSlice = createSlice({
  name: "beneficiaries",
  initialState,
  reducers: {
    bankListStart: (state) => {
      state.bankList = getCommonStart();
    },
    bankListSuccess: (state, action) => {
      state.bankList = getCommonSuccess(action.payload);
    },
    bankListFailure: (state, action) => {
      state.bankList = getCommonFailure(action.payload);
    },

    createBeneficiaryStart: (state) => {
      state.createBeneficiary = getCommonStart();
    },
    createBeneficiarySuccess: (state, action) => {
      state.createBeneficiary = getCommonSuccess(action.payload);
    },
    createBeneficiaryFailure: (state, action) => {
      state.createBeneficiary = getCommonFailure(action.payload);
    },

    beneficiaryListStart: (state) => {
      state.beneficiaryList = getCommonStart();
    },
    beneficiaryListSuccess: (state, action) => {
      state.beneficiaryList = getCommonSuccess(action.payload);
    },
    beneficiaryListFailure: (state, action) => {
      state.beneficiaryList = getCommonFailure(action.payload);
    },

    singleBeneficiaryViewStart: (state) => {
      state.singleBeneficiaryView = getCommonStart();
    },
    singleBeneficiaryViewSuccess: (state, action) => {
      state.singleBeneficiaryView = getCommonSuccess(action.payload);
    },
    singleBeneficiaryViewFailure: (state, action) => {
      state.singleBeneficiaryView = getCommonFailure(action.payload);
    },

    updateBeneficiaryStatusStart: (state) => {
      state.updateBeneficiaryStatus = getCommonStart();
    },
    updateBeneficiaryStatusSuccess: (state, action) => {
      state.updateBeneficiaryStatus = getCommonSuccess(action.payload);
    },
    updateBeneficiaryStatusFailure: (state, action) => {
      state.updateBeneficiaryStatus = getCommonFailure(action.payload);
    },

    blockUnblockBeneficiaryStart: (state) => {
      state.blockUnblockBeneficiary = getCommonStart();
    },
    blockUnblockBeneficiarySuccess: (state, action) => {
      state.blockUnblockBeneficiary = getCommonSuccess(action.payload);
    },
    blockUnblockBeneficiaryFailure: (state, action) => {
      state.blockUnblockBeneficiary = getCommonFailure(action.payload);
    },

    deleteBeneficiaryStart: (state) => {
      state.deleteBeneficiary = getCommonStart();
    },
    deleteBeneficiarySuccess: (state, action) => {
      state.deleteBeneficiary = getCommonSuccess(action.payload);
    },
    deleteBeneficiaryFailure: (state, action) => {
      state.deleteBeneficiary = getCommonFailure(action.payload);
    },

    quotesListStart: (state) => {
      state.quotesList = getCommonStart();
    },
    quotesListSuccess: (state, action) => {
      state.quotesList = getCommonSuccess(action.payload);
    },
    quotesListFailure: (state, action) => {
      state.quotesList = getCommonFailure(action.payload);
    },

    createQuoteStart: (state) => {
      state.createQuote = getCommonStart();
    },
    createQuoteSuccess: (state, action) => {
      state.createQuote = getCommonSuccess(action.payload);
    },
    createQuoteFailure: (state, action) => {
      state.createQuote = getCommonFailure(action.payload);
    },

    singleQuoteViewStart: (state) => {
      state.singleQuoteView = getCommonStart();
    },
    singleQuoteViewSuccess: (state, action) => {
      state.singleQuoteView = getCommonSuccess(action.payload);
    },
    singleQuoteViewFailure: (state, action) => {
      state.singleQuoteView = getCommonFailure(action.payload);
    },

    beneficiaryTransactionListStart: (state) => {
      state.beneficiaryTransactionList = getCommonStart();
    },
    beneficiaryTransactionListSuccess: (state, action) => {
      state.beneficiaryTransactionList = getCommonSuccess(action.payload);
    },
    beneficiaryTransactionListFailure: (state, action) => {
      state.beneficiaryTransactionList = getCommonFailure(action.payload);
    },

    beneficiaryTransactionListDownloadStart: (state) => {
      state.beneficiaryTransactionListDownload = getCommonStart();
    },
    beneficiaryTransactionListDownloadSuccess: (state, action) => {
      state.beneficiaryTransactionListDownload = getCommonSuccess(action.payload);
    },
    beneficiaryTransactionListDownloadFailure: (state, action) => {
      state.beneficiaryTransactionListDownload = getCommonFailure(action.payload);
    },

    createTransactionStart: (state) => {
      state.createTransaction = getCommonStart();
    },
    createTransactionSuccess: (state, action) => {
      state.createTransaction = getCommonSuccess(action.payload);
    },
    createTransactionFailure: (state, action) => {
      state.createTransaction = getCommonFailure(action.payload);
    },

    singleTransactionViewStart: (state) => {
      state.singleTransactionView = getCommonStart();
    },
    singleTransactionViewSuccess: (state, action) => {
      state.singleTransactionView = getCommonSuccess(action.payload);
    },
    singleTransactionViewFailure: (state, action) => {
      state.singleTransactionView = getCommonFailure(action.payload);
    },

    countryListStart: (state) => {
      state.countryList = getCommonStart();
    },
    countryListSuccess: (state, action) => {
      state.countryList = getCommonSuccess(action.payload);
    },
    countryListFailure: (state, action) => {
      state.countryList = getCommonFailure(action.payload);
    },

    // Dashboard Charts
    beneficiaryChartStart: (state, action) => {
      state.beneficiaryChart = getCommonStart();
    },
    beneficiaryChartSuccess: (state, action) => {
      state.beneficiaryChart = getCommonSuccess(action.payload);
    },
    beneficiaryChartFailure: (state, action) => {
      state.beneficiaryChart = getCommonFailure(action.payload);
    },

    // Payment purpose
    paymentPurposeListStart: (state, action) => {
      state.paymentPurposeList = getCommonStart();
    },
    paymentPurposeListSuccess: (state, action) => {
      state.paymentPurposeList = getCommonSuccess(action.payload);
    },
    paymentPurposeListFailure: (state, action) => {
      state.paymentPurposeList = getCommonFailure(action.payload);
    },

    // Currency Conversison
    currencyConversionStart: (state, action) => {
      state.currencyConversion = getCommonStart();
    },
    currencyConversionSuccess: (state, action) => {
      state.currencyConversion = getCommonSuccess(action.payload);
    },
    currencyConversionFailure: (state, action) => {
      state.currencyConversion = getCommonFailure(action.payload);
    },

    // Account Statements 
    accountStatementsListStart: (state, action) => {
      state.accountStatementsList = getCommonStart();
    },
    accountStatementsListSuccess: (state, action) => {
      state.accountStatementsList = getCommonSuccess(action.payload);
    },
    accountStatementsListFailure: (state, action) => {
      state.accountStatementsList = getCommonFailure(action.payload);
    },

    accountStatementsDownloadStart: (state, action) => {
      state.accountStatementsDownload = getCommonStart();
    },
    accountStatementsDownloadSuccess: (state, action) => {
      state.accountStatementsDownload = getCommonSuccess(action.payload);
    },
    accountStatementsDownloadFailure: (state, action) => {
      state.accountStatementsDownload = getCommonFailure(action.payload);
    },

    singleTransactionDownloadStart: (state) => {
      state.singleTransactionDownload = getCommonStart();
    },
    singleTransactionDownloadSuccess: (state, action) => {
      state.singleTransactionDownload = getCommonSuccess(action.payload);
    },
    singleTransactionDownloadFailure: (state, action) => {
      state.singleTransactionDownload = getCommonFailure(action.payload);
    },

    mobileCodesListStart: (state) => {
      state.mobileCodesList = getCommonStart();
    },
    mobileCodesListSuccess: (state, action) => {
      state.mobileCodesList = getCommonSuccess(action.payload);
    },
    mobileCodesListFailure: (state, action) => {
      state.mobileCodesList = getCommonFailure(action.payload);
    },

    // Individual Currency List
    setIndividualCurrencyList: (state, action) => {
      state.individualCurrencyList = action.payload;
    },

    // Business Currency List
    setBusinessCurrencyList: (state, action) => {
      state.businessCurrencyList = action.payload;
    },  

    // Current Individual Quote Data
    setCurrentIndividualQuoteData: (state, action) => {
      state.currentIndividualQuoteData = action.payload;
    },

    // Current Business Quote Data
    setCurrentBusinessQuoteData: (state, action) => {
      state.currentBusinessQuoteData = action.payload;
    },

    // Send Money Data
    setSendMoneyData: (state, action) => {
      state.sendMoneyData = {
        ...state.sendMoneyData,
        ...action.payload
      }
    }

  },
});

export const {
  bankListStart,
  bankListSuccess,
  bankListFailure,
  createBeneficiaryStart,
  createBeneficiarySuccess,
  createBeneficiaryFailure,
  beneficiaryListStart,
  beneficiaryListSuccess,
  beneficiaryListFailure,
  singleBeneficiaryViewStart,
  singleBeneficiaryViewSuccess,
  singleBeneficiaryViewFailure,
  updateBeneficiaryStatusStart,
  updateBeneficiaryStatusSuccess,
  updateBeneficiaryStatusFailure,
  blockUnblockBeneficiaryFailure,
  blockUnblockBeneficiaryStart,
  blockUnblockBeneficiarySuccess,
  deleteBeneficiaryFailure,
  deleteBeneficiaryStart,
  deleteBeneficiarySuccess,
  quotesListStart,
  quotesListSuccess,
  quotesListFailure,
  createQuoteStart,
  createQuoteSuccess,
  createQuoteFailure,
  singleQuoteViewStart,
  singleQuoteViewSuccess,
  singleQuoteViewFailure,
  beneficiaryTransactionListFailure,
  beneficiaryTransactionListStart,
  beneficiaryTransactionListSuccess,
  beneficiaryTransactionListDownloadFailure,
  beneficiaryTransactionListDownloadStart,
  beneficiaryTransactionListDownloadSuccess,
  createTransactionStart,
  createTransactionSuccess,
  createTransactionFailure,
  singleTransactionViewStart,
  singleTransactionViewSuccess,
  singleTransactionViewFailure,
  countryListStart,
  countryListSuccess,
  countryListFailure,
  beneficiaryChartFailure,
  beneficiaryChartStart,
  beneficiaryChartSuccess,
  paymentPurposeListFailure,
  paymentPurposeListStart,
  paymentPurposeListSuccess,
  currencyConversionFailure,
  currencyConversionStart,
  currencyConversionSuccess,
  accountStatementsListFailure,
  accountStatementsListStart,
  accountStatementsListSuccess,
  accountStatementsDownloadFailure,
  accountStatementsDownloadStart,
  accountStatementsDownloadSuccess,
  singleTransactionDownloadFailure,
  singleTransactionDownloadStart,
  singleTransactionDownloadSuccess,
  setIndividualCurrencyList,
  setBusinessCurrencyList,
  setCurrentBusinessQuoteData,
  setCurrentIndividualQuoteData,
  mobileCodesListFailure,
  mobileCodesListStart,
  mobileCodesListSuccess,
  setSendMoneyData
} = BeneficiariesSlice.actions;

export default BeneficiariesSlice.reducer;
