import React, { useEffect, useState, useRef } from "react";
import { Form, Modal, Button, Image, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  beneficiaryListStart,
  createQuoteStart,
  quotesListStart,
} from "../../store/slices/BeneficiariesSlice";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import { ButtonLoader } from "../Helper/Loader";

const QuoteTransferModal = (props) => {

  const t = useTranslation("quote.quote_transfer");
  const formRef = useRef();
  const dispatch = useDispatch();
  const createQuote = useSelector((state) => state.beneficiaries.createQuote);
  const beneficiaryList = useSelector((state) => state.beneficiaries.beneficiaryList);
  const [skipRender, setSkipRender] = useState(true);
  const [beneficiary, setBeneficiary] = useState([]);
  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const validationSchema = Yup.object().shape({
    amount: Yup.number().required(t("required")).min(1, t("amount.minimum")),
    quote_type: Yup.string().required(t("required")),
    beneficiary_account_id: Yup.string().required(t("required")),
  });

  const handleSubmit = (values) => {
    dispatch(createQuoteStart(values));
  };

  useEffect(() => {
    // if (!skipRender && props.show == true) dispatch(beneficiaryListStart());
    // return () => {
    //   setSelectedBeneficiary(null);
    //   formRef.current?.resetForm();
    // };
    if(!beneficiaryList.loading && Object.keys(beneficiaryList.data).length > 0){
      let beneficiary = beneficiaryList.data.beneficiary_accounts.find(item => item.id == props.show.id);
      beneficiary && props.show.id && setSelectedBeneficiary({
        label: `${beneficiary.account_number}-${beneficiary.receiving_currency} `,
        value: props.show.id,
      })
    }
  }, [props.show]);

  useEffect(() => {
    if (
      !skipRender &&
      !createQuote.loading &&
      Object.keys(createQuote.data).length > 0
    ) {
      props.onHide();
      // dispatch(quotesListStart());
    }
    setSkipRender(false);
  }, [createQuote]);

  useEffect(() => {
    if (
      // !skipRender && 
      !beneficiaryList.loading &&
      Object.keys(beneficiaryList.data).length > 0) {
      beneficiaryList.data.total_beneficiary_accounts > 0 &&
      setBeneficiary(
        beneficiaryList.data.beneficiary_accounts.map((data) => ({
          label: `${data.account_number}-${data.receiving_currency} `,
          value: data.id,
        }))
      );
    } else {
      dispatch(beneficiaryListStart());
    }
    // setSkipRender(false);
  }, [beneficiaryList]);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#585c5f",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#0194ff",
              color: "#fff"
            },
  }),
  };

  function handleKeyPress(event) {
    if (!/^[0-9]$/.test(event.key)) {
      event.preventDefault();
    }
  }

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      className="card-sendmoney-modal"
      centered
      backdrop="static"
    >
      <Modal.Header closeButton></Modal.Header>
      <Modal.Body>
        <div className="modal-card-title">
          <h3 className="change-password-heading">{t("create_quote")}</h3>
        </div>
        <Formik
          initialValues={{
            amount: "",
            quote_type: "",
            beneficiary_account_id: props.show.id,
          }}
          innerRef={formRef}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
        >
          {({ setFieldValue, values, touched, errors, resetForm }) => (
            <FORM className="efi-bank-default-form w-100">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("amount.name")}
                    </Form.Label>
                    <div style={{display:"flex", gap: "1em"}}>
                      <Field
                        className="form-control"
                        name="amount"
                        type="number"
                        placeholder={t("amount.placeholder")}
                        onKeyPress={handleKeyPress}
                        onPaste={(event) => {
                          event.preventDefault();
                        }}
                      />
                      <div className="qoute-currency">
                        {values.quote_type ? values.quote_type == "FORWARD" ? "USD" : selectedBeneficiary.label.slice(-4) : "N/A"}
                      </div>
                    </div>
                    <ErrorMessage
                      component={"div"}
                      name="amount"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("beneficiary.name")}
                    </Form.Label>
                    <Select
                      options={beneficiary}
                      styles={customStyles}
                      value={selectedBeneficiary}
                      classNamePrefix="react-select"
                      onChange={(data) => {
                        data !== null && setSelectedBeneficiary(data);
                        setFieldValue("beneficiary_account_id", data.value);
                      }}
                      placeholder={t("beneficiary.placeholder")}
                      isSearchable= {false}
                    />
                    <ErrorMessage
                      component={"div"}
                      name="beneficiary_account_id"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("type.name")}
                    </Form.Label>
                    <Radio
                      name="answer"
                      onClick={(e) =>
                        setFieldValue("quote_type", e.target.value)
                      }
                      value="FORWARD"
                    >
                      {t("forward")}
                    </Radio>
                    <Radio
                      name="answer"
                      onClick={(e) =>
                        setFieldValue("quote_type", e.target.value)
                      }
                      value="REVERSE"
                    >
                      {t("reverse")}
                    </Radio>
                    <ErrorMessage
                      component={"div"}
                      name="quote_type"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="onboarding-auth-footer">
                <Button
                  className="efi-primary-btn w-100"
                  type="submit"
                  disabled={createQuote.buttonDisable}
                >
                  {createQuote.buttonDisable ? (
                    <ButtonLoader />
                  ) : (
                    t("create_quote")
                  )}
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </Modal.Body>
    </Modal>
  );
};

export default QuoteTransferModal;
