import { all, call, put, takeLatest, select } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import store from "../index";
import {
  b2cTransactionCreateFailure,
  b2cTransactionCreateSuccess,
  b2cTransactionsListFailure,
  b2cTransactionsListSuccess,
  b2cTransactionViewFailure,
  b2cTransactionViewSuccess,
  senderCreateFailure,
  senderCreateSuccess,
  sendersListFailure,
  sendersListSuccess,
  senderViewFailure,
  senderViewSuccess,
  senderDeleteSuccess,
  senderDeleteFailure,
  senderUpdateSuccess,
  senderUpdateFailure,
} from "../slices/SendersSlice";

function* sendersListAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders",
    payload: action.payload,
    successNotify: false,
    success: sendersListSuccess,
    failure: sendersListFailure,
  });
}

function* senderCreateAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders/store",
    payload: action.payload,
    successNotify: true,
    success: senderCreateSuccess,
    failure: senderCreateFailure,
  });
}

function* senderViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders/show",
    payload: action.payload,
    successNotify: false,
    success: senderViewSuccess,
    failure: senderViewFailure,
  });
}

function* senderDeleteAPI(action) {
  let sendersList = yield select((state) => state.senders.sendersList.data);
  yield getCommonSaga({
    apiUrl: "senders/delete",
    payload: action.payload,
    successCallback: true,
    successNotify: true,
    success: (data) => {
      if (Object.keys(sendersList).length > 0) {
        sendersList = {
          ...sendersList,
          senders: sendersList.senders.filter((item) =>
            item.id !== action.payload.sender_id

          )

        }
      }
      store.dispatch(senderDeleteSuccess({ success: true }))
      store.dispatch(sendersListSuccess(sendersList))
    },
    failure: senderDeleteFailure,
  });
}

function* senderUpdateAPI(action) {
  yield getCommonSaga({
    apiUrl: "senders/update",
    payload: action.payload,
    successNotify: true,
    success: senderUpdateSuccess,
    failure: senderUpdateFailure,
  });
}

function* b2cTransactionsListAPI(action) {
  yield getCommonSaga({
    apiUrl: "transactions",
    payload: action.payload,
    successNotify: false,
    success: b2cTransactionsListSuccess,
    failure: b2cTransactionsListFailure,
  });
}

function* b2cTransactionCreateAPI(action) {
  yield getCommonSaga({
    apiUrl: "transactions/store",
    payload: action.payload,
    successNotify: true,
    success: b2cTransactionCreateSuccess,
    failure: b2cTransactionCreateFailure,
  });
}

function* b2cTransactionViewAPI(action) {
  yield getCommonSaga({
    apiUrl: "transactions/show",
    payload: action.payload,
    successNotify: false,
    success: b2cTransactionViewSuccess,
    failure: b2cTransactionViewFailure,
  });
}



export default function* SendersSaga() {
  yield all([
    yield takeLatest("senders/sendersListStart", sendersListAPI),
    yield takeLatest("senders/senderCreateStart", senderCreateAPI),
    yield takeLatest("senders/senderViewStart", senderViewAPI),
    yield takeLatest("senders/senderDeleteStart", senderDeleteAPI),
    yield takeLatest("senders/senderUpdateStart", senderUpdateAPI),
    yield takeLatest("senders/b2cTransactionsListStart", b2cTransactionsListAPI),
    yield takeLatest("senders/b2cTransactionCreateStart", b2cTransactionCreateAPI),
    yield takeLatest("senders/b2cTransactionViewStart", b2cTransactionViewAPI),
  ]);
}
