import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { b2cTransactionsListStart } from "../../store/slices/SendersSlice";
import NoDataFound from "../Helper/NoDataFound";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import Skeleton from "react-loading-skeleton";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Pagination from "../Helper/Pagination";
import StatementModal from "./StatementModal";

const B2CTransactions = (props) => {
  
  const navigate = useNavigate();
  const profile = useSelector((state) => state.admin.profile);
  const t = useTranslation("");
  const dispatch = useDispatch();
  const b2cTransactionsList = useSelector((state) => state.senders.b2cTransactionsList);
  const [searchParams] = useSearchParams();
  const formRef = useRef(null);
  const [page, setPage] = useState(searchParams.get("page") ? searchParams.get("page") : 1);
  const [statementModal, setStatementModal] = useState(false);

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
  });

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if(profile.data.onboarding >= 4){
        dispatch(
          b2cTransactionsListStart({
            ...filter,
            skip: 12 * (page - 1),
            take: 12,
          })
        );
      } else {
        getErrorNotificationMessage("Please complete the onboarding to continue.")
      }
    }
  }, [profile, filter, page]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/b2c-transactions?${params}page=${selected + 1}`);
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-completed";
      case 2:
        return "payment-failed";
      default:
        return "payment-initiated";
    }
  };


  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>B2C Transactions</h3>
            {profile.data.onboarding >= 4 ? <div className="card-details-head-action">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                }}
                validationSchema={validationSchema}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                  });

                  navigate(
                    searchParams.get("sort_by")
                      ? `/b2c-transactions?search_key=${
                          val.search_key
                        }&sort_by=${searchParams.get("sort_by")}`
                      : `/b2c-transactions?search_key=${val.search_key}`
                  );
                }}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <FORM className="form">
                    <div className="efi-transation-search">
                      <InputGroup className="mb-0">
                        <Field
                          // placeholder={t("search_placeholder")}
                          placeholder={"Search"}
                          type="text"
                          className="form-control trans-form-control"
                          name="search_key"
                          disabled={b2cTransactionsList.loading || (!b2cTransactionsList.loading && Object.keys(b2cTransactionsList.data).length > 0 && b2cTransactionsList.data.transactions.length === 0)}
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              formRef.current.handleSubmit();
                            }
                          }}
                        />
                        {searchParams.get("search_key") ? (
                          <InputGroup.Text id="basic-addon1">
                            <Button
                              className="search-btn"
                              onClick={() => {
                                setFieldValue("search_key", "");
                                setFilter({
                                  ...filter,
                                  search_key: "",
                                });
                                navigate(
                                  searchParams.get("sort_by")
                                    ? `/b2c-transactions?sort_by=${searchParams.get(
                                        "sort_by"
                                      )}`
                                    : `/b2c-transactions`
                                );
                              }}
                            >
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                              >
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                              </svg>
                            </Button>
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text id="basic-addon1">
                            <Button className="search-btn" type="submit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                <path d="M21 21l-6 -6" />
                              </svg>
                            </Button>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </div>
                  </FORM>
                )}
              </Formik>
              {/* <Link className="efi-primary-btn" to="/add-sender">
                Add Sender
              </Link> */}
            </div> : null}
          </div>
          {b2cTransactionsList.buttonDisable ? (
            <>
              {[...Array(3)].map((_, key) => (
                <Skeleton key={key} height={50} width={"100%"} />
              ))}
            </>
          ) : Object.keys(b2cTransactionsList.data).length > 0 &&
            b2cTransactionsList.data.transactions.length > 0 ? (
            <div>
              <Table className="common-table" responsive>
                <thead className="common-table-header">
                  <tr>
                    <th>Unique Id</th>
                    <th>Name</th>
                    {/* <th>Email</th> */}
                    <th>Type</th>
                    <th>Status</th>
                    <th>Created At</th>
                    <th>Actions</th>
                  </tr>
                </thead>
                <tbody className="common-table-body">
                  {b2cTransactionsList.data.transactions.map((item, i) => (
                    <tr key={i}>
                      <td>{item.unique_id}</td>
                      <td>
                        {item.user.name}
                        {/* {item.first_name +
                          " " +
                          item.middle_name +
                          " " +
                          item.last_name} */}
                      </td>
                      <td>{item.transaction_type}</td>
                      <td>
                      <div className={getStatusClass(item.status)}>
                        {item.status_formatted}
                      </div>
                      </td>
                      <td>{item.created_at_formatted}</td>
                      <td className="delete-contact-btn-sec">
                        <Link
                          // to={`/view-sender-detail/${item.id}`}
                          onClick={()=> setStatementModal(item)}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="18"
                            height="12"
                            fill="none"
                            viewBox="0 0 18 12"
                          >
                            <path
                              fill="#171717"
                              fillRule="evenodd"
                              d="M5.875 6a3.125 3.125 0 116.25 0 3.125 3.125 0 01-6.25 0zM9 4.125a1.875 1.875 0 100 3.75 1.875 1.875 0 000-3.75z"
                              clipRule="evenodd"
                            ></path>
                            <path
                              fill="#171717"
                              fillRule="evenodd"
                              d="M2.603 4.872c-.35.503-.478.897-.478 1.128 0 .231.129.625.478 1.128.337.487.84 1.014 1.474 1.501C5.35 9.606 7.095 10.375 9 10.375c1.905 0 3.65-.77 4.923-1.746.635-.487 1.136-1.014 1.474-1.501.35-.503.478-.897.478-1.128 0-.231-.129-.625-.478-1.128-.338-.487-.84-1.014-1.474-1.501C12.65 2.394 10.905 1.625 9 1.625c-1.905 0-3.65.77-4.923 1.746-.635.487-1.137 1.014-1.474 1.501zm.713-2.493C4.758 1.273 6.763.375 9 .375s4.242.898 5.684 2.004c.722.555 1.319 1.173 1.74 1.78.41.59.701 1.239.701 1.841 0 .602-.291 1.25-.7 1.84-.422.608-1.019 1.226-1.741 1.78-1.442 1.107-3.447 2.005-5.684 2.005s-4.242-.898-5.684-2.004c-.722-.555-1.319-1.173-1.74-1.78C1.166 7.25.875 6.602.875 6c0-.602.291-1.25.7-1.84.422-.608 1.019-1.226 1.741-1.78z"
                              clipRule="evenodd"
                            ></path>
                          </svg>
                        </Link>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {b2cTransactionsList.data.total_transactions > 12 ? (
                <div className="new-billings-pagination-sec">
                  <Pagination
                    page={page}
                    pageCount={Math.ceil(
                      b2cTransactionsList.data.total_transactions / 12
                    )}
                    handlePageClick={handlePageClick}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div className="common-full-card">
              <NoDataFound />
            </div>
          )}
        </div>
      </div>
      {statementModal ? (
        <StatementModal
          show={statementModal}
          onHide={() => setStatementModal(false)}
        />
      ) : null}
    </>
  );
};
export default B2CTransactions;
