import React, { useEffect, useState } from "react";
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import Select, { components } from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import "@djthoms/pretty-checkbox";
import { useDispatch, useSelector } from "react-redux";
import {
  beneficiaryListStart,
  createTransactionStart,
  setSendMoneyData,
} from "../../store/slices/BeneficiariesSlice";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";
import Skeleton from "react-loading-skeleton";
import SelectBeneficiaryModal from "./SelectBeneficiaryModal";

const SingleBeneficiary = (props) => {
  const formRef = React.useRef();
  const dispatch = useDispatch();
  const t = useTranslation("currency_conversion");

  const beneficiaryList = useSelector((state) => state.beneficiaries.beneficiaryList);
  const createTransaction = useSelector((state) => state.beneficiaries.createTransaction);
  const sendMoneyData = useSelector((state) => state.beneficiaries.sendMoneyData);

  const [selectedBeneficiary, setSelectedBeneficiary] = useState("");
  const [selectbeneficiaryModal, setSelectBeneficiaryModal] = useState(false);

  const handleSubmit = () => {
    props.setTransferData({
      ...props.transferData,
      beneficiary_account_id: selectedBeneficiary.id,
      quote_id: props.transferData.quote.id,
    });
    props.setStep(props.step + 1);
    dispatch(
      setSendMoneyData({
        beneficiary_account_id: selectedBeneficiary.id,
      })
    );
  };

  useEffect(() => {
    if (
      !beneficiaryList.loading &&
      Object.keys(beneficiaryList.data).length > 0
    ) {
      if (sendMoneyData.beneficiary_account_id) {
        let selectedBeneficiaryType = sendMoneyData.recipient_type == 1 ? "INDIVIDUAL" : "BUSINESS";
        if(beneficiaryList.data.beneficiary_accounts.length > 0 && ((beneficiaryList.data.beneficiary_accounts[0].receiving_currency == sendMoneyData.receiving_currency) && (beneficiaryList.data.beneficiary_accounts[0].recipient_type == selectedBeneficiaryType)))
        setSelectedBeneficiary(beneficiaryList.data.beneficiary_accounts.find((item) => item.id == sendMoneyData.beneficiary_account_id));
      }
    }
  }, [sendMoneyData, beneficiaryList]);

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        sendMoneyData.beneficiary_account_id && handleSubmit();
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [sendMoneyData]);

  return (
    <>
      <Formik
        onSubmit={handleSubmit}
      >
        {({ setFieldValue, values, touched, errors, resetForm }) => (
          <FORM>
            <div className="single-transaction-beneficiary">
              <div className="single-transaction-back">
                <Button
                  type="button"
                  className="back-btn"
                  onClick={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault(); // Prevent the default action when pressing Enter
                      return;
                    }
                    props.setStep(props.step - 1);
                  }}
                  // disabled={updateAddress.buttonDisable}
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      e.preventDefault();
                    }
                  }}
                  disabled={createTransaction.buttonDisable}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="none"
                    viewBox="0 0 12 18"
                  >
                    <path
                      stroke="#000"
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      strokeWidth="3"
                      d="M10 1.5L2 9l8 7.5"
                    ></path>
                  </svg>
                </Button>
                <h3>{t("choose_beneficiary.title")}</h3>
              </div>
              <div className="single-transaction-beneficiary-select-wrap">
                <div className="single-transaction-beneficiary-select-card">
                  <h4>{t("choose_beneficiary.select_beneficiary_account")}</h4>
                  <Button
                    type="button"
                    className="beneficiary-select-btn"
                    onClick={() => setSelectBeneficiaryModal(true)}
                  >
                    {selectedBeneficiary
                      ? t("choose_beneficiary.change_benficiary")
                      : t("choose_beneficiary.select_beneficiary")}
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="15"
                      height="15"
                      fill="none"
                      viewBox="0 0 17 12"
                    >
                      <path
                        stroke="#2D93F7"
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="3"
                        d="M1.74 2.506l6.647 7.02 6.578-7.085"
                      ></path>
                    </svg>
                  </Button>
                </div>
                {selectedBeneficiary && !selectbeneficiaryModal ? (
                  <div className="single-transaction-beneficiary-bank">
                    <div className="beneficiary-bank-top">
                      <div className="beneficiary-bank-card-title">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="17"
                          height="17"
                          fill="none"
                          viewBox="0 0 17 17"
                        >
                          <path
                            fill="#CCC"
                            d="M8.043.898a4 4 0 110 8 4 4 0 010-8zm0 10c4.42 0 8 1.79 8 4v2h-16v-2c0-2.21 3.58-4 8-4z"
                          ></path>
                        </svg>
                        {selectedBeneficiary.account_name}
                      </div>
                      <div className="beneficiary-bank-card-frame">
                        <h4>{selectedBeneficiary.account_number}</h4>
                        <div className="bank-card-badge">
                          {selectedBeneficiary.recipient_country}
                        </div>
                      </div>
                    </div>
                    <div className="beneficiary-bank-space"></div>
                    <div className="beneficiary-bank-bottom">
                      <div className="beneficiary-bank-name">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="19"
                          height="19"
                          fill="none"
                          viewBox="0 0 19 19"
                        >
                          <path
                            fill="#CCC"
                            d="M2 9.382v3.664c0 .76.67 1.374 1.5 1.374S5 13.806 5 13.046V9.382c0-.76-.67-1.374-1.5-1.374S2 8.622 2 9.382zm6 0v3.664c0 .76.67 1.374 1.5 1.374s1.5-.614 1.5-1.374V9.382c0-.76-.67-1.374-1.5-1.374S8 8.622 8 9.382zM1.5 19h16c.83 0 1.5-.614 1.5-1.374 0-.76-.67-1.374-1.5-1.374h-16c-.83 0-1.5.614-1.5 1.374C0 18.386.67 19 1.5 19zM14 9.382v3.664c0 .76.67 1.374 1.5 1.374s1.5-.614 1.5-1.374V9.382c0-.76-.67-1.374-1.5-1.374S14 8.622 14 9.382zM8.57.213l-7.9 3.81C.26 4.216 0 4.61 0 5.032c0 .632.56 1.145 1.25 1.145h16.51c.68 0 1.24-.513 1.24-1.145 0-.421-.26-.815-.67-1.007L10.43.213a2.137 2.137 0 00-1.86 0z"
                          ></path>
                        </svg>
                        {selectedBeneficiary.additional_details?.service_bank}
                      </div>
                      <div className="beneficiary-bank-currency">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="25"
                          height="25"
                          fill="none"
                          viewBox="0 0 25 25"
                        >
                          <path
                            fill="url(#paint0_linear_5178_739)"
                            d="M12.5 0C5.597 0 0 5.596 0 12.5S5.597 25 12.5 25 25 19.403 25 12.5 19.403 0 12.5 0zm-.727 10.6c.226.235.591.46 1.094.673.502.213.977.435 1.423.665.445.23.83.49 1.155.782.324.292.578.632.762 1.021.184.39.275.857.275 1.403 0 .924-.293 1.678-.879 2.261-.586.584-1.385.925-2.396 1.022v1.63h-1.289v-1.638c-1.178-.125-2.082-.529-2.711-1.213-.63-.683-.944-1.584-.944-2.703h2.732c0 .616.136 1.085.409 1.406.273.322.66.483 1.163.483.368 0 .657-.11.867-.328.211-.22.316-.52.316-.904 0-.427-.105-.762-.316-1.006-.21-.243-.581-.472-1.11-.689a22.636 22.636 0 01-1.468-.656 5.098 5.098 0 01-1.16-.77 3.024 3.024 0 01-.745-1.014c-.173-.383-.26-.848-.26-1.394 0-.913.309-1.666.925-2.258.616-.592 1.438-.936 2.465-1.034V4.63h1.289v1.743c.988.146 1.765.553 2.33 1.22.565.668.847 1.515.847 2.542h-2.74c0-.562-.108-.994-.324-1.297-.216-.303-.524-.454-.924-.454-.357 0-.634.112-.832.336-.197.224-.295.534-.295.928 0 .395.113.718.34.953z"
                          ></path>
                          <defs>
                            <linearGradient
                              id="paint0_linear_5178_739"
                              x1="3.661"
                              x2="21.339"
                              y1="3.661"
                              y2="21.339"
                              gradientUnits="userSpaceOnUse"
                            >
                              <stop stopColor="#FDE560"></stop>
                              <stop offset="1" stopColor="#FF6F00"></stop>
                            </linearGradient>
                          </defs>
                        </svg>
                        {selectedBeneficiary.receiving_currency}
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
              <div className="single-transaction-beneficiary-view-card">
                <div className="beneficiary-view-card-titles">
                  <h3>{t("choose_beneficiary.fund_transfer_overview")}</h3>
                </div>
                <div className="beneficiary-view-card-wrap">
                  <div className="beneficiary-view-card-info">
                    <div className="view-card-titles-info">
                      {t("choose_beneficiary.send")}
                    </div>
                    <div className="view-card-titles-amt">
                      {props.transferData.quote.quote.converted_amount.amount +
                        " " +
                        props.transferData.quote.quote.converted_amount
                          .currency}
                    </div>
                  </div>
                  <div className="beneficiary-view-card-info">
                    <div className="view-card-titles-info">
                      {t("choose_beneficiary.receive")}
                    </div>
                    <div className="view-card-titles-amt">
                      {props.transferData.quote.quote.recipient_amount.amount +
                        " " +
                        props.transferData.quote.quote.recipient_amount
                          .currency}
                    </div>
                  </div>
                </div>
                <div className="beneficiary-fee-card-wrap">
                  <div className="beneficiary-fee-card-title">
                    <div className="fee-card-discout-head">
                      {t("choose_beneficiary.transfer_fee")}{" "}
                      <div className="fee-card-badge">
                        {(
                          (props.transferData.quote.quote.fees.platform_fee
                            .amount /
                            props.transferData.quote.quote.converted_amount
                              .amount) *
                          100
                        ).toFixed(2)}
                        %
                      </div>
                    </div>
                    <div className="fee-card-discout-amt">
                      {props.transferData.quote.quote.fees.platform_fee.amount +
                        " " +
                        props.transferData.quote.quote.fees.platform_fee
                          .currency}
                    </div>
                  </div>
                  <div className="beneficiary-fee-card-frame">
                    <div className="fee-card-info">
                      {t("choose_beneficiary.total_payable")}
                    </div>
                    <div className="fee-card-amt">
                      {props.transferData.quote.quote.charged_amount.amount +
                        " " +
                        props.transferData.quote.quote.charged_amount.currency}
                    </div>
                  </div>
                </div>
              </div>
              <div className="onboarding-auth-footer mt-3">
                <Button
                  className="efi-primary-btn w-100"
                  type="submit"
                  disabled={
                    createTransaction.buttonDisable || !selectedBeneficiary
                  }
                  onClick={handleSubmit}
                >
                  {createTransaction.buttonDisable ? (
                    <ButtonLoader />
                  ) : (
                    t("choose_beneficiary.confirm_beneficiary")
                  )}
                </Button>
              </div>
            </div>
          </FORM>
        )}
      </Formik>
      {/* </div> */}

      {selectbeneficiaryModal ? (
        <SelectBeneficiaryModal
          show={selectbeneficiaryModal}
          selectedBeneficiary={selectedBeneficiary}
          setSelectedBeneficiary={setSelectedBeneficiary}
          transferData={props.transferData}
          setStep={props.setStep}
          onHide={() => setSelectBeneficiaryModal(false)}
        />
      ) : null}
    </>
  );
};

export default SingleBeneficiary;
