import React, { useEffect, useState } from "react";
import EmptyFooter from "./Footer/EmptyFooter";
import EmptyHeader from "./Header/EmptyHeader";
import HeaderIndex from "./Header/HeaderIndex";
import { useDispatch, useSelector } from "react-redux";
import FingerprintJS from "@fingerprintjs/fingerprintjs";
import { setUserID } from "../../store/slices/AdminSlice";
import ReactLanguageSelect from "react-languages-select";
import { setLanguage, useTranslation } from "react-multi-lang";

const EmptyLayout = (props) => {
  return (
    <>
      <EmptyHeader/>

      {props.children}
      <EmptyFooter />
    </>
  );
};

export const StaticLayout = (props) => {
  return (
    <>
      <div className="admin-ui-header-sec static-page-header">
        <EmptyHeader />
      </div>
      {props.children}
    </>
  );
};

export const AuthLayout = (props) => {
  const dispatch = useDispatch();
  const userId = useSelector((state) => state.admin.userId);

  useEffect(() => {
    async function generateUserId() {
      const fpPromise = await FingerprintJS.load();
      const fp = await fpPromise;
      const result = await fp.get();
      const userId = result.visitorId;
      dispatch(setUserID(userId));
    }
    generateUserId();
  }, []);

  return userId ? <>
  <div className="auth-layout-box">
    <div className="language-select-box">
      <ReactLanguageSelect 
        defaultLanguage={localStorage.getItem("lang")} 
        languages={["en", "es"]} 
        onSelect={(option) => {
          localStorage.setItem("lang", option);
          setLanguage(option);
          window.location.reload();
        }}
      />
    </div>
    {props.children}
  </div>
  </> : null;
};

export default EmptyLayout;
