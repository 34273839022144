import React from "react";
import { Navbar, Container, Image, Button } from "react-bootstrap";
import Sidebar from "./Sidebar";
import CollapseSidebar from "./CollapseSidebar";
import { useDispatch, useSelector } from "react-redux";
import ReactJoyride from 'react-joyride';
import { tourStatusUpdateStart } from "../../../store/slices/AdminSlice";
import { useTranslation } from "react-multi-lang";

export default function SidebarIndex(props) {

  const dispatch = useDispatch();
  const t = useTranslation("sidebar.tour");

  const sidebar = useSelector((state) => state.common.sidebar);
  const profile = useSelector((state) => state.admin.profile);

  const Step1 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/dashboard-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step1.content")}</p>
      </div>
    );
  };

  const Step2 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/account-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step2.content")}</p>
      </div>
    );
  };

  const Step3 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/currency-convertion-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step3.content")}</p>
      </div>
    );
  };

  const Step4 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/send-money-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step4.content")}</p>
      </div>
    );
  };
  const Step5 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/beneficiary-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step5.content")}</p>
      </div>
    );
  };
  const Step6 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/beneficiary-transactions-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step6.content")}</p>
      </div>
    );
  };
  const Step7 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/contacts-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step7.content")}</p>
      </div>
    );
  };
  const Step8 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/contact-transactions-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step8.content")}</p>
      </div>
    );
  };
  const Step9 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/single-sender-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step8.content")}</p>
      </div>
    );
  };

  const Step10 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/balance-statements-tour.svg"}
          className="tour-step-img"
        />
        <p>{t("step8.content")}</p>
      </div>
    );
  };

  const Step11 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/employee.svg"}
          className="tour-step-img"
        />
        <p>{t("step11.content")}</p>
      </div>
    );
  };

  const Step12 = () => {
    return (
      <div className="tour-step">
        <Image
          src={window.location.origin + "/img/tour/employee-transactions.svg"}
          className="tour-step-img"
        />
        <p>{t("step12.content")}</p>
      </div>
    );
  };

  const individualSteps = [
    {
      target: ".my-first-step",
      title: t("step1.title"),
      content: <Step1 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-second-step",
      title: t("step2.title"),
      content: <Step2 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-third-step",
      title: t("step3.title"),
      content: <Step3 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-fourth-step",
      title: t("step4.title"),
      content: <Step4 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-fifth-step",
      title: t("step5.title"),
      content: <Step5 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-sixth-step",
      title: t("step6.title"),
      content: <Step6 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-seventh-step",
      title: t("step7.title"),
      content: <Step7 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-eighth-step",
      title: t("step8.title"),
      content: <Step8 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-nineth-step",
      title: t("step9.title"),
      content: <Step9 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-tenth-step",
      title: t("step10.title"),
      content: <Step10 />,
      disableBeacon: true,
      placement: "top",
    },
  ];

  const businessSteps = [
    {
      target: ".my-first-step",
      title: t("step1.title"),
      content: <Step1 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-second-step",
      title: t("step2.title"),
      content: <Step2 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-third-step",
      title: t("step3.title"),
      content: <Step3 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-fourth-step",
      title: t("step4.title"),
      content: <Step4 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-fifth-step",
      title: t("step5.title"),
      content: <Step5 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-sixth-step",
      title: t("step6.title"),
      content: <Step6 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-seventh-step",
      title: t("step7.title"),
      content: <Step7 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-eighth-step",
      title: t("step8.title"),
      content: <Step8 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-eleventh-step",
      title: t("step11.title"),
      content: <Step11 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-twelth-step",
      title: t("step12.title"),
      content: <Step12 />,
      disableBeacon: true,
      placement: "right",
    },
    {
      target: ".my-nineth-step",
      title: t("step9.title"),
      content: <Step9 />,
      disableBeacon: true,
      placement: "bottom",
    },
    {
      target: ".my-tenth-step",
      title: t("step10.title"),
      content: <Step10 />,
      disableBeacon: true,
      placement: "top",
    },
  ];

  const handleJoyrideCallback = (data) => {
    if (["next", "skip"].includes(data.action)) {
      if (
        (["skip"].includes(data.action) && data.lifecycle == "complete") ||
        (["next"].includes(data.action) && data.lifecycle == "init")
      ) {
        dispatch(tourStatusUpdateStart());
      }
    }
  };
  return (
    <>
      {profile.data.tour_status == 0 ? ( 
        <ReactJoyride
          floaterProps={{ placement: "right" }}
          steps={profile.data.user_type == 1 ? individualSteps : businessSteps}
          run={true}
          continuous={true}
          spotlightClicks={true}
          scrollToFirstStep={true}
          disableScrolling={true}
          showSkipButton
          showProgress={true}
          disableOverlayClose={true}
          callback={handleJoyrideCallback}
          hideCloseButton={true}
          autoStart
          className="joyride-dashboard"
          styles={{
            options: {
              borderRadius: "0",
              arrowColor: "#fff",
              backgroundColor: "#fff",
              overlayColor: "#312f2f",
              primaryColor: "#000",
              textColor: "#1a1a1a",
              width: 550,
              zIndex: 99999,
            },
            buttonNext: {
              fontSize: "0.9em",
              padding: "1em 1em",
              display: "inline-block",
              backgroundColor: "#0194ff",
              color: "#fff",
              border: "0",
              letterSpacing: ".5px",
              borderRadius: "0!important",
              fontWeight: "500",
              marginLeft: "16px",
              outline: "none!important",
              position: "relative",
            },
            overlay: {
              backgroundColor: "none",
              mixBlendMode: "unset",
            },
            spotlight: {
              backgroundColor: "#FFFFFF66",
              borderRadius: 0,
              boxShadow: "0px 0px 0px 9999px rgba(0,0,0,0.7)",
              marginTop: "10px",
              marginLeft: "0px",
              maxHeight: "50px",
              maxWidth: "6.1em",
            },
          }}
          {...props}
        >
        </ReactJoyride>
         ) : null}  

      {/* <div className="side-wrapped"> */}
      {sidebar ? (
        <Sidebar profile={profile} />
      ) : (
        <CollapseSidebar profile={profile} />
      )}
      {/* </div> */}
    </>
  );
}
