import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  theme: "",
  login: getCommonInitialState(),
  register: getCommonInitialState(),
  forgotPassword: getCommonInitialState(),
  resetPassword: getCommonInitialState(),
  profile: getCommonInitialState(),
  updateProfile: getCommonInitialState(),
  dashboard: getCommonInitialState(),
  dashboardChart: getCommonInitialState(),
  viewAPIKey: getCommonInitialState(),
  changePassword: getCommonInitialState(),
  verifyForgotPassword: getCommonInitialState(),
  deleteProfile: getCommonInitialState(),
  emailVerify: getCommonInitialState(),
  updateTwoStep: getCommonInitialState(),
  twoStepAuth: getCommonInitialState(),
  resendOtp: getCommonInitialState(),
  updateEmailCode: getCommonInitialState(),
  updateEmail: getCommonInitialState(),
  updatePersonalInformation: getCommonInitialState(),
  updateAddress: getCommonInitialState(),
  kycVerify: getCommonInitialState(),
  tfaEnable: getCommonInitialState(),
  tfaStatusUpdate: getCommonInitialState(),
  tfaAuthentication: getCommonInitialState(),
  tourStatusUpdate: getCommonInitialState(),
  updateServiceType: getCommonInitialState(),
  updateOnboardingDetails: getCommonInitialState(),
  logout: getCommonInitialState(),
  countriesList: getCommonInitialState(),
  nationalitiesList: getCommonInitialState(),
  mobileCodeList: getCommonInitialState(),
  acceptInviteLink: getCommonInitialState(),
  userId: null,
};

const AdminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {
    //set theme
    setTheme: (state, action) => {
      state.theme = action.payload;
    },

    //Login
    loginStart: (state, action) => {
      state.login = getCommonStart();
    },
    loginSuccess: (state, action) => {
      state.login = getCommonSuccess(action.payload);
    },
    loginFailure: (state, action) => {
      state.login = getCommonFailure(action.payload);
    },

    //Register
    registerStart: (state, action) => {
      state.register = getCommonStart();
    },
    registerSuccess: (state, action) => {
      state.register = getCommonSuccess(action.payload);
    },
    registerFailure: (state, action) => {
      state.register = getCommonFailure(action.payload);
    },

    //Forgot password
    forgotPasswordStart: (state, action) => {
      state.forgotPassword = getCommonStart();
    },
    forgotPasswordSuccess: (state, action) => {
      state.forgotPassword = getCommonSuccess(action.payload);
    },
    forgotPasswordFailure: (state, action) => {
      state.forgotPassword = getCommonFailure(action.payload);
    },

    //Reset Password
    resetPasswordStart: (state, action) => {
      state.resetPassword = getCommonStart();
    },
    resetPasswordSuccess: (state, action) => {
      state.resetPassword = getCommonSuccess(action.payload);
    },
    resetPasswordFailure: (state, action) => {
      state.resetPassword = getCommonFailure(action.payload);
    },

    //Profile
    profileStart: (state, action) => {
      state.profile = getCommonStart();
    },
    profileSuccess: (state, action) => {
      state.profile = getCommonSuccess(action.payload);
    },
    profileFailure: (state, action) => {
      state.profile = getCommonFailure(action.payload);
    },

    //Update Profile
    updateProfileStart: (state, action) => {
      state.updateProfile = getCommonStart();
    },
    updateProfileSuccess: (state, action) => {
      state.updateProfile = getCommonSuccess(action.payload);
    },
    updateProfileFailure: (state, action) => {
      state.updateProfile = getCommonFailure(action.payload);
    },

    //Dashboard
    dashboardStart: (state, action) => {
      state.dashboard = getCommonStart();
    },
    dashboardSuccess: (state, action) => {
      state.dashboard = getCommonSuccess(action.payload);
    },
    dashboardFailure: (state, action) => {
      state.dashboard = getCommonFailure(action.payload);
    },

    //Dashboard Charts
    dashboardChartStart: (state, action) => {
      state.dashboardChart = getCommonStart();
    },
    dashboardChartSuccess: (state, action) => {
      state.dashboardChart = getCommonSuccess(action.payload);
    },
    dashboardChartFailure: (state, action) => {
      state.dashboardChart = getCommonFailure(action.payload);
    },

    //View API Key
    viewAPIKeyStart: (state, action) => {
      state.viewAPIKey = getCommonStart();
    },
    viewAPIKeySuccess: (state, action) => {
      state.viewAPIKey = getCommonSuccess(action.payload);
    },
    viewAPIKeyFailure: (state, action) => {
      state.viewAPIKey = getCommonFailure(action.payload);
    },

    //Change Password
    changePasswordStart: (state, action) => {
      state.changePassword = getCommonStart();
    },
    changePasswordSuccess: (state, action) => {
      state.changePassword = getCommonSuccess(action.payload);
    },
    changePasswordFailure: (state, action) => {
      state.changePassword = getCommonFailure(action.payload);
    },

    // Verify Forgot Password
    verifyForgotPasswordStart: (state, action) => {
      state.verifyForgotPassword = getCommonStart();
    },
    verifyForgotPasswordSuccess: (state, action) => {
      state.verifyForgotPassword = getCommonSuccess(action.payload);
    },
    verifyForgotPasswordFailure: (state, action) => {
      state.verifyForgotPassword = getCommonFailure(action.payload);
    },

    //Delete Profile
    deleteProfileStart: (state, action) => {
      state.deleteProfile = getCommonStart();
    },
    deleteProfileSuccess: (state, action) => {
      state.deleteProfile = getCommonSuccess(action.payload);
    },
    deleteProfileFailure: (state, action) => {
      state.deleteProfile = getCommonFailure(action.payload);
    },

    // Email Verification
    emailVerifyStart: (state) => {
      state.emailVerify = getCommonStart();
    },
    emailVerifySuccess: (state, { payload }) => {
      state.emailVerify = getCommonSuccess(payload);
    },
    emailVerifyFailure: (state, { payload }) => {
      state.emailVerify = getCommonFailure(payload);
    },

    // Two Step Auth
    twoStepAuthStart: (state) => {
      state.twoStepAuth = getCommonStart();
    },
    twoStepAuthSuccess: (state, { payload }) => {
      state.twoStepAuth = getCommonSuccess(payload);
    },
    twoStepAuthFailure: (state, { payload }) => {
      state.twoStepAuth = getCommonFailure(payload);
    },

    // Update Two step status
    updateTwoStepStart: (state) => {
      state.updateTwoStep = getCommonStart();
    },
    updateTwoStepSuccess: (state, { payload }) => {
      state.updateTwoStep = getCommonSuccess(payload);
    },
    updateTwoStepFailure: (state, { payload }) => {
      state.updateTwoStep = getCommonFailure(payload);
    },

    // Resend OTP Start
    resendOtpStart: (state) => {
      state.resendOtp = getCommonStart();
    },
    resendOtpSuccess: (state, { payload }) => {
      state.resendOtp = getCommonSuccess(payload);
    },
    resendOtpFailure: (state, { payload }) => {
      state.resendOtp = getCommonFailure(payload);
    },

    //Update Email Verification Code
    updateEmailCodeStart: (state, action) => {
      state.updateEmailCode = getCommonStart();
    },
    updateEmailCodeSuccess: (state, action) => {
      state.updateEmailCode = getCommonSuccess(action.payload);
    },
    updateEmailCodeFailure: (state, action) => {
      state.updateEmailCode = getCommonFailure(action.payload);
    },

    updateEmailStart: (state, action) => {
      state.updateEmail = getCommonStart();
    },
    updateEmailSuccess: (state, action) => {
      state.updateEmail = getCommonSuccess(action.payload);
    },
    updateEmailFailure: (state, action) => {
      state.updateEmail = getCommonFailure(action.payload);
    },

    // New Onboarding Flow
    updatePersonalInformationStart: (state, action) => {
      state.updatePersonalInformation = getCommonStart();
    },
    updatePersonalInformationSuccess: (state, action) => {
      state.updatePersonalInformation = getCommonSuccess(action.payload);
    },
    updatePersonalInformationFailure: (state, action) => {
      state.updatePersonalInformation = getCommonFailure(action.payload);
    },

    updateAddressStart: (state, action) => {
      state.updateAddress = getCommonStart();
    },
    updateAddressSuccess: (state, action) => {
      state.updateAddress = getCommonSuccess(action.payload);
    },
    updateAddressFailure: (state, action) => {
      state.updateAddress = getCommonFailure(action.payload);
    },

    verifyKycStart: (state, action) => {
      state.kycVerify = getCommonStart();
    },
    verifyKycSuccess: (state, action) => {
      state.kycVerify = getCommonSuccess(action.payload);
    },
    verifyKycFailure: (state, action) => {
      state.kycVerify = getCommonFailure(action.payload);
    },

    tfaEnableStart: (state, action) => {
      state.tfaEnable = getCommonStart();
    },
    tfaEnableSuccess: (state, action) => {
      state.tfaEnable = getCommonSuccess(action.payload);
    },
    tfaEnableFailure: (state, action) => {
      state.tfaEnable = getCommonFailure(action.payload);
    },

    tfaStatusUpdateStart: (state, action) => {
      state.tfaStatusUpdate = getCommonStart();
    },
    tfaStatusUpdateSuccess: (state, action) => {
      state.tfaStatusUpdate = getCommonSuccess(action.payload);
    },
    tfaStatusUpdateFailure: (state, action) => {
      state.tfaStatusUpdate = getCommonFailure(action.payload);
    },

    tfaAuthenticationStart: (state, action) => {
      state.tfaAuthentication = getCommonStart();
    },
    tfaAuthenticationSuccess: (state, action) => {
      state.tfaAuthentication = getCommonSuccess(action.payload);
    },
    tfaAuthenticationFailure: (state, action) => {
      state.tfaAuthentication = getCommonFailure(action.payload);
    },

    tourStatusUpdateStart: (state, action) => {
      state.tourStatusUpdate = getCommonStart();
    },
    tourStatusUpdateSuccess: (state, action) => {
      state.tourStatusUpdate = getCommonSuccess(action.payload);
    },
    tourStatusUpdateFailure: (state, action) => {
      state.tourStatusUpdate = getCommonFailure(action.payload);
    },

    updateServiceTypeStart: (state, action) => {
      state.updateServiceType = getCommonStart();
    },
    updateServiceTypeSuccess: (state, action) => {
      state.updateServiceType = getCommonSuccess(action.payload);
    },
    updateServiceTypeFailure: (state, action) => {
      state.updateServiceType = getCommonFailure(action.payload);
    },

    //Logout
    logoutStart: (state, action) => {
      state.logout = getCommonStart();
    },
    logoutSuccess: (state, action) => {
      state.logout = getCommonSuccess(action.payload);
    },
    logoutFailure: (state, action) => {
      state.logout = getCommonFailure(action.payload);
    },

    updateOnboardingDetailsStart: (state, action) => {
      state.updateOnboardingDetails = getCommonStart();
    },
    updateOnboardingDetailsSuccess: (state, action) => {
      state.updateOnboardingDetails = getCommonSuccess(action.payload);
    },
    updateOnboardingDetailsFailure: (state, action) => {
      state.updateOnboardingDetails = getCommonFailure(action.payload);
    },

    // Country, Nationality & Mobile Codes
    countriesListStart: (state, action) => {
      state.countriesList = getCommonStart();
    },
    countriesListSuccess: (state, action) => {
      state.countriesList = getCommonSuccess(action.payload);
    },
    countriesListFailure: (state, action) => {
      state.countriesList = getCommonFailure(action.payload);
    },

    nationalitiesListStart: (state, action) => {
      state.nationalitiesList = getCommonStart();
    },
    nationalitiesListSuccess: (state, action) => {
      state.nationalitiesList = getCommonSuccess(action.payload);
    },
    nationalitiesListFailure: (state, action) => {
      state.nationalitiesList = getCommonFailure(action.payload);
    },

    mobileCodeListStart: (state, action) => {
      state.mobileCodeList = getCommonStart();
    },
    mobileCodeListSuccess: (state, action) => {
      state.mobileCodeList = getCommonSuccess(action.payload);
    },
    mobileCodeListFailure: (state, action) => {
      state.mobileCodeList = getCommonFailure(action.payload);
    },

    acceptInviteLinkStart: (state, action) => {
      state.acceptInviteLink = getCommonStart();
    },
    acceptInviteLinkSuccess: (state, action) => {
      state.acceptInviteLink = getCommonSuccess(action.payload);
    },
    acceptInviteLinkFailure: (state, action) => {
      state.acceptInviteLink = getCommonFailure(action.payload);
    },

    setUserID: (state, action) => {
      state.userId = action.payload;
    },
  },
});

export const {
  setTheme,
  loginStart,
  loginSuccess,
  loginFailure,
  registerStart,
  registerSuccess,
  registerFailure,
  forgotPasswordStart,
  forgotPasswordSuccess,
  forgotPasswordFailure,
  resetPasswordStart,
  resetPasswordSuccess,
  resetPasswordFailure,
  profileStart,
  profileSuccess,
  profileFailure,
  updateProfileStart,
  updateProfileSuccess,
  updateProfileFailure,
  dashboardStart,
  dashboardSuccess,
  dashboardFailure,
  dashboardChartFailure,
  dashboardChartStart,
  dashboardChartSuccess,
  viewAPIKeyStart,
  viewAPIKeySuccess,
  viewAPIKeyFailure,
  changePasswordStart,
  changePasswordSuccess,
  changePasswordFailure,
  verifyForgotPasswordFailure,
  verifyForgotPasswordStart,
  verifyForgotPasswordSuccess,
  deleteProfileFailure,
  deleteProfileStart,
  deleteProfileSuccess,
  emailVerifyFailure,
  emailVerifyStart,
  emailVerifySuccess,
  twoStepAuthFailure,
  twoStepAuthStart,
  twoStepAuthSuccess,
  updateTwoStepFailure,
  updateTwoStepStart,
  updateTwoStepSuccess,
  resendOtpFailure,
  resendOtpStart,
  resendOtpSuccess,
  updateEmailCodeFailure,
  updateEmailCodeStart,
  updateEmailCodeSuccess,
  updateEmailFailure,
  updateEmailStart,
  updateEmailSuccess,
  updatePersonalInformationFailure,
  updatePersonalInformationStart,
  updatePersonalInformationSuccess,
  updateAddressFailure,
  updateAddressStart,
  updateAddressSuccess,
  verifyKycFailure,
  verifyKycStart,
  verifyKycSuccess,
  tfaEnableFailure,
  tfaEnableStart,
  tfaEnableSuccess,
  tfaStatusUpdateFailure,
  tfaStatusUpdateStart,
  tfaStatusUpdateSuccess,
  tfaAuthenticationFailure,
  tfaAuthenticationStart,
  tfaAuthenticationSuccess,
  tourStatusUpdateFailure,
  tourStatusUpdateStart,
  tourStatusUpdateSuccess,
  updateServiceTypeFailure,
  updateServiceTypeStart,
  updateServiceTypeSuccess,
  logoutFailure,
  logoutStart,
  logoutSuccess,
  countriesListFailure,
  countriesListStart,
  countriesListSuccess,
  nationalitiesListFailure,
  nationalitiesListStart,
  nationalitiesListSuccess,
  mobileCodeListFailure,
  mobileCodeListStart,
  mobileCodeListSuccess,
  updateOnboardingDetailsFailure,
  updateOnboardingDetailsStart,
  updateOnboardingDetailsSuccess,
  acceptInviteLinkFailure,
  acceptInviteLinkStart,
  acceptInviteLinkSuccess,
  setUserID,
} = AdminSlice.actions;

export default AdminSlice.reducer;
