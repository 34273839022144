import React, { useEffect, useState } from "react";
import {
  Image,
  Table,
  Row,
  Col,
  Button,
  Nav,
  Tab,
  Form,
  InputGroup,
} from "react-bootstrap";
import LineGraph from "./LineGraph";
import "react-circular-progressbar/dist/styles.css";
import { useDispatch, useSelector } from "react-redux";
import Skeleton from "react-loading-skeleton";
// import { transactionListStart } from "../../store/slices/PaymentSlice";
import { Link, useNavigate } from "react-router-dom";
// import CustomLazyLoad from "../../Helper/CustomLazyLoad";
import { useTranslation } from "react-multi-lang";
import CanvasJSReact from "@canvasjs/react-charts";
import RadialSeparators from "../layouts/Header/RadialSeparators";
import {
  dashboardChartStart,
  dashboardStart,
} from "../../store/slices/AdminSlice";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";
import NoDataFound from "../Helper/NoDataFound";
import { beneficiaryChartStart, beneficiaryTransactionListStart } from "../../store/slices/BeneficiariesSlice";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import NoDataFoundNew from "../Helper/NoDataFoundNew";

const DashboardIndex = () => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const t = useTranslation("dashboard");

  const dashboardAccountList = useSelector((state) => state.dashboard.dashboardAccountList);
  // const transactionList = useSelector((state) => state.payment.transactionList);
  const beneficiaryTransactionList = useSelector((state) => state.beneficiaries.beneficiaryTransactionList);
  const profile = useSelector((state) => state.admin.profile);
  const dashboard = useSelector((state) => state.admin.dashboard);
  const dashboardChart = useSelector((state) => state.admin.dashboardChart);
  const beneficiaryChart = useSelector((state) => state.beneficiaries.beneficiaryChart);

  const [skipRender, setSkipRender] = useState(true);
  const [trasactionType, setTransactionType] = useState("debit");
  const [beneficiaryTransactionType, setBeneficiaryTransactionType] = useState("debit");
  const [statistics, setStatistics] = useState();
  const [beneficiaryStatistics, setBeneficiaryStatistics] = useState()

  useEffect(() => {
    if (!profile.buttonDisable && Object.keys(profile.data).length > 0) {
      navigate(profile.data.onboarding <= 2 ? "/onboarding" : "/account");
      // navigate("/account")
    }
  }, [profile]);

  var CanvasJSChart = CanvasJSReact.CanvasJSChart;

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(dashboardStart());
      if (profile.data.onboarding == 4) {
        dispatch(beneficiaryTransactionListStart())
      }
      // dispatch(dashboardChartStart());
      // dispatch(transactionListStart());
    }
  }, []);

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 2:
        return "payment-processing";
      case 3:
        return "payment-completed";
      case 4:
        return "payment-failed";
      case 5: 
        return "payment-expired"
      default:
        return "payment-initiated";
    }
  };

  const getBeneficiaryStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 2:
        return "payment-processing";
      case 3:
        return "payment-completed";
      case 4:
        return "payment-failed";
      default:
        return "payment-initiated";
    }
  };

  const getStatusColor = (status) => {
    switch (status) {
      case 1:
        return "#5C9EEB";
      case 2:
        return "#197E23";
      case 3:
        return "#FF6A6A";
      default:
        return "#5C9EEB";
    }
  };

  // useEffect(() => {
  //   if (!profile.loading && Object.keys(profile.data).length > 0) {
  //     dispatch(
  //       dashboardChartStart({
  //         type: trasactionType,
  //       })
  //     );
  //   }
  // }, [trasactionType]);

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      dispatch(beneficiaryChartStart({
        // type: beneficiaryTransactionType,
      }))
    }
  }, [beneficiaryTransactionType]);

  useEffect(() => {
    if (
      !skipRender &&
      !beneficiaryChart.loading &&
      Object.keys(beneficiaryChart.data).length > 0
    ) {
      setBeneficiaryStatistics({
        animationEnabled: true,
        backgroundColor: "transparent",
        title: {
          text: "" 
        },
        data: [
          {
            type: "doughnut",
            showInLegend: true,  // Keep the legend to describe the data
            indexLabel: null,    // Hide the index label (text around the graph)
            yValueFormatString: "#,###''",
            dataPoints: [
              {
                name: t("initiated_transactions"),
                y: beneficiaryChart.data.statistics?.total_initiated_count,
                color: "#0194FF",
              },
              {
                name: t("processing_transactions"),
                y: beneficiaryChart.data.statistics?.total_processing_count,
                color: "#FF8A00",
              },
              {
                name: t("success_transactions"),
                y: beneficiaryChart.data.statistics?.total_success_count,
                color: "#197E23",
              },
              {
                name: t("failed_transactions"),
                y: beneficiaryChart.data.statistics?.total_failed_count,
                color: "#FF6A6A",
              },
              {
                name: t("expired_transactions"),
                y: beneficiaryChart.data.statistics?.total_expired_count,
                color: "#3e3d3d",
              },
            ],
            indexLabelFontColor: "#000",
          },
        ],
        legend: {
          fontColor: "#000",
        },
        height: 300,
      });
    }
    setSkipRender(false);
  }, [beneficiaryChart]);

  return (
    <div className="full-body-card-wrapped">
      <Row>
        {/* <Col lg={12}>
          <div className="new-onboarding mb-3">
            <div className="new-onboarding__text">
              <p>
                Hey{" "}
                <span>
                  {" "}
                  {profile.data.first_name} {profile.data.last_name},{" "}
                </span>
                Complete your pending onboarding steps now to unlock additional
                features in our banking product
              </p>
              <Button
                className="efi-overlay-btn "
                onClick={() => navigate("/user-onboarding")}
              >
                {" "}
                <span> {t("continue")} </span>
              </Button>
            </div>
            <div className="new-onboarding__image">
              <CircularProgressbarWithChildren
                value="50%"
                text={`50%`}
                strokeWidth={14}
                strokeColor="#0194ff"
                trackStrokeColor="#FDFAF2"
                styles={buildStyles({
                  strokeLinecap: "butt",
                })}
              >
                <RadialSeparators
                  count={4}
                  style={{
                    background: "#fff",
                    width: "2px",
                    height: `${14}%`,
                  }}
                />
              </CircularProgressbarWithChildren>
            </div>
          </div>
        </Col> */}

        <Col lg={12}>
          <div className="dashed-view-card">
            {dashboard.loading ? (
              <Row>
                <Col md={3}>
                  <Skeleton
                    height={250}
                    borderRadius={10}
                  />
                </Col>
                <Col md={3}>
                  <Skeleton
                    height={250}
                    borderRadius={10}
                  />
                </Col>
                <Col md={3}>
                  <Skeleton
                    height={250}
                    borderRadius={10}
                  />
                </Col>
                <Col md={3}>
                  <Skeleton
                    height={250}
                    borderRadius={10}
                  />
                </Col>
              </Row>
            ) : Object.keys(dashboard.data).length > 0 ? (
              <div className="view-card-frame dashboard-top-sec-layout">
                {/* <div className="dashed-card">
                  <div className="dashed-card__top">
                    <div className="card__top__left">
                      <h6>Total Balance</h6>
                      <div className="card__top__left-value__sec">
                        <h4>
                          {dashboard.data.m_account.m_account_balance}
                        </h4>
                        <p>{dashboard.data.m_account.m_account.currency}</p>
                      </div>
                    </div>
                    <div className="card__top__right">
                      <Image
                        className="card__top__icons"
                        src={
                          window.location.origin +
                          "/img/master-card-icons.png"
                        }
                        type="image/png"
                      />
                    </div>
                  </div>
                  <div className="dashed-card__middle">
                    <h3>
                      {dashboard.data.m_account.m_account.account_number}
                    </h3>
                  </div>
                  <div className="dashed-card__bottom">
                    <div
                      className="card__bottom__left"
                      style={{ gap: "1em" }}
                    >
                      <span>Created</span>
                      <h4>
                        {
                          dashboard.data.m_account.m_account
                            .created_at_formatted
                        }
                      </h4>
                    </div>
                    <div className="card__bottom__right">
                      <h4>EFIcyent</h4>
                    </div>
                  </div>
                </div> */}
                <div className="dashboard-account-card">
                  <div className="dashboard-account-card-header-sec">
                    <div className="dashboard-account-card-header-left-sec">
                      {/* <h3>Account Details</h3> */}
                      {/* <p className="active-badge">Active</p> */}
                      <div className="dashboard-account-body-bottom-sec">
                        <h4>{t("available_balance")}</h4>
                        <p>{dashboard.data.m_account.m_account_balance > 0 ? dashboard.data.m_account.m_account_balance : "0"} <span>{dashboard.data.m_account.m_account.currency}</span></p>
                      </div>
                    </div>
                    <div className="dashboard-account-card-header-right-sec">
                      <CopyToClipboard text={
                        `${t("available_balance")} : ` + dashboard.data.m_account.m_account_balance_formatted + "\n" +
                        `${t("account_number")} : ` + dashboard.data.virtual_account.account_number + "\n" +
                        `${t("account_holder_name")} : ` + dashboard.data.virtual_account.account_holder_name	 + "\n" +
                        `${t("bank_name")} : ` + dashboard.data.virtual_account.account_bank_name + "\n" +
                          // "Account Type : " + dashboard.data.virtual_account.account_type + "\n" 
                        // `${t("created_at")} : `+ dashboard.data.m_account.m_account.created_at_formatted + "\n" + 
                        `${t("bank_code")} : `+ dashboard.data.virtual_account.bank_code + "\n" +
                        `${t("bank_address")} : `+ dashboard.data.virtual_account.account_bank_address
                        }
                      >
                        <Button className="profile-action-btn" onClick={() => getSuccessNotificationMessage("Copied!")}>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="24"
                            height="24"
                            fill="none"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#171717"
                              d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                            ></path>
                            <path
                              fill="#171717"
                              d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                              opacity="0.4"
                            ></path>
                          </svg>
                          Copy
                        </Button>
                      </CopyToClipboard>
                    </div>
                  </div>
                  <div className="space-line"></div>
                  <div className="dashboard-account-body-sec">
                    <div className="dashboard-account-body-top-sec">
                      <div className="dashboard-account-body-top-left-sec">
                        <h4>{t("account_number")}</h4>
                        <p>{dashboard.data.virtual_account?.account_number || "N/A"}</p>
                      </div>
                      <div className="dashboard-account-body-top-right-sec">
                        <h4>{t("account_holder_name")}</h4>
                        <p>{dashboard.data.virtual_account?.account_holder_name || "N/A"}</p>
                      </div>
                    </div>
                    <div className="dashboard-account-body-middle-sec">
                      <div className="dashboard-account-body-middle-card">
                        <h4>{t("bank_name")}</h4>
                        <p>{dashboard.data.virtual_account?.account_bank_name || "N/A"}</p>
                      </div>
                      <div className="dashboard-account-body-middle-card">
                        <h4>{t("created_at")}</h4>
                        <p>{dashboard.data.virtual_account?.created_at_formatted || "N/A"}</p>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="dashboard-right-frame-card">
                  <div className="dashboard-right-frame-view-card-top-sec">
                    <div className="view-card">
                      <div className="view-card__info">
                        <p>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#fff"
                              d="M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0"
                              data-original="#e76e54"
                            ></path>
                            <path
                              fill="#0194FF"
                              d="M384 256c0 70.691-57.309 128-128 128s-128-57.309-128-128 57.309-128 128-128 128 57.309 128 128zm0 0"
                              data-original="#dd523c"
                            ></path>
                          </svg>{" "}
                          <span>{t("total_amount")}</span>
                        </p>
                        <h3>{dashboard.data.total_amount}</h3>
                      </div>
                      <div className="view-card__icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="22"
                          height="22"
                          fill="none"
                          viewBox="0 0 20 21"
                        >
                          <path
                            fill="#000"
                            d="M20 4.418v2.42c0 1.58-1 2.58-2.58 2.58H14v-6.99c0-1.11.91-2.02 2.02-2.01 1.09.01 2.09.45 2.81 1.17.72.73 1.17 1.73 1.17 2.83z"
                          ></path>
                          <path
                            fill="#000"
                            d="M0 5.418v14c0 .83.94 1.3 1.6.8l1.71-1.28c.4-.3.96-.26 1.32.1l1.66 1.67c.39.39 1.03.39 1.42 0l1.68-1.68c.35-.35.91-.39 1.3-.09l1.71 1.28c.66.49 1.6.02 1.6-.8v-17c0-1.1.9-2 2-2H4c-3 0-4 1.79-4 4v1z"
                            opacity="0.4"
                          ></path>
                          <path
                            fill="#000"
                            d="M10 10.678H7c-.41 0-.75.34-.75.75s.34.75.75.75h3c.41 0 .75-.34.75-.75s-.34-.75-.75-.75zM7 8.178h3c.41 0 .75-.34.75-.75s-.34-.75-.75-.75H7c-.41 0-.75.34-.75.75s.34.75.75.75zM3.97 6.428c-.56 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1zM3.97 10.428c-.56 0-1 .45-1 1s.45 1 1 1 1-.45 1-1-.45-1-1-1z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                    <div className="view-card">
                      <div className="view-card__info">
                        <p>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#fff"
                              d="M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0"
                              data-original="#e76e54"
                            ></path>
                            <path
                              fill="#67BCA2"
                              d="M384 256c0 70.691-57.309 128-128 128s-128-57.309-128-128 57.309-128 128-128 128 57.309 128 128zm0 0"
                              data-original="#dd523c"
                            ></path>
                          </svg>{" "}
                          <span>{t("success")}</span>
                        </p>
                        <h3>{dashboard.data.total_success_amount}</h3>
                      </div>
                      <div className="view-card__icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 23 22"
                        >
                          <path
                            fill="#000"
                            d="M21.053 4.695v1.537H0V4.695A4.362 4.362 0 014.368.337h12.316a4.362 4.362 0 014.369 4.358z"
                          ></path>
                          <path
                            fill="#000"
                            d="M0 6.232v7.853a4.353 4.353 0 004.358 4.357h6.59c.61 0 1.136-.515 1.084-1.126-.148-1.61.368-3.358 1.8-4.747a5.347 5.347 0 012.105-1.274 5.891 5.891 0 013.716.01c.684.232 1.4-.262 1.4-.989V6.232H0zm6.316 8.684H4.21a.795.795 0 01-.79-.79c0-.43.358-.789.79-.789h2.105c.431 0 .79.358.79.79 0 .431-.359.79-.79.79z"
                            opacity="0.4"
                          ></path>
                          <g clipPath="url(#clip0_4664_1812)">
                            <path
                              fill="#000"
                              d="M19.663 13.08h-3.537c-1.528 0-2.442.914-2.442 2.442v3.537c0 1.528.914 2.442 2.442 2.442h3.537c1.528 0 2.442-.914 2.442-2.442v-3.537c0-1.528-.914-2.442-2.442-2.442zm.794 2.947l-2.851 3.333a.59.59 0 01-.445.216h-.024a.618.618 0 01-.445-.18l-1.324-1.3a.624.624 0 010-.878.614.614 0 01.879 0l.842.83 2.406-2.814a.627.627 0 01.878-.073c.276.217.3.614.084.867z"
                            ></path>
                          </g>
                          <path
                            fill="#000"
                            d="M7.105 14.127c0 .431-.358.789-.79.789H4.21a.795.795 0 01-.79-.79c0-.431.359-.789.79-.789h2.106c.431 0 .79.358.79.79z"
                          ></path>
                          <defs>
                            <clipPath id="clip0_4664_1812">
                              <rect
                                width="8.421"
                                height="8.421"
                                x="13.684"
                                y="13.08"
                                fill="#fff"
                                rx="4.211"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                    </div>
                    <div className="view-card">
                      <div className="view-card__info">
                        <p>
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="20"
                            height="20"
                            enableBackground="new 0 0 512 512"
                            viewBox="0 0 512 512"
                          >
                            <path
                              fill="#fff"
                              d="M512 256c0 141.387-114.613 256-256 256S0 397.387 0 256 114.613 0 256 0s256 114.613 256 256zm0 0"
                              data-original="#e76e54"
                            ></path>
                            <path
                              fill="#FF8181"
                              d="M384 256c0 70.691-57.309 128-128 128s-128-57.309-128-128 57.309-128 128-128 128 57.309 128 128zm0 0"
                              data-original="#dd523c"
                            ></path>
                          </svg>{" "}
                          <span>{t("failed")}</span>
                        </p>
                        <h3>{dashboard.data.total_failed_amount}</h3>
                      </div>
                      <div className="view-card__icons">
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          fill="none"
                          viewBox="0 0 22 21"
                        >
                          <path
                            fill="#000"
                            d="M17.79 12.58a4.21 4.21 0 00-4.21 4.21A4.21 4.21 0 0017.79 21 4.21 4.21 0 0022 16.79a4.21 4.21 0 00-4.21-4.21zm1.684 5.947a.78.78 0 01-.558.231.78.78 0 01-.558-.231l-.558-.558-.579.579a.78.78 0 01-.558.231.78.78 0 01-.558-.231.794.794 0 010-1.116l.58-.579-.559-.558a.794.794 0 010-1.116.794.794 0 011.116 0l.558.558.526-.526a.794.794 0 011.116 0 .794.794 0 010 1.116l-.526.526.558.558a.794.794 0 010 1.116zM21.053 4.695v1.537H0V4.695A4.362 4.362 0 014.368.337h12.316a4.362 4.362 0 014.369 4.358z"
                          ></path>
                          <path
                            fill="#000"
                            d="M0 6.232v7.853a4.353 4.353 0 004.358 4.357h6.59c.61 0 1.136-.515 1.084-1.126-.148-1.61.368-3.358 1.8-4.747a5.347 5.347 0 012.105-1.274 5.891 5.891 0 013.716.01c.684.232 1.4-.262 1.4-.989V6.232H0zm6.316 8.684H4.21a.795.795 0 01-.79-.79c0-.43.358-.789.79-.789h2.105c.431 0 .79.358.79.79 0 .431-.359.79-.79.79z"
                            opacity="0.4"
                          ></path>
                          <path
                            fill="#000"
                            d="M7.105 14.127c0 .431-.358.789-.79.789H4.21a.795.795 0 01-.79-.79c0-.431.359-.789.79-.789h2.106c.431 0 .79.358.79.79z"
                          ></path>
                        </svg>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ) : null}
          </div>
        </Col>

        {/* Dashboard Chart */}

        {/* <Col lg={8} className="resp-mrg-btm-xs">
          <div className="dashed-wrapped">
            <Tab.Container id="left-tabs-example" defaultActiveKey="send">
              <div className="graph-header">
                <div className="graph-header__left">
                  <h3>Overview</h3>
                </div>
                <div className="graph-header__right">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item onClick={() => setTransactionType("debit")}>
                      <Nav.Link eventKey="send">Sent</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setTransactionType("credit")}>
                      <Nav.Link eventKey="received">Received</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </div>
              <Tab.Content>
                {dashboardChart.loading ? (
                  <React.Fragment>
                    <Skeleton height={300} />
                  </React.Fragment>
                ) : Object.keys(dashboardChart.data).length > 0 ? (
                  <React.Fragment>
                    <LineGraph
                      transactionData={
                        dashboardChart.data.last_x_days_transactions
                      }
                    />
                  </React.Fragment>
                ) : null}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>
        <Col lg={4}>
          <div className="dashed-wrapped">
            <div className="graph-header">
              <div className="graph-header__left">
                <h3>Statistics</h3>
              </div>
            </div>
            <div className="graph-card">
              {dashboardChart.loading ? (
                <React.Fragment>
                  <Skeleton height={300} />
                </React.Fragment>
              ) : Object.keys(dashboardChart.data).length > 0 && statistics ? (
                <React.Fragment>
                  <CanvasJSChart options={statistics} />
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </Col> */}

        {/* Beneficiary Chart */}

        <Col lg={8} className="resp-mrg-btm-xs">
          <div className="dashed-wrapped">
            <Tab.Container id="left-tabs-example" defaultActiveKey="send">
              <div className="graph-header">
                <div className="graph-header__left">
                  <h3>{t("overview")}</h3>
                </div>
                {/* <div className="graph-header__right">
                  <Nav variant="pills" className="flex-row">
                    <Nav.Item onClick={() => setBeneficiaryTransactionType("debit")}>
                      <Nav.Link eventKey="send">Sent</Nav.Link>
                    </Nav.Item>
                    <Nav.Item onClick={() => setBeneficiaryTransactionType("credit")}>
                      <Nav.Link eventKey="received">Received</Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div> */}
              </div>
              <Tab.Content>
                {beneficiaryChart.loading ? (
                  <div className="padding-skeleton-loader">
                    <Skeleton height={300} />
                  </div>
                ) : Object.keys(beneficiaryChart.data).length > 0 ? (
                  <React.Fragment>
                    <LineGraph
                      transactionData={beneficiaryChart.data.last_x_days_transactions}
                    />
                  </React.Fragment>
                ) : null}
              </Tab.Content>
            </Tab.Container>
          </div>
        </Col>

        <Col lg={4}>
          <div className="dashed-wrapped">
            <div className="graph-header">
              <div className="graph-header__left">
                <h3>{t("statistics")}</h3>
              </div>
            </div>
            <div className="graph-card">
              {beneficiaryChart.loading ? (
                <React.Fragment>
                  <Skeleton height={300} />
                </React.Fragment>
              ) : Object.keys(beneficiaryChart.data).length > 0 && beneficiaryStatistics ? (
                <React.Fragment>
                  {beneficiaryChart.data.statistics?.total_transactions > 0 ?
                    <CanvasJSChart options={beneficiaryStatistics} /> :
                    <div style={{ display: "flex", alignItems: "center", flexDirection: "column", gap: "2em" }}>
                      <LazyLoadImage
                        effect="blur"
                        className="create-account-avater"
                        src={window.location.origin + "/img/nodata-found-img.svg"}
                      />
                      <h5>{t("no_data_found")}</h5>
                    </div>
                  }
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </Col>

        {/* Recent Transactions  */}

        {/* <Col lg={12}>
          <div className="dashed-table">
            <div className="dashed-table__table">
              {transactionList.loading ? (
                [...Array(3)].map((i, key) => (
                  <Skeleton key={key} height={50} />
                ))
              ) : (Object.keys(transactionList.data).length > 0 && transactionList.data.m_transactions.length > 0) ? (
                <React.Fragment>
                  <div className="dashed-table__title">
                    <h3>Recent Transaction</h3>
                  </div>
                  <Table className="common-table" responsive>
                    <thead className="common-table-header">
                      <tr>
                        <th>Transactions Remarks</th>
                        <th>Recipient’s Name</th>
                        <th>Transaction ID</th>
                        <th>Date</th>
                        <th>Amount</th>
                        <th>Status</th>
                      </tr>
                    </thead>
                    <tbody className="common-table-body">
                      {transactionList.data.m_transactions.length > 0 ? (
                        transactionList.data.m_transactions
                          .slice(0, 6)
                          .map((transaction, i) => (
                            <tr key={i}>
                              <td>
                                <div className="d-flex align-items-center gap-2">
                                  <span className="efi-trade-card">
                                    {transaction.type == 1 ? (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 28 28"
                                      >
                                        <switch>
                                          <g>
                                            <path
                                              fill="#30a75f"
                                              d="M24.7 4.7L6.4 23H20c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v13.6L23.3 3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
                                              data-original="#88428d"
                                            ></path>
                                          </g>
                                        </switch>
                                      </svg>
                                    ) : (
                                      <svg
                                        xmlns="http://www.w3.org/2000/svg"
                                        width="24"
                                        height="24"
                                        enableBackground="new 0 0 512 512"
                                        viewBox="0 0 28 28"
                                        className="rote"
                                      >
                                        <switch>
                                          <g>
                                            <path
                                              fill="#EB6363"
                                              d="M24.7 4.7L6.4 23H20c.6 0 1 .4 1 1s-.4 1-1 1H4c-.6 0-1-.4-1-1V8c0-.6.4-1 1-1s1 .4 1 1v13.6L23.3 3.3c.4-.4 1-.4 1.4 0s.4 1 0 1.4z"
                                              data-original="#88428d"
                                            ></path>
                                          </g>
                                        </switch>
                                      </svg>
                                    )}
                                  </span>
                                  <p className="mb-0 text-fit">
                                    {" "}
                                    {transaction.description}
                                  </p>
                                </div>
                              </td>
                              <td>{transaction.name}</td>
                              <td>
                                {transaction.transaction_id ? (
                                  <>
                                    {transaction.transaction_id.slice(0, 3) +
                                      "..." +
                                      transaction.transaction_id.slice(-3)}{" "}
                                    <CopyToClipboard
                                      text={transaction.transaction_id}
                                    >
                                      <Button
                                        className="copy-btn"
                                        onClick={() =>
                                          getSuccessNotificationMessage(
                                            "Copied!"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#0194ff"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                          <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>{transaction.created_at_formatted}</td>
                              <td>
                                {transaction.type == 1 ? (
                                  <span className="color-green">
                                    + ${transaction.amount}
                                  </span>
                                ) : (
                                  <span className="color-red">
                                    - ${transaction.amount}
                                  </span>
                                )}
                              </td>
                              <td>
                                <div
                                  className={getStatusClass(transaction.status)}
                                >
                                  {transaction.status_formatted}
                                </div>
                              </td>
                            </tr>
                          ))
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            <NoDataFound />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </React.Fragment>
              ) : null}
            </div>
          </div>
        </Col> */}

        {/* Recent Beneficiary Transactions  */}

        <Col lg={12}>
          <div className="dashed-table">
            <div className="dashed-table__table">
              <div className="dashed-table__title">
                <h3>{t("recent_transaction.title")}</h3>
                {Object.keys(beneficiaryTransactionList.data).length > 0 && beneficiaryTransactionList.data.beneficiary_transactions.length > 0 ? <Link className="view-all" to="/beneficiary-transactions">{t("view_all")}</Link> : null}
              </div>
              {beneficiaryTransactionList.buttonDisable ? (
               [...Array(6)].map((_, key) => (
                  <Skeleton key={key} className="mb-3" height={80} width={"100%"} />
                ))
              ) : (Object.keys(beneficiaryTransactionList.data).length > 0 && beneficiaryTransactionList.data.beneficiary_transactions.length > 0) ? (
                <React.Fragment>
                  <Table className="common-table" responsive>
                    <thead className="common-table-header">
                      <tr>
                        <th>{t("created_date")}</th>
                        <th>{t("account_holder_name")}</th>
                        <th>{t("transaction_id")}</th>
                        <th>{t("account_number")}</th>
                        <th>{t("ifsc_code")}</th>
                        <th>{t("amount")}</th>
                        <th>{t("recipient_amount")}</th>
                        <th>{t("status")}</th>
                      </tr>
                    </thead>
                    <tbody className="common-table-body">
                      {Object.keys(beneficiaryTransactionList.data).length > 0 &&
                        beneficiaryTransactionList.data.beneficiary_transactions.length > 0 ? (
                        beneficiaryTransactionList.data.beneficiary_transactions.slice(0, 6).map(
                          (transaction, i) => (
                            <tr key={i}>
                              <td>{transaction.created_at_formatted}</td>
                              <td>{transaction.account_holder_name || "N/A"}</td>
                              <td>
                                {transaction.transaction_id ? (
                                  <>
                                    {transaction.transaction_id.slice(0, 3) +
                                      "..." +
                                      transaction.transaction_id.slice(-3)}{" "}
                                    <CopyToClipboard
                                      text={transaction.transaction_id}
                                    >
                                      <Button
                                        className="copy-btn"
                                        onClick={() =>
                                          getSuccessNotificationMessage("Copied!")
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          viewBox="0 0 24 24"
                                          fill="none"
                                          stroke="#0194ff"
                                          stroke-width="2"
                                          stroke-linecap="round"
                                          stroke-linejoin="round"
                                          class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                                        >
                                          <path
                                            stroke="none"
                                            d="M0 0h24v24H0z"
                                            fill="none"
                                          />
                                          <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                          <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </>
                                ) : (
                                  "N/A"
                                )}
                              </td>
                              <td>{transaction.account_number}</td>
                              <td>{transaction.code}</td>
                              <td>
                                {transaction.type == 1 ? (
                                  <span className="color-green">
                                    + {transaction.amount}
                                  </span>
                                ) : (
                                  <span className="color-red">
                                    - {transaction.amount}
                                  </span>
                                )}
                              </td>
                              <td>
                                {transaction.recipient_amount + " " + transaction.recipient_currency}
                              </td>
                              <td>
                                {/* <div className="settlement-status-cell"> */}
                                <div className={getStatusClass(transaction.status)}>
                                  {/* <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="10"
                                  height="10"
                                  fill={getStatusColor(
                                    transaction.status
                                  )}
                                  data-name="Layer 1"
                                  viewBox="0 0 24 24"
                                >
                                  <path d="M12 0a12 12 0 1012 12A12.013 12.013 0 0012 0z"></path>
                                </svg> */}
                                  {transaction.status_formatted}
                                </div>
                                {/* </div> */}
                              </td>
                            </tr>
                          )
                        )
                      ) : (
                        <tr>
                          <td colSpan="8" className="text-center">
                            <NoDataFound />
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </React.Fragment>
              ) : <NoDataFoundNew/>}
            </div>
          </div>
        </Col>

      </Row>
    </div>
  );
};

export default DashboardIndex;
