import React, { useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { virtualAccountStart } from "../../store/slices/AccountsSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { Link } from "react-router-dom";
import CopyToClipboard from "react-copy-to-clipboard";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage";

const VirtualAccount = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("virtual_account");

  const virtualAccount = useSelector((state) => state.accounts.virtualAccount);

  useEffect(() => {
    dispatch(virtualAccountStart());
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header">
            <h3> {t("account_details")}</h3>
          </div>
          {virtualAccount.loading ? (
            <>
              <Row>
                <Col md={6}>
                 {[...Array(4)].map((i, key) => (
                    <>
                      <Skeleton className="mb-2" key={key} width="40%" height={30} />
                      <Skeleton className="mb-3" key={key} height={50} />
                    </>
                  ))}
                </Col>
                <Col md={6}>
                  {[...Array(4)].map((i, key) => (
                    <>
                      <Skeleton className="mb-2" key={key} width="40%" height={30} />
                      <Skeleton className="mb-3" key={key} height={50} />
                    </>
                  ))}
                </Col>
              </Row>
            </>
          ) : Object.keys(virtualAccount.data).length > 0 ? (
            virtualAccount.data.is_virtual_account_exists ? (
              <React.Fragment>
                <div className="list-view-card-row">
                  <div className="list-view-card">
                    <div className="list-view-card-info">
                      <p>{t("account_id")}</p>
                      <h4>
                        {virtualAccount.data.virtual_account.account_id}&nbsp;
                        <CopyToClipboard text={virtualAccount.data.virtual_account.account_id}>
                          <Button
                            className="copy-btn"
                            onClick={() => getSuccessNotificationMessage("Copied!")}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#1f73b7"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                              </svg>
                          </Button>
                        </CopyToClipboard>
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("account_holder")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .account_holder_name
                        }
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("account_bank_address")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .account_bank_address
                        }
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("account_status")}</p>
                      <h4>{virtualAccount.data.virtual_account.status}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("created_at")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .created_at_formatted
                        }
                      </h4>
                    </div>
                  </div>
                  <div className="list-view-card">
                    <div className="list-view-card-info">
                      <p>{t("account_number")}</p>
                      <h4>
                        {virtualAccount.data.virtual_account.account_number} &nbsp;
                        <CopyToClipboard text={virtualAccount.data.virtual_account.account_number}>
                          <Button
                            className="copy-btn"
                            onClick={() => getSuccessNotificationMessage("Copied!")}>
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#1f73b7"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-copy"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M7 7m0 2.667a2.667 2.667 0 0 1 2.667 -2.667h8.666a2.667 2.667 0 0 1 2.667 2.667v8.666a2.667 2.667 0 0 1 -2.667 2.667h-8.666a2.667 2.667 0 0 1 -2.667 -2.667z" />
                                <path d="M4.012 16.737a2.005 2.005 0 0 1 -1.012 -1.737v-10c0 -1.1 .9 -2 2 -2h10c.75 0 1.158 .385 1.5 1" />
                              </svg>
                          </Button>
                        </CopyToClipboard>
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("bank_name")}</p>
                      <h4>
                        {virtualAccount.data.virtual_account.account_bank_name}
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("bank_country")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .account_bank_country
                        }
                      </h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("bank_code")}</p>
                      <h4>{virtualAccount.data.virtual_account.bank_code}</h4>
                    </div>
                    <div className="list-view-card-info">
                      <p>{t("updated_at")}</p>
                      <h4>
                        {
                          virtualAccount.data.virtual_account
                            .updated_at_formatted
                        }
                      </h4>
                    </div>
                  </div>
                </div>
              </React.Fragment>
            ) : (
              <React.Fragment>
                <div className="onboard-card">
                <LazyLoadImage
                  effect="blur"
                  className="onboarding-handle-img"
                src={window.location.origin + "/img/onboarding-handle.svg"}
              />
              <div className="onboard-card-info">
                <h5>{virtualAccount.data.note}</h5>
                <p>{t("content")}</p>
                </div>
                <Link to="/user-onboarding" className="efi-overlay-btn">
                  {t("continue")}
                </Link>
                </div>
              </React.Fragment>
            )
          ) : <NoDataFound/>}
        </div>
      </div>
    </>
  );
};
export default VirtualAccount;
