import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, InputGroup, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import {
  businessTransactionStoreStart,
  businessUserEditStart,
  businessUserListStart,
} from "../../store/slices/BusinessSlice";
import { ButtonLoader } from "../Helper/Loader";
import OtpVerification from "../Card/OtpVerification";
import { useNavigate } from "react-router-dom";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";

const filteredCountries = getCountries().filter(
  (country) =>
    ![
      "AF",
      "BY",
      "CF",
      "CN",
      "CU",
      "CD",
      "ET",
      "IN",
      "IR",
      "IQ",
      "LY",
      "ML",
      "NI",
      "KP",
      "SO",
      "SS",
      "SD",
      "SY",
      "US",
      "VE",
      "YE",
      "ZW",
    ].includes(country)
);

const EditBusinessUserModal = (props) => {

  const dispatch = useDispatch();
  const formRef = useRef();
  const navigate = useNavigate();
  const t = useTranslation("business.create");

  const businessTransactionStore = useSelector((state) => state.business.businessTransactionStore);
  const businessUserList = useSelector((state) => state.business.businessUserList);
  const profile = useSelector((state) => state.admin.profile);
  const businessUserEdit = useSelector((state) => state.business.businessUserEdit);

  const [selectedTitle, setSelectedTitle] = useState(null);
  const [contactOptions, setContactOptions] = useState([]);
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#585c5f",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    title: Yup.string().required(t("title.required")),
    first_name: Yup.string()
      .required(t("first_name.required"))
      .matches(/^\S.*$/, t("first_name.required"))
      .matches(/^[A-Za-z\s]+$/, t("first_name.invalid"))
      .trim(),
    middle_name: Yup.string()
      .matches(/^[A-Za-z\s]+$/, t("middle_name.invalid"))
      .trim(),
    last_name: Yup.string()
      .required(t("last_name.required"))
      .matches(/^\S.*$/, t("last_name.invalid"))
      .matches(/^[A-Za-z\s]+$/, t("last_name.invalid"))
      .trim(),
    email: Yup.string().email(t("email.invalid")).required(t("email.required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.invalid")),
  });

  const title_options = [
    { value: "Mr", label: "Mr" },
    { value: "Miss", label: "Miss" },
    { value: "Mrs", label: "Mrs" },
  ];

  useEffect(() => {
    if (
      !businessUserList.buttonDisable &&
      !Object.keys(businessUserList.data).length > 0
    ) {
      dispatch(businessUserListStart());
    }
  }, []);

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");
      dispatch(
        businessUserEditStart({
          ...values,
          mobile: mobile,
          mobile_country_code: countryCode,
        })
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !businessUserEdit.loading &&
      Object.keys(businessUserEdit.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [businessUserEdit]);

  return (
    <>
      <Modal
        {...props}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="card-sendmoney-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-card-title">
            <h3 className="change-password-heading">{t("edit_header")}</h3>
          </div>
          <Formik
            initialValues={{
              title: props.show.title,
              first_name: props.show.first_name,
              middle_name: props.show.middle_name,
              last_name: props.show.last_name,
              email: props.show.email,
              mobile: "+" + props.show.mobile_country_code + props.show.mobile,
              user_unique_id: props.show.user_unique_id,
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form efi-onboarding-form-after-login w-100 ">
                <Row>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        {t("title.label")} <span>*</span>
                      </Form.Label>
                      <Select
                        styles={customStyles}
                        onChange={(option) => {
                          setSelectedTitle(option);
                          setFieldValue("title", option.value);
                        }}
                        value={title_options.find(item => item.value == values.title)}
                        options={title_options}
                        name="title"
                        placeholder={<>Title</>}
                        isSearchable={false}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="title"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        {t("middle_name.label")}
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="middle_name"
                        type="text"
                        placeholder="Middle Name"
                        aria-label="text"
                        aria-describedby="text-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="middle_name"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        {t("email.label")} <span>*</span>
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="email"
                        type="email"
                        placeholder="Email"
                        aria-label="Email"
                        aria-describedby="email-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="email"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={6}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        {t("first_name.label")} <span>*</span>
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="first_name"
                        type="text"
                        placeholder="First Name"
                        aria-label="text"
                        aria-describedby="text-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="first_name"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        {t("last_name.label")} <span>*</span>
                      </Form.Label>
                      <Field
                        className="form-control"
                        name="last_name"
                        type="text"
                        placeholder="Last Name"
                        aria-label="text"
                        aria-describedby="text-addon"
                      />
                      <ErrorMessage
                        component={"div"}
                        name="last_name"
                        className="errorMsg"
                      />
                    </Form.Group>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label">
                        {t("mobile.label")} <span>*</span>
                      </Form.Label>
                      <div className="register-phone-input  employee-phone">
                        <PhoneInput
                          name="mobile"
                          defaultCountry={"AE"}
                          placeholder={t("mobile.placeholder")}
                          onChange={(value) => setFieldValue("mobile", value)}
                          onBlur={() => formRef.current.submitForm()}
                          international
                          value={values.mobile}
                          countries={filteredCountries}
                          className={`${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                        />
                      </div>
                      <ErrorMessage
                        component={"div"}
                        name="mobile"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                </Row>
                <div className="create-beneficiary-btn-sec mt-3">
                  <Button
                    className="efi-overlay-btn"
                    onClick={() => props.onHide()}
                  >
                    {t("cancel")}
                  </Button>
                  <Button
                    type="submit"
                    className="efi-primary-btn"
                    disabled={businessUserEdit.buttonDisable}
                  >
                    {businessUserEdit.buttonDisable ? (
                      <ButtonLoader />
                    ) : (
                      t("update")
                    )}
                  </Button>
                </div>
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default EditBusinessUserModal;
