import { all, fork } from "redux-saga/effects";
import AdminSaga from "./AdminSaga";
import WithdrawalSaga from "./WithdrawalSaga";
import WalletSaga from "./WalletSaga";
import PaymentSaga from "./PaymentSaga";
import ExpenseSaga from "./ExpenseSaga";
import UserAccessSaga from "./UserAccessSaga";
import DashboardSaga from "./DashboardSaga";
import AccountsSaga from "./AccountsSaga";
import PageSaga from "./PageSaga";
import CardSaga from "./CardSaga";
import BankAccountsSaga from "./BankAccountsSaga";
import BusinessSaga from "./BusinessSaga";
import BeneficiarySaga from "./BeneficiariesSaga";
import SendersSaga from "./SendersSaga";

export default function* rootSaga() {
  yield all([
    fork(AdminSaga),
    fork(WithdrawalSaga),
    fork(WalletSaga),
    fork(PaymentSaga),
    fork(ExpenseSaga),
    fork(UserAccessSaga),
    fork(DashboardSaga),
    fork(AccountsSaga),
    fork(PageSaga),
    fork(CardSaga),
    fork(BankAccountsSaga),
    fork(BusinessSaga),
    fork(BeneficiarySaga),
    fork(SendersSaga),
  ]);
}
