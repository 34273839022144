import React, { useEffect } from "react";
import { Button, Col, Row, Table } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik } from "formik";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { businessUserShowStart } from "../../store/slices/BusinessSlice";
import NoDataFound from "../Helper/NoDataFound";
import Skeleton from "react-loading-skeleton";
import { useTranslation } from "react-multi-lang";

const BusinessUserShowView = (props) => {

  const dispatch = useDispatch();
  const params = useParams();
  const t = useTranslation("business.list");

  const businessUserShow = useSelector((state) => state.business.businessUserShow);

  useEffect(() => {
    dispatch(
      businessUserShowStart({
        user_unique_id: params.id,
      })
    );
  }, []);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header">
            <Link to={-1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="24"
                height="24"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h3>{t("view_heading")}</h3>
          </div>
          {businessUserShow.loading ? (
            <>
              {[...Array(2)].map((_, key) => (
                <Skeleton key={key} height={60} width={"100%"} className="mb-3" />
              ))}
              <Row>
                <Col md={6}>
                  {[...Array(2)].map((i, key) => (
                    <>
                      <Skeleton className="mb-2" key={key} width={"40%"} height={30} />
                      <Skeleton className="mb-3" key={key} height={50} />
                    </>
                  ))}
                </Col>
                <Col md={6}>
                  {[...Array(2)].map((i, key) => (
                    <>
                      <Skeleton className="mb-2" key={key} width={"40%"} height={30} />
                      <Skeleton className="mb-3" key={key} height={50} />
                    </>
                  ))}
                </Col>
              </Row>
            </>
          ) : Object.keys(businessUserShow.data).length > 0 ? (
            Object.keys(businessUserShow.data.user).length > 0 ? (
              <React.Fragment>
            <Table className="common-table" responsive>
              <thead className="common-table-header">
                <tr>
                  <th>{t("unique_id")}</th>
                  <th>{t("name")}</th>
                  <th>{t("email")}</th>
                  <th>{t("mobile")}</th>
                </tr>
              </thead>
              <tbody className="common-table-body">
                <tr>
                  <td>{businessUserShow.data.user.user_unique_id}</td>
                  <td> 
                    {businessUserShow.data.user.title +
                      " " +
                      businessUserShow.data.user.first_name +
                      " " +
                      businessUserShow.data.user.middle_name +
                      " " +
                      businessUserShow.data.user.last_name}
                  </td>
                  <td>{businessUserShow.data.user.email}</td>
                  <td>{"+" + businessUserShow.data.user.mobile_country_code + " " + businessUserShow.data.user.mobile}</td>
                </tr>
              </tbody>
            </Table>
            <div className="list-view-card-row">
              <div className="list-view-card">
                <div className="list-view-card-info">
                  <p>{t("service_type")}</p>
                  <h4>{businessUserShow.data.user.service_type_formatted}</h4>
                </div>
                <div className="list-view-card-info">
                  <p>{t("kyc_status")}</p>
                  <h4>
                    {/* {businessUserShow.data.user.service_type == 1
                    ? businessUserShow.data.user.mastercard_kyc_status_formatted
                    : businessUserShow.data.user.iban_kyc_status_formatted} */}
                    {businessUserShow.data.user.mastercard_kyc_status_formatted}
                  </h4>
                </div>
              </div>
              <div className="list-view-card">
                <div className="list-view-card-info">
                  <p>{t("user_status")}</p>
                  <h4>{businessUserShow.data.user.status_formatted}</h4>
                </div>
                {businessUserShow.data.user.email_verified_at ? <div className="list-view-card-info">
                  <p>{t("verified_on")}</p>
                  <h4>{businessUserShow.data.user.email_verified_at}</h4>
                </div> : null}
              </div>
            </div>
          </React.Fragment>
            ) : (
              <NoDataFound />
            )
          ) : null}
        </div>
      </div>
    </>
  );
};
export default BusinessUserShowView;
