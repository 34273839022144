import React, { useEffect, useState } from "react";
import { Form, Button, Image, Modal, InputGroup } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../Helper/Loader";
import "react-lazy-load-image-component/src/effects/blur.css";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import { senderDeleteStart } from "../../store/slices/SendersSlice";
import { useTranslation } from "react-multi-lang";

const DeleteSenderModal = (props) => {

  const dispatch = useDispatch();
  const t = useTranslation("single_sender.delete");

  const senderDelete = useSelector((state) => state.senders.senderDelete);
  const [skipRender, setSkipRender] = useState(true);

  const handlesenderDelete = () => {
    dispatch(senderDeleteStart({ sender_id: props.show }));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !senderDelete.loading &&
      Object.keys(senderDelete.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [senderDelete]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        className="card-sendmoney-modal"
        centered
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="modal-card-title">
            <h3 className="change-password-heading">{t("header")}</h3>
          </div>
          <div className="transfer-successful-icon-sec logout-avater mb-4">
            <svg
              width="66"
              height="66"
              viewBox="0 0 66 66"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <circle
                cx="33"
                cy="33"
                r="33"
                fill="#D8D8D8"
                fill-opacity="0.17"
              />
              <path
                d="M33 51.8236C43.396 51.8236 51.8236 43.396 51.8236 33C51.8236 22.6041 43.396 14.1765 33 14.1765C22.6041 14.1765 14.1765 22.6041 14.1765 33C14.1765 43.396 22.6041 51.8236 33 51.8236Z"
                fill="#FF4830"
              />
              <path
                d="M48.9647 23.0236C45.6353 28.3353 39.7294 31.8648 33 31.8648C26.2706 31.8648 20.3647 28.3353 17.0353 23.0236C20.3588 17.7118 26.2706 14.1765 33 14.1765C39.7294 14.1765 45.6412 17.7118 48.9647 23.0236Z"
                fill="white"
                fill-opacity="0.35"
              />
              <path
                d="M26.5521 26.1562C26.5521 22.9863 29.1218 20.4166 32.2917 20.4166C35.4616 20.4166 38.0313 22.9863 38.0313 26.1562C38.0313 29.3261 35.4616 31.8958 32.2917 31.8958C29.1218 31.8958 26.5521 29.3261 26.5521 26.1562Z"
                fill="white"
              />
              <path
                d="M22.9271 39.4478C22.9271 36.2779 25.4968 33.7083 28.6667 33.7083H35.9167C37.3289 33.7083 38.6219 34.2183 39.6217 35.0641C39.0256 35.0804 38.4344 35.3161 37.9795 35.771C37.0357 36.7148 37.0357 38.2449 37.9795 39.1886L38.8339 40.0431L37.9795 40.8975C37.0357 41.8413 37.0357 43.3714 37.9795 44.3152C38.1023 44.438 38.235 44.5448 38.3752 44.6357C37.63 44.9895 36.7965 45.1874 35.9167 45.1874H28.6667C25.4968 45.1874 22.9271 42.6178 22.9271 39.4478Z"
                fill="white"
              />
              <path
                d="M45.4557 38.1207C45.8096 37.7668 45.8096 37.193 45.4557 36.839C45.1018 36.4851 44.5279 36.4851 44.174 36.839L42.2517 38.7615L40.3292 36.839C39.9753 36.4851 39.4015 36.4851 39.0475 36.839C38.6936 37.193 38.6936 37.7668 39.0475 38.1207L40.97 40.0431L39.0475 41.9655C38.6936 42.3194 38.6936 42.8933 39.0475 43.2472C39.4015 43.6011 39.9753 43.6011 40.3292 43.2472L42.2517 41.3247L44.174 43.2472C44.5279 43.6011 45.1018 43.6011 45.4557 43.2472C45.8096 42.8933 45.8096 42.3194 45.4557 41.9655L43.5332 40.0431L45.4557 38.1207Z"
                fill="white"
              />
            </svg>
            <h5 className="mb-3 mt-3">{t('content')}</h5>
          </div>
          <div className="efi-bank-default-form w-100">
            <div className="onboarding-auth-footer">
              <Button
                className="efi-primary-btn w-100"
                type="submit"
                onClick={() => props.onHide()}
                disabled={senderDelete.buttonDisable}
              >
                {t("cancel")}
              </Button>
              <Button
                className="sender-primary-btn w-100"
                type="submit"
                onClick={handlesenderDelete}
                disabled={senderDelete.buttonDisable}
              >
                {senderDelete.buttonDisable ? <ButtonLoader /> : t("delete")}
              </Button>
            </div>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default DeleteSenderModal;
