import React, { useEffect } from "react";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Row, Col, Container } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import { singleBeneficiaryViewStart } from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import iso from "iso-3166-1";

const ViewbeneficiaryDetail = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("beneficiaries.single_beneficiary_view");

  const singleBeneficiaryView = useSelector((state) => state.beneficiaries.singleBeneficiaryView);

  useEffect(() => {
    dispatch(
      singleBeneficiaryViewStart({ beneficiary_account_unique_id: params.id })
    );
  }, [params.id]);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <Row>
            <Col md={12}>
              <div className="create-beneficiary-from-back">
                <Link onClick={() => navigate(-1)} >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="26"
                    height="26"
                    data-name="Layer 2"
                    viewBox="0 0 24 24"
                  >
                    <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
                  </svg>
                  {/* {t("back")} */}
                </Link>
                <h2>{t("beneficiary_details")}</h2>
              </div>
              {singleBeneficiaryView.loading ? (
                <>
                  <div className="beneficiary-detail-box">
                    {[...Array(2)].map((item) => (
                      <Row>
                        <Col md={6}>
                          {[...Array(4)].map((i, key) => (
                            <>
                              <Skeleton className="mb-2" key={key} width="40%" height={30} />
                              <Skeleton className="mb-3" key={key} height={50} />
                            </>
                          ))}
                        </Col>
                        <Col md={6}>
                          {[...Array(4)].map((i, key) => (
                            <>
                              <Skeleton className="mb-2" key={key} width="40%" height={30} />
                              <Skeleton className="mb-3" key={key} height={50} />
                            </>
                          ))}
                        </Col>
                      </Row>
                    ))}
                  </div>
                </>
              ) : (
                <>
                  {Object.keys(singleBeneficiaryView.data).length > 0 ? (
                    <div className="beneficiary-detail-box">
                      <div className="beneficiary-detail-card">
                        <div className="beneficiary-detail-info">
                          {/* <p>{t("first_name")}</p> */}
                          <p>{t("name")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .first_name + " " + singleBeneficiaryView.data.beneficiary_account
                                .middle_name  + " " + singleBeneficiaryView.data.beneficiary_account
                                .last_name
                            }
                          </h4>
                        </div>
                        {/* <div className="beneficiary-detail-info">
                          <p>{t("last_name")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .last_name
                            }
                          </h4>
                        </div> */}
                        <div className="beneficiary-detail-info">
                          <p>{t("beneficiary_type")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .recipient_type
                            }
                          </h4>
                        </div>
                        {/* {singleBeneficiaryView.data.beneficiary_account
                          .middle_name && (
                          <React.Fragment>
                            <div className="beneficiary-detail-info">
                              <p>{t("middle_name")}</p>
                              <h4>
                                {
                                  singleBeneficiaryView.data.beneficiary_account
                                    .middle_name
                                }
                              </h4>
                            </div>
                          </React.Fragment>
                        )} */}
                        {/* <div className="beneficiary-detail-info">
                            <p>{t("user_id")}</p>
                            <h4>
                              {
                                singleBeneficiaryView.data.beneficiary_account
                                  .id
                              }
                            </h4>
                          </div> */}
                        <div className="beneficiary-detail-info">
                          <p>{t("email")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .email
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("phone_number")}</p>
                          <h4>
                            {"+"}
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .mobile_country_code
                            }
                            -
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .mobile
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("primary_address")}</p>
                          <h4>
                            {`${singleBeneficiaryView.data.beneficiary_account.additional_details.primary_address}`}
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("secondary_address")}</p>
                          <h4>
                            {` ${singleBeneficiaryView.data.beneficiary_account.additional_details.secondary_address}`}
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("city")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .additional_details.city
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("state")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .additional_details.state
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("country")}</p>
                          <h4>
                            {
                              iso.whereAlpha3(
                                singleBeneficiaryView.data.beneficiary_account
                                  .additional_details.country
                              ).country
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("zip_code")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .additional_details.postal_code
                            }
                          </h4>
                        </div>
                        {singleBeneficiaryView.data.beneficiary_account
                          .recipient_type === "BUSINESS" ? (
                          <>
                            <div className="beneficiary-detail-info">
                              <p>{t("email")}</p>
                              <h4>
                                {
                                  singleBeneficiaryView.data.beneficiary_account
                                    .email
                                }
                              </h4>
                            </div>
                            {singleBeneficiaryView.data.beneficiary_account.additional_details.registered_org_name ?
                             <div className="beneficiary-detail-info">
                              <p>{t("registered_organisation_name")}</p>
                              <h4>
                                {
                                  singleBeneficiaryView.data.beneficiary_account.additional_details.registered_org_name
                                }
                              </h4>
                            </div> : null}
                          </>
                        ) : null}
                        {/* <div className="beneficiary-detail-info">
                            <p>Full Address</p>
                            <h4>
                              57 13th Cross, Baldwins Road, Koramangala,
                              Bengaluru, Karnataka 560030
                            </h4>
                          </div> */}
                      </div>
                      <div className="beneficiary-detail-card">
                        <div className="beneficiary-detail-info">
                          <p>{t("bank_id")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .unique_id
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("account_type")}</p>
                          <h4 className="account-type-detail">
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .account_type
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("receiving_currency")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .receiving_currency
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("receiving_country")}</p>
                          <h4>
                            {
                              iso.whereAlpha3(
                                singleBeneficiaryView.data.beneficiary_account
                                  .recipient_country
                              ).country
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("recipient_type")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .recipient_type
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("purpose_of_payment")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .additional_details.purpose_of_payment
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("account_name")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .account_name
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("account_number")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .account_number
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("bank_name")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .additional_details.service_bank
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("ifsc_code")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .code
                            }
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("created_date")}</p>
                          <h4>
                            {
                              singleBeneficiaryView.data.beneficiary_account
                                .created_at_formatted
                            }
                          </h4>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <NoDataFoundNew />
                  )}
                </>
              )}
            </Col>
          </Row>
        </div>
      </div>
    </>
  );
};

export default ViewbeneficiaryDetail;
