import React, { useEffect, useState, useRef } from "react";
import { Image, Row, Col, Form, Button } from "react-bootstrap";
import Select from "react-select";
import { Checkbox, Radio, Switch } from "pretty-checkbox-react";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-multi-lang";
import { useDispatch, useSelector } from "react-redux";
import { ButtonLoader } from "../../Helper/Loader";
import * as Yup from "yup";
import "@djthoms/pretty-checkbox";
import { countriesListStart, nationalitiesListStart, updateAddressStart } from "../../../store/slices/AdminSlice";
import { countries } from "countries-list";
import isEqual from 'lodash/isEqual';

const BillingAddress = (props) => {

  const formRef = useRef();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("onboarding.onboarding_form.billing_address");

  const businessInfoUpdate = useSelector((state) => state.business.businessInfoUpdate);
  const profile = useSelector((state) => state.admin.profile);
  const updateAddress = useSelector((state) => state.admin.updateAddress);
  const countriesList = useSelector((state) => state.admin.countriesList);

  const [skipRender, setSkipRender] = useState(true);
  const [deliverySameAsBilling, setDeliverySameAsBilling] = useState(false);
  const [countryOptions, setCountryOptions] = useState([])

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "#fff!important",
      border: "1px solid #dbdbdb!important",
      borderRadius: "6px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#757575",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#0194ff",
              color: "#fff"
            },
  }),
  };

  const validationSchema = Yup.object().shape({
    billing_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_address_1.invalid"))
      .min(5, t("billing_address_1.min_length_invalid"))
      .trim(),
    billing_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_address_2.invalid"))
      .min(5, t("billing_address_2.min_length_invalid"))
      .trim(),
    billing_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_city.invalid"))
      .min(3, t("billing_city.min_length_invalid"))
      .trim(),
    billing_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_state.invalid"))
      .min(2, t("billing_state.min_length_invalid"))
      .trim(),
    billing_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_pincode.invalid"))
      .min(4, t("billing_pincode.min_length_invalid"))
      .trim(),
    billing_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("billing_country.invalid"))
      .trim(),
    delivery_primary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_1.invalid"))
      .min(5, t("delivery_address_1.min_length_invalid"))
      .trim(),
    delivery_secondary_address: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_address_2.invalid"))
      .min(5, t("delivery_address_2.min_length_invalid"))
      .trim(),
    delivery_city: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_city.invalid"))
      .min(3, t("delivery_city.min_length_invalid"))
      .trim(),
    delivery_state: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_state.invalid"))
      .min(2, t("delivery_state.min_length_invalid"))
      .trim(),
    delivery_zipcode: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_pincode.invalid"))
      .min(4, t("delivery_pincode.min_length_invalid"))
      .trim(),
    delivery_country: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("delivery_country.invalid"))
      .trim(),
  });

  const handleSubmit = (values) => {
    dispatch(updateAddressStart(values));
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updateAddress.loading &&
      Object.keys(updateAddress.data).length > 0
    ) {
      props.setStep(props.step + 1);
    }
    setSkipRender(false);
  }, [updateAddress]);

  const handleCheckboxChange = () => {
    setDeliverySameAsBilling(!deliverySameAsBilling);
    if (!deliverySameAsBilling) {
      // Copy values from billing address to delivery address fields
      const billingAddress = formRef.current.values;
      formRef.current.setValues({
        ...billingAddress,
        delivery_primary_address: billingAddress.billing_primary_address,
        delivery_secondary_address: billingAddress.billing_secondary_address,
        delivery_country: billingAddress.billing_country,
        delivery_state: billingAddress.billing_state,
        delivery_city: billingAddress.billing_city,
        delivery_zipcode: billingAddress.billing_zipcode,
      });
    }
  };

  const handleAddressChanges = () => {
    const billingValues = formRef.current.values;
    const deliveryValues = {
      delivery_primary_address: billingValues.billing_primary_address,
      delivery_secondary_address: billingValues.billing_secondary_address,
      delivery_country: billingValues.billing_country,
      delivery_state: billingValues.billing_state,
      delivery_city: billingValues.billing_city,
      delivery_zipcode: billingValues.billing_zipcode,
    };
    const addressesAreEqual = isEqual(billingValues, deliveryValues);
    setDeliverySameAsBilling(addressesAreEqual);
  };

  useEffect(() => {
    if(!profile.loading && Object.keys(profile.data).length > 0){
      const addressValues = formRef.current.values;
      const billingValues = {
        primary_address: addressValues.billing_primary_address,
        secondary_address: addressValues.billing_secondary_address,
        country: addressValues.billing_country,
        state: addressValues.billing_state,
        city: addressValues.billing_city,
        zipcode: addressValues.billing_zipcode,
      };
      const deliveryValues = {
        primary_address: addressValues.delivery_primary_address,
        secondary_address: addressValues.delivery_secondary_address,
        country: addressValues.delivery_country,
        state: addressValues.delivery_state,
        city: addressValues.delivery_city,
        zipcode: addressValues.delivery_zipcode,
      };
      if(isEqual(billingValues, deliveryValues)){
        setDeliverySameAsBilling(true)
      }
    }
  }, [profile]);

  useEffect(()=> {
    dispatch(countriesListStart({
      all: 1,
    }))
  }, []);

  useEffect(()=> {
    if(!skipRender && !countriesList.buttonDisable && Object.keys(countriesList.data).length > 0){
      if(countriesList.data.countries.length > 0){
        setCountryOptions(countriesList.data.countries.map((item)=> ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [countriesList])

  return (
    <>
      <div className="onboarding-auth billing-addres-form">
            <div className="onboarding-auth-header resp-marg-zero-1">
              <div className="onboarding-auth-titles">
                <h3>{t("address")}</h3>
                <p>{t("content")}</p>
               </div>
              <Button
                className="efi-overlay-btn"
                onClick={() => navigate("/")}
                disabled={businessInfoUpdate.buttonDisable}
              >
              
                {t("skip_onboarding")}
              </Button>
            </div>
        <Formik
          initialValues={{
            billing_primary_address:
              profile.data.billing_address?.primary_address,
            billing_secondary_address:
              profile.data.billing_address?.secondary_address,
            billing_country: profile.data.billing_address?.country,
            billing_state: profile.data.billing_address?.state,
            billing_city: profile.data.billing_address?.city,
            billing_zipcode: profile.data.billing_address?.zipcode,
            delivery_primary_address:
              profile.data.delivery_address?.primary_address,
            delivery_secondary_address:
              profile.data.delivery_address?.secondary_address,
            delivery_country: profile.data.delivery_address?.country,
            delivery_state: profile.data.delivery_address?.state,
            delivery_city: profile.data.delivery_address?.city,
            delivery_zipcode: profile.data.delivery_address?.zipcode,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ setFieldValue, values, touched, errors }) => (
            <FORM
              className="efi-bank-default-form efi-onboarding-form-after-login w-100"
              style={{ display: "flex", flexDirection: "column", gap: "1em" }}
            >
              <Row>
                <Col md={6}>
                  <div className="onboarding-auth-header resp-marg-zero-1">
                    <h3>{t("billing_address")}</h3>
                    {/* <p>Please provide your basic details to proceed further and enjoy the banking services with us to the fullest extent.</p> */}
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("billing_address_1.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      className="form-control"
                      type="text"
                      placeholder={t("billing_address_1.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="billing_primary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="billing_primary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("billing_address_2.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("billing_address_2.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="billing_secondary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="billing_secondary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("billing_country.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setFieldValue("billing_country", selectedOption.value)
                        handleAddressChanges()
                      }
                      }
                      isSearchable
                      value={countryOptions.find(
                        (option) => option.value === values.billing_country
                      )}
                      placeholder={t("billing_country.placeholder")}
                    />
                    <ErrorMessage
                      name="billing_country"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("billing_state.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("billing_state.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="billing_state"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="billing_state"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("billing_city.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("billing_city.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="billing_city"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="billing_city"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("billing_pincode.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("billing_pincode.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="billing_zipcode"
                      onInput={handleAddressChanges}
                      maxLength={7}
                    />
                    <ErrorMessage
                      name="billing_zipcode"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={6}>
                  <div className="onboarding-auth-header d-flex align-items-center justify-content-between">
                    <h3> {t("delivery_address")}</h3>
                    <Checkbox
                      checked={deliverySameAsBilling}
                      disabled={deliverySameAsBilling}
                      onChange={handleCheckboxChange}
                      icon={
                        <Image
                          src={window.location.origin + "/img/tick-mark.svg"}
                          className="tick-mark-checkbox"
                        />
                      }
                    >
                      {t("same_as_billing_addres")}
                    </Checkbox>
                  </div>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("delivery_address_1.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("delivery_address_1.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="delivery_primary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="delivery_primary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("delivery_address_2.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("delivery_address_2.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="delivery_secondary_address"
                      onInput={handleAddressChanges}
                      maxLength={30}
                    />
                    <ErrorMessage
                      name="delivery_secondary_address"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("delivery_country.name")} <span>*</span>
                    </Form.Label>
                    <Select
                      options={countryOptions}
                      styles={customStyles}
                      classNamePrefix="react-select"
                      onChange={(selectedOption) => {
                        setFieldValue("delivery_country", selectedOption.value)
                        handleAddressChanges()
                      }
                      }
                      isSearchable
                      value={countryOptions.find(
                        (option) => option.value === values.delivery_country
                      )}
                      placeholder={t("delivery_country.placeholder")}
                    />
                    <ErrorMessage
                      name="delivery_country"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("delivery_state.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("delivery_state.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="delivery_state"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="delivery_state"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("delivery_city.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("delivery_city.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="delivery_city"
                      onInput={handleAddressChanges}
                      maxLength={20}
                    />
                    <ErrorMessage
                      name="delivery_city"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      {t("delivery_pincode.name")} <span>*</span>
                    </Form.Label>
                    <Field
                      type="text"
                      className="form-control"
                      placeholder={t("delivery_pincode.placeholder")}
                      aria-label="Name"
                      aria-describedby="text-addon"
                      name="delivery_zipcode"
                      onInput={handleAddressChanges}
                      maxLength={7}
                    />
                    <ErrorMessage
                      name="delivery_zipcode"
                      component="div"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
              </Row>
              <div className="onboarding-auth-footer">
                <div className="onboarding-auth-footer-skip">
                  <Button
                    className="efi-overlay-btn"
                   
                    onClick={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();  // Prevent the default action when pressing Enter
                        return;
                      }
                      props.setStep(props.step - 1);
                    }}
                    disabled={updateAddress.buttonDisable}
                    onKeyDown={(e) => {
                      if (e.key === "Enter") {
                        e.preventDefault();  // Prevent the form submission on Enter key
                      }
                    }}
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="20"
                      height="20"
                      enableBackground="new 0 0 512 512"
                      viewBox="0 0 24 24"
                    >
                      <path
                        fill="#0194ff"
                        d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"
                        data-original="#000000"
                      ></path>
                    </svg>
                    <span>{t("back")}</span>
                  </Button>
                </div>
                <div className="space-tow">
                  <Button
                    className="efi-primary-btn"
                    type="submit"
                    disabled={updateAddress.buttonDisable}
                  // onClick={() => props.setStep(3)}
                  >
                    {updateAddress.buttonDisable ? (
                      <ButtonLoader varient="black" />
                    ) : (
                      t("continue")
                    )}
                  </Button>
                </div>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
    </>
  );
};

export default BillingAddress;
