import React, { useState, useEffect, useRef } from "react";
import { Form, Modal, Button, InputGroup, Row, Col } from "react-bootstrap";
import Select from "react-select";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { useTranslation } from "react-multi-lang";
import {
  businessTransactionStoreStart,
  businessUserListStart,
} from "../../store/slices/BusinessSlice";
import { ButtonLoader } from "../Helper/Loader";
import OtpVerification from "../Card/OtpVerification";
import { Link } from "react-router-dom";

const SendMoneyModal = (props) => {

  const t = useTranslation("cards.card_send_money");
  const dispatch = useDispatch();

  const businessTransactionStore = useSelector((state) => state.business.businessTransactionStore);
  const businessUserList = useSelector((state) => state.business.businessUserList);
  const profile = useSelector((state) => state.admin.profile);

  const [contactOptions, setContactOptions] = useState([]);
  const [skipRender, setSkipRender] = useState(true);
  const [step, setStep] = useState(1);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#585c5f",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    amount: Yup.number()
      .typeError(t("amount.invalid_type"))
      .min(0.1, t("amount.at_least"))
      .required(t("amount.required")),
    user_id: Yup.string().required(t("contact.required")),
    description: Yup.string()
      .required(t("description.required"))
      .matches(/^[A-Za-z\s]+$/, "Invalid"),
  });

  useEffect(() => {
    if (
      !businessUserList.buttonDisable &&
      !Object.keys(businessUserList.data).length > 0
    ) {
      dispatch(businessUserListStart());
    }
  }, []);

  const handleSubmit = (values) => {
    if (step == 1) {
      setStep(step + 1);
    } else {
      if (profile.data.tfa_status) {
        props.onHide();
        props.setShowOTPVerifyModal({
          ...values,
          action: 5,
        });
      } else {
        dispatch(businessTransactionStoreStart(values));
      }
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !businessTransactionStore.loading &&
      Object.keys(businessTransactionStore.data).length > 0
    ) {
      props.onHide();
    }
    setSkipRender(false);
  }, [businessTransactionStore]);

  useEffect(() => {
    if (
      // !skipRender &&
      !businessUserList.loading &&
      Object.keys(businessUserList.data).length > 0 &&
      businessUserList.data.users.length > 0
    ) {
      setContactOptions(
        businessUserList.data.users.filter((item)=> item.mastercard_kyc_status == 3).map((item) => ({
          label: item.first_name + item.last_name,
          value: item.user_id,
          id: item.id,
        }))
      );
    }
    setSkipRender(false);
  }, [businessUserList]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="card-sendmoney-modal"
        backdrop="static"
      >
        <Modal.Header closeButton></Modal.Header>
        <Modal.Body>
          <div className="payment-table-header">
            {step == 2 ? (
              <Link onClick={() => setStep(step - 1)}>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="24"
                  height="24"
                  data-name="Layer 2"
                  viewBox="0 0 24 24"
                >
                  <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
                </svg>
              </Link>
            ) : null}
            <h3 className="change-password-heading">{t("heading")}</h3>
          </div>
          <Formik
            initialValues={{
              amount: "",
              user_id: props.show.user_id,
              description: "",
            }}
            validationSchema={validationSchema}
            onSubmit={handleSubmit}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form w-100">
                {step == 1 ? (
                  <React.Fragment>
                    <Row>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="default-form-label w-100">
                            {t("amount.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            type="number"
                            placeholder={t("amount.placeholder")}
                            name="amount"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="amount"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="default-form-label w-100">
                            {t("business_user.name")} <span>*</span>
                          </Form.Label>
                          <Select
                            options={contactOptions}
                            styles={customStyles}
                            disabled={businessUserList.buttonDisable}
                            classNamePrefix="react-select"
                            onChange={(selectedOption) =>
                              setFieldValue("user_id", selectedOption.value)
                            }
                            value={contactOptions.length > 0 && contactOptions.find((option) => option.value === values.user_id )}
                            placeholder={t("select_employee")}
                          />
                          <ErrorMessage
                            component={"div"}
                            name="user_id"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="default-form-label w-100">
                            {t("description.name")} <span>*</span>
                          </Form.Label>
                          <Field
                            className="form-control"
                            as="textarea"
                            type="text"
                            placeholder={t("description.placeholder")}
                            name="description"
                          />
                          <ErrorMessage
                            component={"div"}
                            name="description"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                    </Row>
                    <div className="onboarding-auth-footer">
                      <Button
                        className="efi-primary-btn"
                        type="submit"
                        disabled={businessTransactionStore.buttonDisable}
                        style={{ width: "100%" }}
                      >
                        {businessTransactionStore.buttonDisable ? (
                          <ButtonLoader varient="black" />
                        ) : (
                          t("btn_text")
                        )}
                      </Button>
                    </div>
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="single-avater mb-3">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="80"
                        height="80"
                        viewBox="0 0 24 24"
                      >
                        <g data-name="Layer 2">
                          <path
                            fill="#2196f3"
                            d="M15.14 20L12 22l-4-3a12.22 12.22 0 01-4.89-9.78V5.33a21.29 21.29 0 004.45-1.11A22 22 0 0012 2a22 22 0 004.44 2.22 21.29 21.29 0 004.45 1.11v4.34A12.21 12.21 0 0115.14 20z"
                          ></path>
                          <path
                            fill="#176ca5"
                            d="M20.89 5.33v4.34A12.21 12.21 0 0115.14 20L12 22V2a22 22 0 004.44 2.22 21.29 21.29 0 004.45 1.11z"
                          ></path>
                        </g>
                      </svg>
                      <h3>{t("review")}</h3>
                    </div>
                    <div className="single-transaction-info-card">
                      <h4>{t("amount.name")}</h4>
                      <h5>{values.amount}</h5>
                    </div>
                    <div className="single-transaction-info-card">
                      <h4>{t("business_user.name")}</h4>
                      <h5>
                        {contactOptions.find((option) => option.value === values.user_id).label}
                      </h5>
                    </div>
                    <div className="single-transaction-info-card">
                      <h4>{t("description.name")}</h4>
                      <h5>{values.description}</h5>
                    </div>
                    <div className="onboarding-auth-footer">
                      <Button
                        className="efi-primary-btn"
                        type="submit"
                        disabled={businessTransactionStore.buttonDisable}
                        style={{ width: "100%" }}
                      >
                        {businessTransactionStore.buttonDisable ? (
                          <ButtonLoader varient="black" />
                        ) : (
                          t("btn_text")
                        )}
                      </Button>
                    </div>
                  </React.Fragment>
                )}
              </FORM>
            )}
          </Formik>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default SendMoneyModal;
