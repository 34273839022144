import React, { useEffect, useState } from "react";
import CopyToClipboard from "react-copy-to-clipboard";
import { Button, Nav, Tab } from "react-bootstrap";
import { Link, useNavigate, useLocation, useParams } from "react-router-dom";
import { Row, Col } from "react-bootstrap";
import { useSelector, useDispatch } from "react-redux";
import {
  singleTransactionDownloadStart,
  singleTransactionViewStart,
  updateBeneficiaryStatusStart,
} from "../../store/slices/BeneficiariesSlice.jsx";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew.jsx";
import { useTranslation } from "react-multi-lang";
import { getSuccessNotificationMessage } from "../Helper/NotificationMessage.jsx";
import { ButtonLoader } from "../Helper/Loader.jsx";
import iso from "iso-3166-1";
// import NoDataFound from "../Helper/NoDataFound.jsx";

const BeneficiaryView = () => {

  const params = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const t = useTranslation("beneficiaries.beneficiary_view");

  const singleTransactionView = useSelector((state) => state.beneficiaries.singleTransactionView);
  const updateBeneficiaryStatus = useSelector((state) => state.beneficiaries.updateBeneficiaryStatus);
  const singleTransactionDownload = useSelector((state) => state.beneficiaries.singleTransactionDownload);

  const [skipRender, setSkipRender] = useState(true);

  const getStatusClass = (status) => {
    switch (status) {
      case 1:
        return "payment-initiated";
      case 2:
        return "payment-processing";
      case 3:
        return "payment-completed";
      case 4:
        return "payment-failed";
      case 5:
        return "payment-expired";
      default:
        return "payment-initiated";
    }
  };

  useEffect(() => {
    dispatch(
      singleTransactionViewStart({ beneficiary_transaction_unique_id: params.id })
    );
  }, []);

  useEffect(() => {
    if (
      !skipRender &&
      !singleTransactionDownload.buttonDisable &&
      Object.keys(singleTransactionDownload.data).length > 0
    ) {
      singleTransactionDownload.data?.download_url &&
        window.open(singleTransactionDownload.data.download_url, "_blank");
    }
    setSkipRender(false);
  }, [singleTransactionDownload]);

  return (
    <div className="full-body-card-wrapped">
      <div className="beneficiary-wrapped">
        <div className="beneficiary-frame-head">
          <div className="quote-frame-titles">
            <Link to={-1}>
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="26"
                height="26"
                data-name="Layer 2"
                viewBox="0 0 24 24"
              >
                <path d="M22 11H4.414l5.293-5.293a1 1 0 10-1.414-1.414l-7 7a1 1 0 000 1.414l7 7a1 1 0 001.414-1.414L4.414 13H22a1 1 0 000-2z"></path>
              </svg>
            </Link>
            <h2>{t("heading")}</h2>
          </div>
        </div>
        <div className="beneficiary-frame-content">
          {singleTransactionView.loading ? (
            <>
              {[...Array(2)].map((item) => (
                <Skeleton height={70} className="mb-3" />
              ))}
              <Skeleton height={120} className="mb-3" />
              {/* {[...Array(3)].map((item) => (
                <Skeleton height={70} className="mb-3" />
              ))} */}
              <Skeleton height={60} borderRadius={10} className="mb-2" />
              <Skeleton height={200} borderRadius={10} />
            </>
          ) : Object.keys(singleTransactionView.data).length > 0 ? (
            <>
              <div className="beneficiary-info-card back-gray">
                <div className="beneficiary-info-head">
                  <h3>{t("transaction_details")}</h3>
                  <div style={{ display: "flex", gap: "1em" }}>
                    {[1, 2].includes(singleTransactionView.data.beneficiary_transaction
                      .status)
                      ? (
                        <Button
                          disabled={updateBeneficiaryStatus.buttonDisable}
                          className="efi-overlay-btn mt-1"
                          onClick={() => {
                            dispatch(
                              updateBeneficiaryStatusStart({
                                beneficiary_transaction_unique_id:
                                  singleTransactionView.data
                                    ?.beneficiary_transaction.unique_id,
                              })
                            );
                          }}
                        >
                          {updateBeneficiaryStatus.buttonDisable ? (
                            <ButtonLoader />
                          ) : (
                            t("check_status")
                          )}
                        </Button>
                      ) : null}
                    <Button
                      disabled={singleTransactionDownload.buttonDisable}
                      className="efi-overlay-btn mt-1"
                      onClick={() => {
                        dispatch(
                          singleTransactionDownloadStart({
                            beneficiary_transaction_unique_id: params.id,
                          })
                        );
                      }}
                    >
                      {singleTransactionDownload.buttonDisable ? (
                        <ButtonLoader />
                      ) : (
                        t("download_receipt")
                      )}
                    </Button>
                  </div>
                </div>
                <div className="beneficiary-code-card">
                  <div className="beneficiary-code-sec">
                    <div className="code-frame transaction-copy-btn-sec">
                      <h5>
                        <span>
                          {
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction?.id
                          }
                        </span>
                        <CopyToClipboard
                          text={
                            singleTransactionView.data.beneficiary_transaction
                              .beneficiary_transaction?.id
                          }
                        >
                          <Button
                            className="copy-btn"
                            onClick={() =>
                              getSuccessNotificationMessage("Copied!")
                            }
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="24"
                              height="24"
                              fill="none"
                              viewBox="0 0 24 24"
                            >
                              <path
                                fill="#171717"
                                d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                              ></path>
                              <path
                                fill="#171717"
                                d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                opacity="0.4"
                              ></path>
                            </svg>
                          </Button>
                        </CopyToClipboard>
                      </h5>
                    </div>
                    <div className="d-flex flex-column justify-content-center transaction-btn ">
                      <div
                        className={getStatusClass(
                          singleTransactionView.data.beneficiary_transaction
                            ?.status
                        )}
                      >
                        {
                          singleTransactionView.data.beneficiary_transaction
                            ?.status_formatted
                        }
                      </div>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-frame-info card-flex">
                    <div className="benefi-info-card">
                      <h4>{t("recipient_amount")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction.recipient_amount
                        }
                        {" "}
                        {
                          singleTransactionView.data.beneficiary_transaction.recipient_currency
                        }
                      </p>
                    </div>
                    <div className="benefi-info-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path d="M1.655 218.199a21.693 21.693 0 014.701-23.64l98.699-98.699c8.475-8.475 22.208-8.475 30.682 0 8.472 8.47 8.472 22.208 0 30.678l-61.665 61.665h416.233c11.981 0 21.695 9.712 21.695 21.695s-9.714 21.695-21.695 21.695H21.697a21.695 21.695 0 01-20.042-13.394zm488.648 62.208H21.695C9.714 280.407 0 290.119 0 302.102s9.714 21.695 21.695 21.695h416.233l-61.667 61.665c-8.47 8.47-8.472 22.208 0 30.678a21.626 21.626 0 0015.341 6.356 21.63 21.63 0 0015.341-6.356l98.701-98.699a21.695 21.695 0 00-15.341-37.034z"></path>
                      </svg>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("converted_amount")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .amount
                        }
                        {/* {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction?.converted_amount?.amount
                        } */}
                      </p>
                    </div>
                    <div className="benefi-info-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        viewBox="0 0 512 512"
                      >
                        <path d="M256 439.98c-13.81 0-25-11.19-25-25V97.02c0-13.81 11.19-25 25-25s25 11.19 25 25v317.96c0 13.81-11.19 25-25 25z"></path>
                        <path d="M414.98 281H97.02c-13.81 0-25-11.19-25-25s11.19-25 25-25h317.96c13.81 0 25 11.19 25 25s-11.19 25-25 25z"></path>
                      </svg>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("total_fee")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .fees
                        }
                        {/* {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction?.quote?.fees?.total?.amount
                        } */}
                      </p>
                    </div>
                    <div className="benefi-info-icons">
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="20"
                        height="20"
                        x="0"
                        y="0"
                        enableBackground="new 0 0 121.805 121.805"
                        version="1.1"
                        viewBox="0 0 121.805 121.805"
                        xmlSpace="preserve"
                      >
                        <path d="M7.308 85.264h107.188c4.037 0 7.309-3.271 7.309-7.31s-3.271-7.309-7.309-7.309H7.308C3.271 70.646 0 73.916 0 77.954s3.271 7.31 7.308 7.31zM7.308 51.158h107.188a7.309 7.309 0 100-14.617H7.308A7.307 7.307 0 000 43.849a7.308 7.308 0 007.308 7.309z"></path>
                      </svg>
                    </div>
                    <div className="benefi-info-card">
                      <h4>{t("charged_amount")}</h4>
                      <p>
                        {
                          singleTransactionView.data.beneficiary_transaction
                            .total
                        }
                        {/* {
                          singleTransactionView.data.beneficiary_transaction
                            .beneficiary_transaction?.charged_amount?.amount
                        } */}
                      </p>
                    </div>
                  </div>
                  <div className="benefici-space"></div>
                  <div className="beneficiary-code-sec">
                    <div className="benefi-info-card">
                      <h4>{t("transaction_sub_status")}</h4>
                      <p>
                        {" "}
                        {
                          singleTransactionView.data.beneficiary_transaction
                            ?.message
                        }
                      </p>
                    </div>
                    {singleTransactionView.data.beneficiary_transaction
                      ?.sub_status !== "" && (
                        <div
                          className={getStatusClass(
                            singleTransactionView.data.beneficiary_transaction
                              ?.status
                          )}
                        >
                          {singleTransactionView.data.beneficiary_transaction?.sub_status
                            ?.split("_")
                            .join(" ")}
                        </div>
                      )}
                  </div>
                  {singleTransactionView.data.beneficiary_transaction?.fx_rate ? <div className="beneficiary-code-sec">
                    <div className="benefi-info-card">
                      <h4>{t("fx_rate")}</h4>
                      <p>
                        {" "}
                        {singleTransactionView.data.beneficiary_transaction?.fx_rate}
                      </p>
                    </div>
                  </div> : null}
                  <div className="beneficiary-frame-info ">
                    <div className="benefi-info-card">
                      <h4>{t("customer_remark")}</h4>
                      <p>
                        {singleTransactionView.data.beneficiary_transaction
                          ?.remarks !== ""
                          ? singleTransactionView.data.beneficiary_transaction
                            ?.remarks
                          : "N/A"}
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="tab-content-frame">
                <Tab.Container id="details-tabs" defaultActiveKey="first">
                  <Nav variant="pills">
                    <Nav.Item>
                      <Nav.Link eventKey="first">{t("details")}</Nav.Link>
                    </Nav.Item>
                    {/* <Nav.Item>
                      <Nav.Link eventKey="second">
                        {t("rates_and_fees")}
                      </Nav.Link>
                    </Nav.Item> */}
                    <Nav.Item>
                      <Nav.Link eventKey="third">
                        {t("additional_information")}
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item>
                      <Nav.Link eventKey="four">{t("history")}</Nav.Link>
                    </Nav.Item>
                  </Nav>
                  <Tab.Content>
                    <Tab.Pane eventKey="first">
                      <div className="beneficiary-info-card">
                        {/* <div className="beneficiary-info-head">
                        <h3>{t("details")}</h3>
                      </div> */}
                        <div className="beneficiary-code-card">
                          <Row>
                            {/* <Col lg={6}>
                              <div className="beneficiary-frame-info card-grid__2 border-space">
                                <div className="code-frame">
                                  <h4>{t("user_id")}</h4>
                                  <h5>
                                    <span>
                                      {
                                        singleTransactionView.data
                                          .beneficiary_transaction
                                          ?.beneficiary_transaction?.user?.id
                                      }
                                    </span>
                                    <CopyToClipboard
                                      text={
                                        singleTransactionView.data
                                          .beneficiary_transaction
                                          ?.beneficiary_transaction?.user?.id
                                      }
                                    >
                                      <Button
                                        className="copy-btn"
                                        onClick={() =>
                                          getSuccessNotificationMessage(
                                            "Copied!"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="#171717"
                                            d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                                          ></path>
                                          <path
                                            fill="#171717"
                                            d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                            opacity="0.4"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </h5>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("user_type")}</h4>
                                  <p>
                                    {
                                      singleTransactionView.data
                                        .beneficiary_transaction
                                        ?.beneficiary_transaction?.user?.type
                                    }
                                  </p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("user_country")}</h4>
                                  <p>{singleTransactionView.data.beneficiary_transaction.beneficiary_transaction?.user?.country ? (iso.whereAlpha3(singleTransactionView.data.beneficiary_transaction.beneficiary_transaction?.user?.country)?.country) : "N/A"}</p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("user_currency")}</h4>
                                  <p>
                                    {
                                      singleTransactionView.data
                                        .beneficiary_transaction
                                        ?.beneficiary_transaction?.user
                                        ?.currency
                                    }
                                  </p>
                                </div>
                              </div>
                            </Col> */}
                            <Col lg={6}>
                              <div className="beneficiary-frame-info card-grid__2">
                                <div className="code-frame transaction-copy-btn-sec">
                                  <h4>{t("receipient_id")}</h4>
                                  <h5>
                                    <span>
                                      {
                                        singleTransactionView.data
                                          .beneficiary_transaction
                                          ?.beneficiary_transaction?.recipient
                                          ?.id
                                      }
                                    </span>
                                    <CopyToClipboard
                                      text={
                                        singleTransactionView.data
                                          .beneficiary_transaction
                                          .beneficiary_transaction?.recipient
                                          ?.id
                                      }
                                    >
                                      <Button
                                        className="copy-btn"
                                        onClick={() =>
                                          getSuccessNotificationMessage(
                                            "Copied!"
                                          )
                                        }
                                      >
                                        <svg
                                          xmlns="http://www.w3.org/2000/svg"
                                          width="24"
                                          height="24"
                                          fill="none"
                                          viewBox="0 0 24 24"
                                        >
                                          <path
                                            fill="#171717"
                                            d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                                          ></path>
                                          <path
                                            fill="#171717"
                                            d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                            opacity="0.4"
                                          ></path>
                                        </svg>
                                      </Button>
                                    </CopyToClipboard>
                                  </h5>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("receipient_type")}</h4>
                                  <p>
                                    {
                                      singleTransactionView.data
                                        .beneficiary_transaction
                                        .beneficiary_transaction?.recipient
                                        ?.type
                                    }
                                  </p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("receipient_country")}</h4>
                                  <p>
                                    {singleTransactionView.data
                                      .beneficiary_transaction
                                      .beneficiary_transaction?.recipient
                                      ?.country
                                      ? iso.whereAlpha3(
                                        singleTransactionView.data
                                          .beneficiary_transaction
                                          .beneficiary_transaction?.recipient
                                          ?.country
                                      )?.country
                                      : "N/A"}
                                  </p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("receipient_currency")}</h4>
                                  <p>
                                    {
                                      singleTransactionView.data
                                        .beneficiary_transaction
                                        .beneficiary_transaction?.recipient
                                        ?.currency
                                    }
                                  </p>
                                </div>
                              </div>
                            </Col>
                            {Object.keys(singleTransactionView.data.sender).length > 0 ? <Col lg={6}>
                              <div className="beneficiary-frame-info card-grid__2">
                                <div className="benefi-info-card">
                                  <h4>{t("sender_name")}</h4>
                                  <p>
                                    {
                                      singleTransactionView.data
                                        .sender.first_name + " " + singleTransactionView.data
                                          .sender.middle_name + " " + singleTransactionView.data
                                            .sender.last_name
                                    }
                                  </p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("sender_email")}</h4>
                                  <p>
                                    {
                                      singleTransactionView.data
                                        .sender.email
                                    }
                                  </p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("sender_contact")}</h4>
                                  <p>
                                    {"+" +
                                      singleTransactionView.data
                                        .sender.mobile_country_code + " " + singleTransactionView.data
                                          .sender.mobile
                                    }
                                  </p>
                                </div>
                                <div className="benefi-info-card">
                                  <h4>{t("sender_country")}</h4>
                                  <p>
                                    {singleTransactionView.data
                                      .sender.country
                                      ? iso.whereAlpha2(singleTransactionView.data.sender.country)?.country
                                      : "N/A"}
                                  </p>
                                </div>
                              </div>
                            </Col> : null}
                          </Row>
                          <div className="benefici-space"></div>
                          <div className="beneficiary-frame-info">
                            <div className="beneficiary-child-info-head m-0">
                              <h3>{t("receiving_institution")}</h3>
                            </div>
                            <div className="beneficiary-child-frame-info card-grid__4">
                              <div className="benefi-info-card">
                                <h4> {t("name")}</h4>
                                <p>
                                  {
                                    singleTransactionView.data
                                      .beneficiary_transaction
                                      .beneficiary_transaction
                                      ?.receiving_institution
                                      ?.receiving_institution || "N/A"
                                  }
                                </p>
                              </div>
                              <div className="benefi-info-card">
                                <h4>{t("branch")}</h4>
                                <p>
                                  {
                                    singleTransactionView.data
                                      .beneficiary_transaction
                                      ?.beneficiary_transaction
                                      ?.receiving_institution
                                      ?.receiving_institution || "N/A"
                                  }
                                </p>
                              </div>
                              <div className="benefi-info-card">
                                <h4>{t("code")} </h4>
                                <p>
                                  {
                                    singleTransactionView.data
                                      ?.beneficiary_transaction
                                      ?.beneficiary_transaction
                                      ?.receiving_institution
                                      ?.receiving_institution_code || "N/A"
                                  }
                                </p>
                              </div>
                              <div className="benefi-info-card">
                                <h4>{t("bank_account_type")}</h4>
                                <p>
                                  {
                                    singleTransactionView.data
                                      ?.beneficiary_transaction
                                      ?.beneficiary_transaction
                                      ?.receiving_institution?.account_type || "N/A"
                                  }
                                </p>
                              </div>
                              <div className="benefi-info-card">
                                <h4>{t("account_name")}</h4>
                                <p>
                                  {
                                    singleTransactionView.data
                                      ?.beneficiary_transaction
                                      ?.beneficiary_transaction
                                      ?.receiving_institution?.account_name || "N/A"
                                  }
                                </p>
                              </div>
                              <div className="benefi-info-card">
                                <h4>{t("account_number")}</h4>
                                <p>
                                  {
                                    singleTransactionView.data
                                      ?.beneficiary_transaction
                                      ?.beneficiary_transaction
                                      ?.receiving_institution?.account_number || singleTransactionView.data
                                      ?.beneficiary_transaction
                                      ?.beneficiary_transaction
                                      ?.receiving_institution?.IBAN || "N/A"
                                  }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="second">
                      <div className="beneficiary-info-card">
                        {/* <div className="beneficiary-info-head">
                        <h3> {t("rates_and_fees")}</h3>
                      </div> */}
                        <div className="beneficiary-code-card">
                          <Row>
                            <Col lg={6}>
                              <div className="beneficiary-child-frame border-space">
                                <div className="beneficiary-child-info-head m-0">
                                  <h3> {t("quotes")}</h3>
                                </div>
                                <div className="beneficiary-child-frame-info">
                                  <div className="code-frame">
                                    <h4>{t("quote_id")}</h4>
                                    <h5>
                                      <span>
                                        {
                                          singleTransactionView.data
                                            ?.beneficiary_transaction
                                            ?.beneficiary_transaction?.quote?.id
                                        }
                                      </span>
                                      <CopyToClipboard
                                        text={
                                          singleTransactionView.data
                                            ?.beneficiary_transaction
                                            ?.beneficiary_transaction?.quote?.id
                                        }
                                      >
                                        <Button
                                          className="copy-btn"
                                          onClick={() =>
                                            getSuccessNotificationMessage(
                                              "Copied!"
                                            )
                                          }
                                        >
                                          <svg
                                            xmlns="http://www.w3.org/2000/svg"
                                            width="24"
                                            height="24"
                                            fill="none"
                                            viewBox="0 0 24 24"
                                          >
                                            <path
                                              fill="#171717"
                                              d="M16 12.9v4.2c0 3.5-1.4 4.9-4.9 4.9H6.9C3.4 22 2 20.6 2 17.1v-4.2C2 9.4 3.4 8 6.9 8h4.2c3.5 0 4.9 1.4 4.9 4.9z"
                                            ></path>
                                            <path
                                              fill="#171717"
                                              d="M17.1 2h-4.2C9.45 2 8.05 3.37 8.01 6.75h3.09c4.2 0 6.15 1.95 6.15 6.15v3.09c3.38-.04 4.75-1.44 4.75-4.89V6.9C22 3.4 20.6 2 17.1 2z"
                                              opacity="0.4"
                                            ></path>
                                          </svg>
                                        </Button>
                                      </CopyToClipboard>
                                    </h5>
                                  </div>
                                  <div className="card-grid__2 mt-3">
                                    <div className="benefi-info-card">
                                      <h4>{t("quote_type")}</h4>
                                      <p>
                                        {
                                          singleTransactionView.data
                                            ?.beneficiary_transaction
                                            ?.beneficiary_transaction?.quote
                                            ?.type
                                        }
                                      </p>
                                    </div>
                                    <div className="benefi-info-card">
                                      <h4>{t("fee_included")}</h4>
                                      <p>
                                        {singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.included == false
                                          ? t("no")
                                          : t("yes")}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="beneficiary-child-frame">
                                <div className="beneficiary-child-info-head m-0">
                                  <h3>{t("total_fee")}</h3>
                                </div>
                                <div className="beneficiary-child-frame-info card-grid__2">
                                  <div className="benefi-info-card">
                                    <h4> {t("total")}</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.total.currency
                                      }
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.total.amount
                                      }
                                    </p>
                                  </div>
                                  <div className="benefi-info-card">
                                    <h4>{t("fixed")}</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup?.total_markup
                                          ?.fixed_amount?.currency
                                      }
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup?.total_markup
                                          ?.fixed_amount?.amount
                                      }
                                    </p>
                                  </div>
                                  <div className="benefi-info-card">
                                    <h4> {t("variable_fee")} %</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup?.total_markup
                                          ?.percentage_value
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>{" "}
                          <Row>
                            <Col lg={12}>
                              <div className="benefici-space"></div>
                            </Col>
                          </Row>
                          <Row>
                            <Col lg={6}>
                              <div className="beneficiary-child-frame  border-space">
                                <div className="beneficiary-child-info-head m-0">
                                  <h3>{t("platform_fee")}</h3>
                                </div>
                                <div className="beneficiary-child-frame-info card-grid__2">
                                  <div className="benefi-info-card">
                                    <h4> {t("total")}</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.platform_charge?.total
                                          ?.currency
                                      }
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.platform_charge?.total
                                          ?.amount
                                      }
                                    </p>
                                  </div>
                                  <div className="benefi-info-card">
                                    <h4>{t("fixed")}</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.platform_charge
                                          ?.fixed_amount?.currency
                                      }
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.platform_charge
                                          ?.fixed_amount?.amount
                                      }
                                    </p>
                                  </div>
                                  <div className="benefi-info-card">
                                    <h4>{t("variable_fee")} %</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.platform_charge
                                          ?.percentage_value
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                            <Col lg={6}>
                              <div className="beneficiary-child-frame">
                                <div className="beneficiary-child-info-head m-0">
                                  <h3> {t("program_fee")}</h3>
                                </div>
                                <div className="beneficiary-child-frame-info card-grid__2">
                                  <div className="benefi-info-card">
                                    <h4> {t("total")}</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details?.markup?.program?.total
                                          ?.currency
                                      }
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup?.program?.total
                                          ?.amount
                                      }
                                    </p>
                                  </div>
                                  <div className="benefi-info-card">
                                    <h4>{t("fixed")}</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup.program?.fixed_amount
                                          ?.currency
                                      }
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup.program?.fixed_amount
                                          ?.amount
                                      }
                                    </p>
                                  </div>
                                  <div className="benefi-info-card">
                                    <h4>{t("variable_fee")} %</h4>
                                    <p>
                                      {
                                        singleTransactionView.data
                                          ?.beneficiary_transaction
                                          ?.beneficiary_transaction?.quote?.fees
                                          ?.details.markup?.program
                                          ?.percentage_value
                                      }
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </Col>
                          </Row>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="third">
                      <div className="beneficiary-info-card">
                        {/* <div className="beneficiary-info-head">
                        <h3> {t("additional_information")}</h3>
                      </div> */}
                        <div className="beneficiary-frame-info card-grid__4">
                          <div className="benefi-info-card">
                            <h4>{t("purpose_of_payment")}</h4>
                            <p>
                              {singleTransactionView.data
                                .beneficiary_transaction.beneficiary_transaction
                                .additional_data?.purpose_of_payment == ""
                                ? "N/A"
                                : singleTransactionView.data
                                  ?.beneficiary_transaction
                                  ?.beneficiary_transaction?.additional_data
                                  ?.purpose_of_payment}
                            </p>
                          </div>
                          <div className="benefi-info-card">
                            <h4>{t("occupation")}</h4>
                            <p>
                              {singleTransactionView.data
                                .beneficiary_transaction.beneficiary_transaction
                                .additional_data?.user_occupation == ""
                                ? "N/A"
                                : singleTransactionView.data
                                  ?.beneficiary_transaction
                                  ?.beneficiary_transaction?.additional_data
                                  ?.user_occupation}
                            </p>
                          </div>
                          <div className="benefi-info-card">
                            <h4>{t("relationship")}</h4>
                            <p>
                              {singleTransactionView.data
                                ?.beneficiary_transaction
                                .beneficiary_transaction?.additional_data
                                ?.user_recipient_relationship == ""
                                ? "N/A"
                                : singleTransactionView.data
                                  ?.beneficiary_transaction
                                  ?.beneficiary_transaction?.additional_data
                                  ?.user_recipient_relationship}
                            </p>
                          </div>
                          <div className="benefi-info-card">
                            <h4> {t("source_of_income")}</h4>
                            <p className="source-of-income-text">
                              {singleTransactionView.data
                                .beneficiary_transaction.beneficiary_transaction
                                ?.additional_data?.user_source_of_income?.split("_").join(" ") || "N/A"}
                            </p>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                    <Tab.Pane eventKey="four">
                      <div className="beneficiary-info-card">
                        {/* <div className="beneficiary-info-head">
                          <h3> {t("history")}</h3>
                        </div> */}
                        <div className="beneficiary-frame-info ">
                          <div className="benefi-info-list">
                            <ul>
                              {singleTransactionView.data.timeline?.history?.map(
                                (item, key) => (
                                  <li key={key}>
                                    <div className="benefi-info-card">
                                      <h4>{item.status}</h4>
                                      <p>{item.updated_date}</p>
                                      <p>{item.sub_status || t("n_a")}</p>
                                    </div>
                                  </li>
                                )
                              )}
                            </ul>
                          </div>
                        </div>
                      </div>
                    </Tab.Pane>
                  </Tab.Content>
                </Tab.Container>
              </div>
            </>
          ) : (
            <NoDataFoundNew />
          )}
        </div>
      </div>
    </div>
  );
};

export default BeneficiaryView;
