import React from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { beneficiaryTransactionListFailure } from '../../store/slices/BeneficiariesSlice';

const useLogout = () => {

    const dispatch = useDispatch();
    const navigate = useNavigate();

    const resetSaga = () => {
        dispatch(beneficiaryTransactionListFailure());
    }

    const logout = () => {
        let userLang = localStorage.getItem("lang") || "en";
        resetSaga();
        localStorage.clear();
        sessionStorage.clear();
        localStorage.setItem("lang", userLang)
        navigate('/login')
    }

    return { logout };

}

export default useLogout;