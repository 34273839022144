import React, { useState, useEffect } from "react";
import { Modal, Button, Form, Image, Row, Col } from "react-bootstrap";
import Select from 'react-select';
import 'react-datepicker/dist/react-datepicker.css';
import './payment.css';

const TransferModal = (props) => {
  const [step, setStep] = useState(1);
  const [selectedOption, setSelectedOption] = useState(null);

  useEffect(() => {
    setStep(1);
  }, []);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "300",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? '#0194ff' : '#fff',
      color: state.isFocused || state.isSelected ? '#fff' : '#000',
      ":hover": {
              backgroundColor: "#0194ff",
              color: "#fff"
            },
  }),
  };

  const options = [
    { value: "option 1", label: "Option 1" },
    { value: "option 2", label: "Option 2" },
    { value: "option 3", label: "Option 3" },
    { value: "option 4", label: "Option 4" },
  ]


  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="money-transfer-modal"
      >
        <Modal.Header >
          <Image
            className="dubai-bank-img"
            src={
              window.location.origin + "/img/lock-icon.svg"
            }
            type="image/png"
          />
          <h3>Transfer Payment</h3>
        </Modal.Header>
        {step == 1 && (
          <Modal.Body>
            <div className="efi-bank-default-form">
              <Form >
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    Bank Name
                  </Form.Label>
                  <Select
                    styles={customStyles}
                    defaultValue={selectedOption}
                    onChange={setSelectedOption}
                    options={options}
                    isSearchable= {false}
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    Account Number
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Bank A/C Number"
                    aria-label="text"
                    aria-describedby="text-addon"
                  />
                </Form.Group>
                <Form.Group className="mb-3">
                  <Form.Label className="default-form-label">
                    BIC code
                  </Form.Label>
                  <Form.Control
                    type="text"
                    placeholder="Enter BCI Code "
                    aria-label="text"
                    aria-describedby="text-addon"
                  />
                </Form.Group>
              </Form>
            </div>
            <div className="step-modal-button">
              <Button className="efi-cancel-btn" onClick={() => props.onHide()}>
                Cancel
              </Button>
              <Button className="efi-primary-btn" onClick={() => setStep(2)}>
                Next
              </Button>
            </div>
          </Modal.Body>
        )}

        {step == 2 && (
          <div className="step-2">
            <Modal.Body>
              <div className="efi-bank-default-form">
                <Form >
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      Receiptant Name
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Name"
                      aria-label="text"
                      aria-describedby="text-addon"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      Amount
                    </Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Amount"
                      aria-label="text"
                      aria-describedby="text-addon"
                    />
                  </Form.Group>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label">
                      Description
                    </Form.Label>
                    <Form.Control as="textarea" placeholder="Description" rows={3} />
                  </Form.Group>
                </Form>
              </div>
              <div className="step-modal-button">
                <Button className="efi-cancel-btn" onClick={() => setStep(1)}>
                  Back
                </Button>
                <Button className="efi-primary-btn" onClick={() => setStep(3)}>
                  Next
                </Button>
              </div>
            </Modal.Body>
          </div>
        )}

        {step == 3 && (
          <div className="step-3">
            <Modal.Body>
              <div className="efi-bank-default-form">
                <Form className="payament-form-input">
                  <Row>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          Bank Name
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value="Dubai Islamic Bank"
                          aria-label="text"
                          aria-describedby="text-addon"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          Account Number
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value="1234567891234"
                          aria-label="text"
                          aria-describedby="text-addon"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          BIC code
                        </Form.Label>
                        <Form.Control
                          type="text"
                          value="DIB000874536"
                          aria-label="text"
                          aria-describedby="text-addon"
                        />
                      </Form.Group>

                    </Col>
                    <Col md={6}>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">Receiptant Name</Form.Label>
                        <Form.Control
                          type="text"
                          value="John Duo"
                          aria-label="Password"
                          aria-describedby="text-addon"
                        />
                      </Form.Group>
                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">Amount</Form.Label>
                        <Form.Control
                          type="text"
                          value="1000"
                          aria-label="Password"
                          aria-describedby="text-addon"
                        />
                      </Form.Group>

                      <Form.Group className="mb-3">
                        <Form.Label className="default-form-label">
                          Description
                        </Form.Label>
                        <Form.Control as="textarea" rows={2} value="Lorem ipsum dolor sit" />
                      </Form.Group>
                    </Col>
                  </Row>
                </Form>
              </div>
              <div className="step-modal-button">
                <Button className="efi-cancel-btn" onClick={() => setStep(2)}>
                  Back
                </Button>
                <Button className="efi-primary-btn" onClick={() => props.onHide()}>
                  Transfer
                </Button>
              </div>
            </Modal.Body>
          </div>
        )}

      </Modal>
    </>
  );
};

export default TransferModal;