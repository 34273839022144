import React from "react";
import { Modal, Button, Form, Row, Col, Image } from "react-bootstrap";
import { useTranslation } from "react-multi-lang";

const StatementModal = (props) => {

  const t = useTranslation("modals.statement");

  return (
    <Modal
      {...props}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
       <h3 className="change-password-heading">{t("heading")}</h3>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <div className="quote-info-card">
          {/* <div className="quote-card">
            <p>{t("id")}</p>
            <h5>
              <span>{props.show.transaction_id}</span>
            </h5>
          </div> */}
          <div className="quote-card">
            <p>
              {t("remarks")}
            </p>
            <h5 className="mt-1">{props.show.description}</h5>
          </div>
          {/* <div className="quote-card">
            <p>{t("type")}</p>
            <h5>
              <span>{props.show.type.toUpperCase()}</span>
            </h5>
          </div>
          <div className="quote-card">
            <p>{t("status")}</p>
            <h5>
              <span>{props.show.status}</span>
            </h5>
          </div>
          <div className="quote-card">
            <p>{t("amount")}</p>
            <h5>
              <span>${props.show.amount}</span>
            </h5>
          </div>
          <div className="quote-card">
            <p>{t("running_balance")}</p>
            <h5>
              <span>${props.show.balance}</span>
            </h5>
          </div> */}
        </div>
      </Modal.Body>
    </Modal>
  );
};
export default StatementModal;
