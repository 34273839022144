import React, { useEffect, useState } from "react";
import { Button, Image, Dropdown, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  blockUnblockBeneficiaryStart,
  contactsViewStart,
} from "../../store/slices/BeneficiariesSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const BeneficiaryBlockPermanentModal = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation("beneficiaries.beneficiary_block")

  const blockUnblockBeneficiary = useSelector((state) => state.beneficiaries.blockUnblockBeneficiary);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !blockUnblockBeneficiary.loading && Object.keys(blockUnblockBeneficiary.data).length > 0) {
      props.onHide();
    }
    setSkipRender(false);
  }, [blockUnblockBeneficiary]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contact-delete-modal"
        backdrop="static"
      >
        <Modal.Body>
          <Form className="efi-bank-default-form">
            <div className="open-account-modal-heading">
              {/* <Image
                className="delete-logo-avater"
                src={window.location.origin + "/img/feature/deleted.png"}
                type="image/png"
              /> */}
              <svg
                xmlns="http://www.w3.org/2000/svg"
                id="Layer_1"
                data-name="Layer 1"
                width="20"
                height="20"
                viewBox="0 0 24 24"
                fill="#595856"
              >
                <path d="M22.251 22.229A5.98 5.98 0 0 0 24 18c0-3.309-2.691-6-6-6a6.01 6.01 0 0 0-6 6c0 3.309 2.691 6 6 6 1.66 0 3.165-.679 4.251-1.771M22 18c0 .74-.216 1.424-.567 2.019l-5.452-5.453A3.96 3.96 0 0 1 18 13.999c2.206 0 4 1.794 4 4Zm-8 0c0-.74.216-1.424.567-2.019l5.452 5.453a3.96 3.96 0 0 1-2.019.567c-2.206 0-4-1.794-4-4Zm-5-6c3.309 0 6-2.691 6-6s-2.691-6-6-6-6 2.691-6 6 2.691 6 6 6M9 2c2.206 0 4 1.794 4 4s-1.794 4-4 4-4-1.794-4-4 1.794-4 4-4m1.992 13.187a.996.996 0 0 1-1.116.868A7 7 0 0 0 9 16.001c-3.859 0-7 3.14-7 7a1 1 0 1 1-2 0c0-4.962 4.037-9 9-9 .377 0 .755.023 1.124.07a1 1 0 0 1 .868 1.117Z"></path>
              </svg>
              <div className="open-account-modal-info text-center">
                <h3>{t("title")}</h3>
                <p>
                  {t("content")} {props.show.account_name}?
                </p>
              </div>
            </div>
            <div className="form-modal-footer">
              <Button className="cancel-button" onClick={props.onHide}>
                {t("cancel")}
              </Button>
              <Button
                className={"delete-button"}
                disabled={blockUnblockBeneficiary.buttonDisable}
                onClick={() =>
                  dispatch(
                    blockUnblockBeneficiaryStart({
                      status: "block",
                      beneficiary_account_unique_id: props.show.unique_id,
                    })
                  )
                }
              >
                {blockUnblockBeneficiary.buttonDisable ? <ButtonLoader/> : t("block")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BeneficiaryBlockPermanentModal;
