import React, { useEffect, useState } from "react";
import { Button, Image, Dropdown, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteBeneficiaryStart,
  contactsViewStart,
} from "../../store/slices/BeneficiariesSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const BeneficiaryDeleteModal = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation("beneficiaries.beneficiary_delete")

  const deleteBeneficiary = useSelector((state) => state.beneficiaries.deleteBeneficiary);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !deleteBeneficiary.loading && Object.keys(deleteBeneficiary.data).length > 0) {
      props.onHide();
    }
    setSkipRender(false);
  }, [deleteBeneficiary]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contact-delete-modal"
        backdrop="static"
      >
        <Modal.Body>
          <Form className="efi-bank-default-form">
            <div className="open-account-modal-heading">
              <Image
                className="delete-logo-avater"
                src={window.location.origin + "/img/feature/deleted.png"}
                type="image/png"
              />
              <div className="open-account-modal-info text-center">
                <h3>{t("title")}</h3>
                <p>
                  {t("content")} {props.show.account_name}?
                </p>
              </div>
            </div>
            <div className="form-modal-footer">
              <Button className="cancel-button" onClick={props.onHide}>
                {t("cancel")}
              </Button>
              <Button
                className="delete-button"
                disabled={deleteBeneficiary.buttonDisable}
                onClick={() =>
                  dispatch(
                    deleteBeneficiaryStart({
                      // status: "delete",
                      beneficiary_account_unique_id: props.show.unique_id,
                    })
                  )
                }
              >
                {deleteBeneficiary.buttonDisable ? <ButtonLoader/> : t("delete")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BeneficiaryDeleteModal;
