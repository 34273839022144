import React, { useEffect, useRef, useState } from "react";
import { Button, Table } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik } from "formik";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { businessUserListStart } from "../../store/slices/BusinessSlice";
import NoDataFound from "../Helper/NoDataFound";
import { getErrorNotificationMessage } from "../Helper/NotificationMessage";
import Skeleton from "react-loading-skeleton";
import configuration from "react-global-configuration";
import { useTranslation } from "react-multi-lang";
import * as Yup from "yup";
import Pagination from "../Helper/Pagination";
import SendMoneyModal from "./SendMoneyModal";
import OtpVerification from "../Card/OtpVerification";
import { Tooltip } from 'react-tooltip';
import EditBusinessUserModal from "./EditBusinessUserModal";
import BusinessUserDeleteModal from "./BusinessUserDeleteModal";

const BusinessUserList = (props) => {

  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const formRef = useRef(null);
  const dispatch = useDispatch();
  const t = useTranslation("business.list");

  const businessUserList = useSelector((state) => state.business.businessUserList);
  const profile = useSelector((state) => state.admin.profile);

  const [moneyModal, setMoneyModal] = useState(false);
  const [showOTPVerifyModal, setShowOTPVerifyModal] = useState(false);
  const [editBusinessUser, setEditBusinessUser] = useState(false);
  const [deleteBusinessUser, setDeleteBusinessUser] = useState(false);
  const [skipRender, setSkipRender] = useState(true);

  const [page, setPage] = useState(
    searchParams.get("page") ? searchParams.get("page") : 1
  );

  const [filter, setFilter] = useState({
    search_key: searchParams.get("search_key")
      ? searchParams.get("search_key")
      : "",
  });

  const validationSchema = Yup.object().shape({
    search_key: Yup.string()
      .required(t("required"))
      .matches(/^(?=\S*$)/, t("white_space")),
  });

  useEffect(() => {
    if (!profile.loading && Object.keys(profile.data).length > 0) {
      if (profile.data.onboarding >= 4) {
        dispatch(
          businessUserListStart({
            ...filter,
            skip: 12 * (page - 1),
            take: 12,
          })
        );
      } else {
        getErrorNotificationMessage("Please complete the onboarding to continue.")
      }
    }
  }, [profile, filter, page]);

  const handlePageClick = ({ selected }) => {
    let params = searchParams.get("search_key")
      ? `search_key=${searchParams.get("search_key")}&`
      : "";
    navigate(`/employee-list?${params}page=${selected + 1}`);
  };

  useEffect(() => {
    if (!skipRender) {
      setPage(searchParams.get("page") ? searchParams.get("page") : 1);
      setFilter({
        ...filter,
        search_key: searchParams.get("search_key")
          ? searchParams.get("search_key")
          : "",
      });
    }
    setSkipRender(false);
  }, [searchParams.get("page"), searchParams.get("search_key")]);

  return (
    <>
      <div className="full-body-card-wrapped">
        <div className="contact-list-card-area">
          <div className="payment-table-header justify-content-between">
            <h3>{t("heading")}</h3>
            {profile.data.onboarding >= 4 ? <div className="card-details-head-action">
              <Formik
                initialValues={{
                  search_key: searchParams.get("search_key")
                    ? searchParams.get("search_key")
                    : "",
                }}
                validationSchema={validationSchema}
                onSubmit={(val) => {
                  setFilter({
                    ...filter,
                    search_key: val.search_key,
                  });

                  navigate(
                    searchParams.get("sort_by")
                      ? `/employee-list?search_key=${val.search_key
                      }&sort_by=${searchParams.get("sort_by")}`
                      : `/employee-list?search_key=${val.search_key}`
                  );
                }}
                innerRef={formRef}
              >
                {({ values, setFieldValue, resetForm }) => (
                  <FORM className="form">
                    <div className="efi-transation-search">
                      <InputGroup className="mb-0">
                        <Field
                          placeholder={t("search_placeholder")}
                          type="text"
                          className="form-control trans-form-control"
                          name="search_key"
                          onKeyPress={(event) => {
                            if (event.key === "Enter") {
                              event.preventDefault();
                              formRef.current.handleSubmit();
                            }
                          }}
                        />
                        {searchParams.get("search_key") ? (
                          <InputGroup.Text id="basic-addon1">
                            <Button
                              className="search-btn"
                              onClick={() => {
                                setFieldValue("search_key", "");
                                setFilter({
                                  ...filter,
                                  search_key: "",
                                });
                                navigate(
                                  searchParams.get("sort_by")
                                    ? `/employee-list?sort_by=${searchParams.get(
                                      "sort_by"
                                    )}`
                                    : `/employee-list`
                                );
                              }}
                            >
                              <svg
                                height="20"
                                width="20"
                                viewBox="0 0 20 20"
                                aria-hidden="true"
                                focusable="false"
                                class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                              >
                                <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                              </svg>
                            </Button>
                          </InputGroup.Text>
                        ) : (
                          <InputGroup.Text id="basic-addon1">
                            <Button className="search-btn" type="submit">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="20"
                                height="20"
                                viewBox="0 0 24 24"
                                fill="none"
                                stroke="#333"
                                stroke-width="2"
                                stroke-linecap="round"
                                stroke-linejoin="round"
                              // class="icon icon-tabler icons-tabler-outline icon-tabler-search"
                              >
                                <path
                                  stroke="none"
                                  d="M0 0h24v24H0z"
                                  fill="none"
                                />
                                <path d="M10 10m-7 0a7 7 0 1 0 14 0a7 7 0 1 0 -14 0" />
                                <path d="M21 21l-6 -6" />
                              </svg>
                            </Button>
                          </InputGroup.Text>
                        )}
                      </InputGroup>
                    </div>
                  </FORM>
                )}
              </Formik>
              <Button
                className="efi-primary-btn"
                onClick={() =>
                  profile.data.onboarding != 4
                    ? getErrorNotificationMessage("Please complete onboarding first!")
                    : setMoneyModal(true)
                }
                disabled={businessUserList.buttonDisable}
              >
                {t("send_money")}
              </Button>
              <Button className="efi-primary-btn"
                disabled={businessUserList.buttonDisable}
                onClick={() => navigate("/create-employee")}
              >
                {t("create")}
              </Button>
            </div> : null}
          </div>
          {businessUserList.loading ? (
            <>
              {[...Array(6)].map((_, key) => (
                <Skeleton key={key} className="mb-3" height={80} width={"100%"} />
              ))}
            </>
          ) : Object.keys(businessUserList.data).length > 0 &&
            businessUserList.data.users.length > 0 ? (
            <div>
              <Table className="common-table" responsive>
                <thead className="common-table-header">
                  <tr>
                    {/* <th>{t("unique_id")}</th> */}
                    <th>{t("name")}</th>
                    <th>{t("email")}</th>
                    <th>{t("phone")}</th>
                    {/* <th>{t("service_type")}</th> */}
                    <th>{t("kyc_status")}</th>
                    <th>{t("actions")}</th>
                  </tr>
                </thead>
                <tbody className="common-table-body">
                  {businessUserList.data.users.map((item, i) => (
                    <tr key={i}>
                      {/* <td>{item.user_unique_id}</td> */}
                      <td>
                        {item.title +
                          " " +
                          item.first_name +
                          " " +
                          item.middle_name +
                          " " +
                          item.last_name}
                      </td>
                      <td>{item.email}</td>
                      <td>{`+${item.mobile_country_code + item.mobile}`}</td>
                      {/* <td>{item.service_type_formatted}</td> */}
                      <td>
                        {/* {item.service_type == 1
                          ? item.mastercard_kyc_status_formatted
                          : item.iban_kyc_status_formatted} */}
                        {item.mastercard_kyc_status_formatted}
                      </td>
                      <td className="delete-contact-btn-sec">
                        <Link
                          to={`/employee-list-view/${item.user_unique_id}`}
                          className="view efi-overlay-btn btn-action"
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#0194ff">
                              <path
                                d="M23.271 9.419C21.72 6.893 18.192 2.655 12 2.655S2.28 6.893.729 9.419a4.91 4.91 0 0 0 0 5.162C2.28 17.107 5.808 21.345 12 21.345s9.72-4.238 11.271-6.764a4.91 4.91 0 0 0 0-5.162m-1.705 4.115C20.234 15.7 17.219 19.345 12 19.345S3.766 15.7 2.434 13.534a2.92 2.92 0 0 1 0-3.068C3.766 8.3 6.781 4.655 12 4.655s8.234 3.641 9.566 5.811a2.92 2.92 0 0 1 0 3.068"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M12 7a5 5 0 1 0 5 5 5.006 5.006 0 0 0-5-5m0 8a3 3 0 1 1 3-3 3 3 0 0 1-3 3"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                          {/* {t("view")} */}
                        </Link>
                        <Tooltip anchorSelect=".view" place="bottom" className="tooltip-element-box">
                          {t("view")}
                        </Tooltip>
                        <Button
                          className="edit efi-edit-btn btn-action"
                          onClick={() => {
                            setEditBusinessUser(item)
                          }}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <path
                              fill="#595856"
                              d="M22.987 5.451c-.028-.177-.312-1.767-1.464-2.928-1.157-1.132-2.753-1.412-2.931-1.44a1 1 0 0 0-.682.137c-.118.073-2.954 1.849-8.712 7.566-6.063 6.021-7.653 8.427-7.718 8.526-.091.14-.146.301-.159.467l-.319 4.071A1 1 0 0 0 2 22.928q.038 0 .077-.003l4.101-.316c.165-.013.324-.066.463-.155.1-.064 2.523-1.643 8.585-7.662 5.759-5.718 7.548-8.535 7.622-8.652.128-.205.178-.45.14-.689Zm-9.17 7.922c-4.93 4.895-7.394 6.78-8.064 7.263l-2.665.206.206-2.632c.492-.672 2.393-3.119 7.312-8.004a104 104 0 0 1 3.942-3.729l.031.006c.012.002 1.237.214 2.021.98.772.755.989 1.93.995 1.959l.002.011a103 103 0 0 1-3.78 3.94m5.309-5.684a5.4 5.4 0 0 0-1.127-1.656c-.524-.513-1.134-.861-1.674-1.093 1.139-.95 1.908-1.516 2.309-1.797.419.124 1.049.377 1.481.8.453.456.695 1.081.81 1.469-.285.4-.851 1.159-1.798 2.278Z"
                              data-original="#000000"
                            ></path>
                          </svg>
                          {/* {t("edit")} */}
                        </Button>
                        <Tooltip anchorSelect=".edit" place="bottom" className="tooltip-element-box">
                          {t("edit")}
                        </Tooltip>
                        <Link
                          className="delete efi-delete-btn btn-action"
                          onClick={() =>
                            profile.data.onboarding != 4
                              ? getErrorNotificationMessage("Please complete onboarding first!") : configuration.get("configData.stylopay_service_status") == 1
                                ? setDeleteBusinessUser(item)
                                : getErrorNotificationMessage("Service not available, Please try again later!")
                          }
                        >
                          {" "}
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            xmlSpace="preserve"
                            width="20"
                            height="20"
                            viewBox="0 0 24 24"
                          >
                            <g fill="#ff6a6a">
                              <path
                                d="M21 4h-3.1A5.01 5.01 0 0 0 13 0h-2a5.01 5.01 0 0 0-4.9 4H3a1 1 0 0 0 0 2h1v13a5.006 5.006 0 0 0 5 5h6a5.006 5.006 0 0 0 5-5V6h1a1 1 0 0 0 0-2M11 2h2a3.01 3.01 0 0 1 2.829 2H8.171A3.01 3.01 0 0 1 11 2m7 17a3 3 0 0 1-3 3H9a3 3 0 0 1-3-3V6h12Z"
                                data-original="#000000"
                              ></path>
                              <path
                                d="M10 18a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1m4 0a1 1 0 0 0 1-1v-6a1 1 0 0 0-2 0v6a1 1 0 0 0 1 1"
                                data-original="#000000"
                              ></path>
                            </g>
                          </svg>
                          {/* {t("delete")} */}
                        </Link>
                        <Tooltip anchorSelect=".delete" place="bottom" className="tooltip-element-box">
                          {t("delete")}
                        </Tooltip>
                        <Button
                          onClick={() =>
                            profile.data.onboarding != 4
                              ? getErrorNotificationMessage(
                                "Please complete onboarding first!"
                              )
                              : setMoneyModal(item)
                          }
                          className="send efi-primary-btn btn-action"
                          disabled={item.mastercard_kyc_status != 3}
                        >
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            id="Layer_1"
                            width="20"
                            height="20"
                            data-name="Layer 1"
                            viewBox="0 0 24 24"
                            fill="#fff"
                          >
                            <path d="M21.916 8.727 3.965.282A2.745 2.745 0 0 0 .917.713a2.745 2.745 0 0 0-.745 2.995c.017.043 4.411 8.296 4.411 8.296S.27 20.255.255 20.297a2.74 2.74 0 0 0 .749 2.993 2.76 2.76 0 0 0 1.841.708c.409 0 .819-.092 1.201-.279l17.872-8.438A3.59 3.59 0 0 0 24 12.003c0-1.42-.801-2.675-2.084-3.275ZM2.032 2.967a.75.75 0 0 1 .223-.768.74.74 0 0 1 .838-.116l17.974 8.455c.239.112.438.27.591.462H6.315zm19.034 10.504L3.178 21.917a.74.74 0 0 1-.838-.116.74.74 0 0 1-.223-.769L6.319 13h15.345a1.65 1.65 0 0 1-.597.471Z"></path>
                          </svg>
                          {/* {t("send")} */}
                        </Button>
                        <Tooltip anchorSelect=".send" place="bottom" className="tooltip-element-box">
                          {t("send")}
                        </Tooltip>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              {businessUserList.data.total_users > 12 ? (
                <div className="new-billings-pagination-sec">
                  <Pagination
                    page={page}
                    pageCount={Math.ceil(
                      businessUserList.data.total_users / 12
                    )}
                    handlePageClick={handlePageClick}
                  />
                </div>
              ) : null}
            </div>
          ) : (
            <div className="common-full-card">
              <NoDataFound />
            </div>
          )}
        </div>
      </div>
      {moneyModal ? (
        <SendMoneyModal show={moneyModal} onHide={() => setMoneyModal(false)} setShowOTPVerifyModal={setShowOTPVerifyModal} />
      ) : null}
      {showOTPVerifyModal ? (
        <OtpVerification
          showOtpVerify={showOTPVerifyModal}
          closeOtpVerifyModal={() => setShowOTPVerifyModal(false)}
        />
      ) : null}
      {editBusinessUser ? (
        <EditBusinessUserModal
          show={editBusinessUser}
          onHide={() => setEditBusinessUser(false)}
        />
      ) : null}
      {deleteBusinessUser ? (
        <BusinessUserDeleteModal
          show={deleteBusinessUser}
          onHide={() => setDeleteBusinessUser(false)}
        />
      ) : null}
    </>
  );
};
export default BusinessUserList;
