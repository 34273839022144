import React, { useEffect, useState } from "react";
import { Button, Image, Dropdown, Form, Modal } from "react-bootstrap";

const InactivityModal = (props) => {

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contact-delete-modal"
        backdrop="static"
      >
        <Modal.Body>
          <Form className="efi-bank-default-form">
            <div className="open-account-modal-heading">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlSpace="preserve"
                width="55"
                height="55"
                viewBox="0 0 128 128"
              >
                <path
                  fill="#ff0012"
                  d="M57.362 26.54 20.1 91.075a7.666 7.666 0 0 0 6.639 11.5h74.518a7.666 7.666 0 0 0 6.639-11.5L70.638 26.54a7.665 7.665 0 0 0-13.276 0"
                  data-original="#ee404c"
                ></path>
                <g fill="#fff7ed">
                  <rect
                    width="9.638"
                    height="29.377"
                    x="59.181"
                    y="46.444"
                    data-original="#fff7ed"
                    rx="4.333"
                  ></rect>
                  <circle cx="64" cy="87.428" r="4.819" data-original="#fff7ed"></circle>
                </g>
              </svg>
              <div className="open-account-modal-info text-center">
                <h3>Warning</h3>
                <p>
                  You have been inactive for a while.
                  Do you wish to continue?
                </p>
              </div>
            </div>
            <div className="form-modal-footer">
              {/* <Button className="cancel-button" onClick={props.onHide}>
                Cancel
              </Button> */}
              <Button
                className="cancel-button"
                // disabled={contactsDelete.buttonDisable}
                onClick={() => props.onHide()}
              >
                {/* {contactsDelete.buttonDisable ? <ButtonLoader/> : "Delete Recipient"} */}
                Confirm
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default InactivityModal;
