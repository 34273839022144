import React, { useEffect, useState } from "react";
import { Button, Image, Dropdown, Form, Modal } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import {
  blockUnblockBeneficiaryStart,
  contactsViewStart,
} from "../../store/slices/BeneficiariesSlice";
import { useLocation, useNavigate } from "react-router-dom";
import { ButtonLoader } from "../Helper/Loader";
import { useTranslation } from "react-multi-lang";

const BeneficiaryBlockModal = (props) => {

  const navigate = useNavigate();
  const location = useLocation();
  const dispatch = useDispatch();
  const t = useTranslation("beneficiaries.beneficiary_block_unblock")

  const blockUnblockBeneficiary = useSelector((state) => state.beneficiaries.blockUnblockBeneficiary);

  const [skipRender, setSkipRender] = useState(true);

  useEffect(() => {
    if (!skipRender && !blockUnblockBeneficiary.loading && Object.keys(blockUnblockBeneficiary.data).length > 0) {
      props.onHide();
    }
    setSkipRender(false);
  }, [blockUnblockBeneficiary]);

  return (
    <>
      <Modal
        {...props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        className="contact-delete-modal"
        backdrop="static"
      >
        <Modal.Body>
          <Form className="efi-bank-default-form">
            <div className="open-account-modal-heading">
              {/* <Image
                className="delete-logo-avater"
                src={window.location.origin + "/img/feature/deleted.png"}
                type="image/png"
              /> */}
              {props.show.status == 1 ? <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Outline"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="#595856"
                >
                  <path d="M19 8.424V7A7 7 0 0 0 5 7v1.424A5 5 0 0 0 2 13v6a5.006 5.006 0 0 0 5 5h10a5.006 5.006 0 0 0 5-5v-6a5 5 0 0 0-3-4.576M7 7a5 5 0 0 1 10 0v1H7Zm13 12a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3Z"></path>
                  <path d="M12 14a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1"></path>
                </svg> :  <svg
                  xmlns="http://www.w3.org/2000/svg"
                  id="Outline"
                  width="20"
                  height="20"
                  viewBox="0 0 24 24"
                  fill="#595856"
                >
                  <path d="M17 8H7V7a5 5 0 0 1 9.375-2.422 1 1 0 0 0 1.749-.971A7 7 0 0 0 5 7v1.424A5 5 0 0 0 2 13v6a5.006 5.006 0 0 0 5 5h10a5.006 5.006 0 0 0 5-5v-6a5.006 5.006 0 0 0-5-5m3 11a3 3 0 0 1-3 3H7a3 3 0 0 1-3-3v-6a3 3 0 0 1 3-3h10a3 3 0 0 1 3 3Z"></path>
                  <path d="M12 14a1 1 0 0 0-1 1v2a1 1 0 0 0 2 0v-2a1 1 0 0 0-1-1"></path>
                </svg>}
              <div className="open-account-modal-info text-center">
                <h3>{props.show.status == 1 ? t("block_title") : t("unblock_title")}</h3>
                <p>
                  {props.show.status == 1 ? t("block_content") : t("unblock_content")} {props.show.account_name}?
                </p>
              </div>
            </div>
            <div className="form-modal-footer">
              <Button className="cancel-button" onClick={props.onHide}>
                {t("cancel")}
              </Button>
              <Button
                className={props.show.status == 1 ? "delete-button" : "submit-button"}
                disabled={blockUnblockBeneficiary.buttonDisable}
                onClick={() =>
                  dispatch(
                    blockUnblockBeneficiaryStart({
                      status: props.show.status == 1 ? "deactivate" : "activate",
                      beneficiary_account_unique_id: props.show.unique_id,
                    })
                  )
                }
              >
                {blockUnblockBeneficiary.buttonDisable ? <ButtonLoader/> : props.show.status == 1 ? t("deactivate") : t("activate")}
              </Button>
            </div>
          </Form>
        </Modal.Body>
      </Modal>
    </>
  );
};

export default BeneficiaryBlockModal;
