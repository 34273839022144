import { call, select, put, takeLatest, all } from "redux-saga/effects";
import { getCommonSaga } from "../sagaUtils";
import CryptoJS from "crypto-js";
import store from '../index';
import {
    changePasswordFailure,
    changePasswordSuccess,
    dashboardFailure,
    dashboardSuccess,
    forgotPasswordFailure,
    forgotPasswordSuccess,
    loginFailure,
    loginSuccess,
    profileFailure,
    profileSuccess,
    registerFailure,
    registerSuccess,
    resetPasswordFailure,
    resetPasswordSuccess,
    updateProfileFailure,
    updateProfileSuccess,
    viewAPIKeyFailure,
    viewAPIKeySuccess,
    verifyForgotPasswordSuccess,
    verifyForgotPasswordFailure,
    deleteProfileSuccess,
    deleteProfileFailure,
    emailVerifySuccess,
    emailVerifyFailure,
    twoStepAuthSuccess,
    twoStepAuthFailure,
    updateTwoStepSuccess,
    updateTwoStepFailure,
    resendOtpSuccess,
    resendOtpFailure,
    updateEmailCodeSuccess,
    updateEmailCodeFailure,
    updateEmailSuccess,
    updateEmailFailure,
    updatePersonalInformationSuccess,
    updatePersonalInformationFailure,
    updateAddressSuccess,
    updateAddressFailure,
    verifyKycSuccess,
    verifyKycFailure,
    tfaEnableSuccess,
    tfaEnableFailure,
    tfaStatusUpdateSuccess,
    tfaStatusUpdateFailure,
    tourStatusUpdateSuccess,
    tourStatusUpdateFailure,
    updateServiceTypeSuccess,
    updateServiceTypeFailure,
    tfaAuthenticationSuccess,
    tfaAuthenticationFailure,
    dashboardChartSuccess,
    dashboardChartFailure,
    logoutSuccess,
    logoutFailure,
    updateOnboardingDetailsFailure,
    updateOnboardingDetailsSuccess,
    countriesListSuccess,
    countriesListFailure,
    nationalitiesListSuccess,
    nationalitiesListFailure,
    mobileCodeListSuccess,
    mobileCodeListFailure,
    acceptInviteLinkSuccess,
    acceptInviteLinkFailure,
} from "../slices/AdminSlice";

function* loginAPI(action) {
    yield getCommonSaga({
        apiUrl: "login",
        payload: action.payload,
        successCallback: true,
        success: data => {
            if(data.user) {
                // store.dispatch(loginSuccess({ ...data.user, remember: action.payload.remember }));
                store.dispatch(loginSuccess(data));
                // if (data.user.email_status === 1 && data.user.is_initial_two_step_auth_enabled === 1) {
                if (data.user.email_status === 1) {
                    if(data.user.tfa_status === 0){
                        let key = process.env.REACT_APP_DECRYPTION_KEY;
                        let encrypted_json = JSON.parse(atob(data.salt_key));
                        let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
                        });
                        const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

                        if (action.payload.remember) {
                            localStorage.setItem("token", data.access_token)
                            localStorage.setItem("api_key", data.api_key)
                            localStorage.setItem("salt_key", salt_key_decrypted)
                        }
                        else {
                            sessionStorage.setItem("token", data.access_token)
                            sessionStorage.setItem("api_key", data.api_key)
                            sessionStorage.setItem("salt_key", salt_key_decrypted)
                        }
                        store.dispatch(profileSuccess(data.user));
                    }
                }
            } else {
                store.dispatch(loginSuccess({ ...data, remember: action.payload.remember }));
            }
        },
        failure: loginFailure,
    });
}

function* registerAPI(action) {
    yield getCommonSaga({
        apiUrl: "register",
        payload: action.payload,
        successCallback: true,
        success: data => {
            // store.dispatch(registerSuccess({ ...data.user, remember: action.payload.remember }));
            store.dispatch(registerSuccess(data));
            // if (data.user.email_status === 1) {
            //     let key = process.env.REACT_APP_DECRYPTION_KEY;
            //     let encrypted_json = JSON.parse(atob(data.salt_key));
            //     let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
            //     iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
            //     });
            //     const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

            //     if (action.payload.remember) {
            //         localStorage.setItem("token", data.access_token)
            //         localStorage.setItem("api_key", data.api_key)
            //         localStorage.setItem("salt_key", salt_key_decrypted)
            //     }
            //     else {
            //         sessionStorage.setItem("token", data.access_token)
            //         sessionStorage.setItem("api_key", data.api_key)
            //         sessionStorage.setItem("salt_key", salt_key_decrypted)
            //     }
            //     store.dispatch(profileSuccess(data.user));
            // }
            if (data.user.email_status === 1 && data.user.is_initial_two_step_auth_enabled === 1) {
                if(data.user.tfa_status === 0){
                    let key = process.env.REACT_APP_DECRYPTION_KEY;
                    let encrypted_json = JSON.parse(atob(data.salt_key));
                    let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
                    iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
                    });
                    const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

                    if (action.payload.remember) {
                        localStorage.setItem("token", data.access_token)
                        localStorage.setItem("api_key", data.api_key)
                        localStorage.setItem("salt_key", salt_key_decrypted)
                    }
                    else {
                        sessionStorage.setItem("token", data.access_token)
                        sessionStorage.setItem("api_key", data.api_key)
                        sessionStorage.setItem("salt_key", salt_key_decrypted)
                    }
                    store.dispatch(profileSuccess(data.user));
                }
            }
        },
        failure: registerFailure,
    });
}

function* forgotPasswordAPI(action) {
    yield getCommonSaga({
        apiUrl: "forgot_password",
        payload: action.payload,
        success: forgotPasswordSuccess,
        failure: forgotPasswordFailure,
    });
}

function* resetPasswordAPI(action) {
    yield getCommonSaga({
        apiUrl: "reset_password",
        payload: action.payload,
        success: resetPasswordSuccess,
        failure: resetPasswordFailure,
    });
}

function* profileAPI(action) {
    yield getCommonSaga({
        apiUrl: "profile",
        payload: action.payload,
        successNotify: false,
        successCallback: true,
        success: data => store.dispatch(profileSuccess(data.user)),
        failure: profileFailure,
    });
}

function* updateProfileAPI(action) {
    yield getCommonSaga({
        apiUrl: "update_profile",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(profileSuccess(data.user));
            store.dispatch(updateProfileSuccess(data));
        },
        failure: updateProfileFailure,
    });
}

function* dashboardAPI(action) {
    yield getCommonSaga({
        apiUrl: "dashboard",
        payload: action.payload,
        successNotify: false,
        success: dashboardSuccess,
        failure: dashboardFailure,
    });
}

function* dashboardChartAPI(action) {
    yield getCommonSaga({
      apiUrl: "dashboard_chart",
      payload: action.payload,
      successNotify: false,
      success: dashboardChartSuccess,
      failure: dashboardChartFailure,
    });
  }

function* viewAPIKeyAPI(action) {
    yield getCommonSaga({
        apiUrl: "view_central_api_key",
        payload: action.payload,
        successNotify: false,
        success: viewAPIKeySuccess,
        failure: viewAPIKeyFailure,
    });
}

function* changePasswordAPI(action) {
    yield getCommonSaga({
        apiUrl: "change_password",
        payload: action.payload,
        successCallback: true,
        success: data => store.dispatch(changePasswordSuccess({ data: data })),
        failure: changePasswordFailure,
    });
}

function* verifyForgotPasswordAPI(action) {
    yield getCommonSaga({
        apiUrl: "verify_forgot_password_code",
        payload: action.payload,
        success: verifyForgotPasswordSuccess,
        failure: verifyForgotPasswordFailure,
    });
}

function* deleteProfileAPI(action) {
    yield getCommonSaga({
        apiUrl: "delete_account",
        payload: action.payload,
        success: deleteProfileSuccess,
        failure: deleteProfileFailure,
    });
}

function* emailVerifyAPI(action) {
    yield getCommonSaga({
        apiUrl: "verify_email",
        payload: action.payload,
        successCallback: true,
        success: data => {
            // store.dispatch(emailVerifySuccess(data.user));
            store.dispatch(emailVerifySuccess(data));
            if (data.user.email_status === 1) {
                let key = process.env.REACT_APP_DECRYPTION_KEY;
                let encrypted_json = JSON.parse(atob(data.salt_key));
                let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
                iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
                });
                const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

                if (action.payload.remember) {
                    localStorage.setItem("token", data.access_token)
                    localStorage.setItem("api_key", data.api_key)
                    localStorage.setItem("salt_key", salt_key_decrypted)
                }
                else {
                    sessionStorage.setItem("token", data.access_token)
                    sessionStorage.setItem("api_key", data.api_key)
                    sessionStorage.setItem("salt_key", salt_key_decrypted)
                }
                store.dispatch(profileSuccess(data.user));
            }
        },
        failure: emailVerifyFailure,
    });
}

function* twoStepAuthAPI(action) {
    yield getCommonSaga({
        apiUrl: "tfa_login",
        payload: action.payload,
        successCallback: true,
        success: data => {
            if (action.payload.remember) {
                localStorage.setItem("token", data.access_token)
            }
            else sessionStorage.setItem("token", data.access_token);
            store.dispatch(twoStepAuthSuccess(data.user));
            // store.dispatch(profileSuccess(data.user));
            if (data.user.email_status === 1) {
                let key = process.env.REACT_APP_DECRYPTION_KEY;
                let encrypted_json = JSON.parse(atob(data.salt_key));
                let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
                iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
                });
                const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

                if (action.payload.remember) {
                    localStorage.setItem("token", data.access_token)
                    localStorage.setItem("api_key", data.api_key)
                    localStorage.setItem("salt_key", salt_key_decrypted)
                }
                else {
                    sessionStorage.setItem("token", data.access_token)
                    sessionStorage.setItem("api_key", data.api_key)
                    sessionStorage.setItem("salt_key", salt_key_decrypted)
                }
                store.dispatch(profileSuccess(data.user));
            }
        },
        failure: twoStepAuthFailure,
    });
}

function* updateTwoStepAPI(action) {
    yield getCommonSaga({
        apiUrl: "tfa_status",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(updateTwoStepSuccess(data));
            store.dispatch(profileSuccess(data.user));
        },
        failure: updateTwoStepFailure,
    });
}

function* resendOtpAPI(action) {
    yield getCommonSaga({
        apiUrl: "send_verification_code",
        payload: action.payload,
        success: resendOtpSuccess,
        failure: resendOtpFailure,
    });
}

function* updateEmailCodeAPI(action) {
    yield getCommonSaga({
        apiUrl: "update_email_verification_code",
        payload: action.payload,
        success: updateEmailCodeSuccess,
        failure: updateEmailCodeFailure,
    });
}

function* updateEmailAPI(action) {
    yield getCommonSaga({
        apiUrl: "update_email",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(profileSuccess(data.user));
            store.dispatch(updateEmailSuccess(data));
        },
        failure: updateEmailFailure,
    });
}


function* updatePersonalInformationAPI(action) {
    yield getCommonSaga({
        apiUrl: "update_user_info",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(updatePersonalInformationSuccess(data));
            store.dispatch(profileSuccess(data.user));
        },
        failure: updatePersonalInformationFailure,
    });
}

function* updateAddressAPI(action) {
    yield getCommonSaga({
        apiUrl: "update_address",
        payload: action.payload,
        successCallback: true,
        success: data => {
            store.dispatch(updateAddressSuccess(data));
            store.dispatch(profileSuccess(data.user));
        },
        failure: updateAddressFailure,
    });
}

function* verifyKycAPI(action) {
    yield getCommonSaga({
        apiUrl: "kyc_status",
        payload: action.payload,
        successCallback: true,
        successNotify: false,
        success: data => {
            store.dispatch(verifyKycSuccess(data));
            store.dispatch(profileSuccess(data.user));
        },
        failure: verifyKycFailure,
    });
}

function* tfaEnableAPI(action) {
    yield getCommonSaga({
        apiUrl: "tfa_enable",
        payload: action.payload,
        success: tfaEnableSuccess,
        failure: tfaEnableFailure,
    });
}

function* tfaStatusUpdateAPI(action) {
    yield getCommonSaga({
        apiUrl: "tfa_status",
        payload: action.payload,
        successNotify: true,
        successCallback: true,
        success: data => {
            store.dispatch(tfaStatusUpdateSuccess(data));
            store.dispatch(profileSuccess(data.user))
            // if(data.salt_key){
            //     if (data.user.email_status === 1) {
            //         if(data.user.tfa_status === 1){
            //             let key = process.env.REACT_APP_DECRYPTION_KEY;
            //             let encrypted_json = JSON.parse(atob(data.salt_key));
            //             let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
            //             iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
            //             });
            //             const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

            //             if (action.payload.remember) {
            //                 localStorage.setItem("token", data.access_token)
            //                 localStorage.setItem("api_key", data.api_key)
            //                 localStorage.setItem("salt_key", salt_key_decrypted)
            //             }
            //             else {
            //                 sessionStorage.setItem("token", data.access_token)
            //                 sessionStorage.setItem("api_key", data.api_key)
            //                 sessionStorage.setItem("salt_key", salt_key_decrypted)
            //             }
            //             store.dispatch(profileSuccess(data.user));
            //         }
            //     }
            //     store.dispatch(profileSuccess(data.user));
            // } else {
            //     store.dispatch(profileSuccess(data.user));
            // }
        },
        failure: tfaStatusUpdateFailure,
    });
}

function* tfaAuthenticationAPI(action) {
    yield getCommonSaga({
        apiUrl: "tfa_update",
        payload: action.payload,
        successNotify: true,
        successCallback: true,
        success: data => {
            store.dispatch(tfaAuthenticationSuccess(data));
            if(data.salt_key){
                if (data.user.email_status === 1) {
                    if(data.user.tfa_status === 1){
                        let key = process.env.REACT_APP_DECRYPTION_KEY;
                        let encrypted_json = JSON.parse(atob(data.salt_key));
                        let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
                        iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
                        });
                        const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

                        if (action.payload.remember) {
                            localStorage.setItem("token", data.access_token)
                            localStorage.setItem("api_key", data.api_key)
                            localStorage.setItem("salt_key", salt_key_decrypted)
                        }
                        else {
                            sessionStorage.setItem("token", data.access_token)
                            sessionStorage.setItem("api_key", data.api_key)
                            sessionStorage.setItem("salt_key", salt_key_decrypted)
                        }
                        store.dispatch(profileSuccess(data.user));
                    }
                }
                store.dispatch(profileSuccess(data.user));
            } else {
                store.dispatch(profileSuccess(data.user));
            }
        },
        failure: tfaAuthenticationFailure,
    });
}

function* tourStatusUpdateAPI(action) {
    yield getCommonSaga({
        apiUrl: "tour_status",
        payload: action.payload,
        successNotify: false,
        successCallback: true,
        success: data => {
            store.dispatch(tourStatusUpdateSuccess(data));
            store.dispatch(profileSuccess(data.user));
        },
        failure: tourStatusUpdateFailure,
    });
}

function* updateServiceTypeAPI(action) {
    yield getCommonSaga({
        apiUrl: "service_types",
        payload: action.payload,
        successNotify: true,
        successCallback: true,
        success: data => {
            store.dispatch(updateServiceTypeSuccess(data));
            store.dispatch(profileSuccess(data.user));
        },
        failure: updateServiceTypeFailure,
    });
}

function* logoutAPI(action) {
    yield getCommonSaga({
        apiUrl: "logout",
        payload: action.payload,
        successNotify: false,
        successCallback: true,
        success: data => {
            store.dispatch(logoutSuccess(data));
        },
        failure: logoutFailure,
    });
}

function* updateOnboardingDetailsAPI(action) {
    yield getCommonSaga({
        apiUrl: "sync_account",
        payload: action.payload,
        successNotify: true,
        successCallback: true,
        success: data => {
            store.dispatch(updateOnboardingDetailsSuccess(data))
            store.dispatch(profileSuccess(data.user));
        },
        failure: updateOnboardingDetailsFailure,
    });
}

function* countriesListAPI(action) {
    yield getCommonSaga({
        apiUrl: "countries",
        payload: action.payload,
        successNotify: false,
        success: countriesListSuccess,
        failure: countriesListFailure,
    });
}

function* nationalitiesListAPI(action) {
    yield getCommonSaga({
        apiUrl: "nationalities",
        payload: action.payload,
        successNotify: false,
        success: nationalitiesListSuccess,
        failure: nationalitiesListFailure,
    });
}

function* mobileCodeListAPI(action) {
    yield getCommonSaga({
        apiUrl: "mobile_country_codes",
        payload: action.payload,
        successNotify: false,
        success: mobileCodeListSuccess,
        failure: mobileCodeListFailure,
    });
}

function* acceptInviteLinkAPI(action) {
    yield getCommonSaga({
        apiUrl: "accept_invite_link",
        payload: action.payload,
        successCallback: true,
        success: data => {
            if (action.payload.remember) {
                localStorage.setItem("token", data.access_token)
            }
            else sessionStorage.setItem("token", data.access_token);
            store.dispatch(acceptInviteLinkSuccess(data.user));
            if (data.user.email_status === 1) {
                let key = process.env.REACT_APP_DECRYPTION_KEY;
                let encrypted_json = JSON.parse(atob(data.salt_key));
                let decrypted = CryptoJS.AES.decrypt(encrypted_json.value, CryptoJS.enc.Base64.parse(key), {
                iv: CryptoJS.enc.Base64.parse(encrypted_json.iv)
                });
                const salt_key_decrypted = JSON.parse(decrypted.toString(CryptoJS.enc.Utf8));

                if (action.payload.remember) {
                    localStorage.setItem("token", data.access_token)
                    localStorage.setItem("api_key", data.api_key)
                    localStorage.setItem("salt_key", salt_key_decrypted)
                }
                else {
                    sessionStorage.setItem("token", data.access_token)
                    sessionStorage.setItem("api_key", data.api_key)
                    sessionStorage.setItem("salt_key", salt_key_decrypted)
                }
                store.dispatch(profileSuccess(data.user));
            }
        },
        failure: acceptInviteLinkFailure,
    });
}

export default function* AdminSaga() {
    yield all([
        yield takeLatest('admin/loginStart', loginAPI),
        yield takeLatest('admin/registerStart', registerAPI),
        yield takeLatest('admin/forgotPasswordStart', forgotPasswordAPI),
        yield takeLatest('admin/resetPasswordStart', resetPasswordAPI),
        yield takeLatest('admin/profileStart', profileAPI),
        yield takeLatest('admin/updateProfileStart', updateProfileAPI),
        yield takeLatest('admin/dashboardStart', dashboardAPI),
        yield takeLatest('admin/dashboardChartStart', dashboardChartAPI),
        yield takeLatest('admin/viewAPIKeyStart', viewAPIKeyAPI),
        yield takeLatest('admin/changePasswordStart', changePasswordAPI),
        yield takeLatest('admin/verifyForgotPasswordStart', verifyForgotPasswordAPI),
        yield takeLatest('admin/deleteProfileStart', deleteProfileAPI),
        yield takeLatest('admin/emailVerifyStart', emailVerifyAPI),
        yield takeLatest('admin/twoStepAuthStart', twoStepAuthAPI),
        yield takeLatest('admin/updateTwoStepStart', updateTwoStepAPI),
        yield takeLatest('admin/resendOtpStart', resendOtpAPI),
        yield takeLatest('admin/updateEmailCodeStart', updateEmailCodeAPI),
        yield takeLatest('admin/updateEmailStart', updateEmailAPI),
        yield takeLatest('admin/updatePersonalInformationStart', updatePersonalInformationAPI),
        yield takeLatest('admin/updateAddressStart', updateAddressAPI),
        yield takeLatest('admin/verifyKycStart', verifyKycAPI),
        yield takeLatest('admin/tfaEnableStart', tfaEnableAPI),
        yield takeLatest('admin/tfaStatusUpdateStart', tfaStatusUpdateAPI),
        yield takeLatest('admin/tfaAuthenticationStart', tfaAuthenticationAPI),
        yield takeLatest('admin/tourStatusUpdateStart', tourStatusUpdateAPI),
        yield takeLatest('admin/updateServiceTypeStart', updateServiceTypeAPI),
        yield takeLatest('admin/logoutStart', logoutAPI),
        yield takeLatest('admin/countriesListStart', countriesListAPI),
        yield takeLatest('admin/nationalitiesListStart', nationalitiesListAPI),
        yield takeLatest('admin/mobileCodeListStart', mobileCodeListAPI),
        yield takeLatest('admin/acceptInviteLinkStart', acceptInviteLinkAPI),
        yield takeLatest('admin/updateOnboardingDetailsStart', updateOnboardingDetailsAPI),
    ]);
}

