import React, { useEffect, useRef, useState } from "react";
import { Table, Button, Form, Row, Col, Image } from "react-bootstrap";
import { InputGroup } from "react-bootstrap";
import { Field, Form as FORM, Formik, ErrorMessage } from "formik";
import Select from "react-select";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useTranslation } from "react-multi-lang";
import { sendersListStart } from "../../store/slices/SendersSlice";
import * as Yup from "yup";
import { ButtonLoader } from "../Helper/Loader";
import { setSendMoneyData } from "../../store/slices/BeneficiariesSlice";
import CustomLazyLoad from "../Helper/CustomLazyLoad";
import Skeleton from "react-loading-skeleton";
import NoDataFoundNew from "../Helper/NoDataFoundNew";

const SelectSender = (props) => {

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const formRef = useRef();
  const t = useTranslation("quote.quote_transfer");

  const sendersList = useSelector((state) => state.senders.sendersList);
  const [senders, setSenders] = useState([]);
  const [selectedOption, setSelectedOption] = useState(null);
  const createTransaction = useSelector((state) => state.beneficiaries.createTransaction);
  const sendMoneyData = useSelector((state) => state.beneficiaries.sendMoneyData);

  const [selectedSender, setSelectedSender] = useState("");
  const [skipRender, setSkipRender] = useState(true);

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 250,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E5E8EF!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      minWidth: "180px",
      cursor: "pointer",
      fontSize: "0.95em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.95em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#0194ff",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.95em",
      fontWeight: "600",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#878E96",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isSelected
        ? "#0194ff"
        : state.isFocused
          ? "#f0f0f0"
          : "transparent",
      color: state.isSelected ? "#fff" : state.isFocused ? "#000" : "#000",
      ":hover": {
        backgroundColor: "#f0f0f0",
        color: "#000",
      },
    }),
  };

  const validationSchema = Yup.object().shape({
    sender_id: Yup.number().required(t("required")),
  });

  useEffect(() => {
    if(!sendersList.buttonDisable && !Object.keys(sendersList.data).length > 0 && props.transferData){
      dispatch(
        sendersListStart({
          receiving_currency: props.transferData.receiving_currency,
          recipient_type: props.transferData.recipient_type,
        })
      );
    }
  }, [props]);

  useEffect(() => {
    if (
      // !skipRender &&
      !sendersList.loading &&
      Object.keys(sendersList.data).length > 0
    ) {
      sendersList.data.total_senders > 0 &&
        setSenders(
          sendersList.data.senders.map((data) => ({
            label: `${
              data.first_name + " " + data.middle_name + " " + data.last_name
            }`,
            value: data.id,
          }))
        );
    }
    setSkipRender(false);
  }, [sendersList]);

  const handleSubmit = (values) => {
    props.setTransferData({
      ...props.transferData,
      ...values,
    });
    props.setStep(props.step + 1);
    dispatch(setSendMoneyData({
      sender_id: values.sender_id,
    }))
  };

  useEffect(()=> {
    if (!sendersList.loading && Object.keys(sendersList.data).length > 0) {
      if(sendMoneyData.sender_id){
        setSelectedSender(sendersList.data.senders.find((item)=> item.id == sendMoneyData.sender_id))
      }
    }
  }, [sendMoneyData, sendersList])

  useEffect(() => {
    const handleKeyPress = (event) => {
      if (event.key === "Enter") {
        if(selectedSender || sendMoneyData.sender_id){
          handleSubmit(formRef?.current?.values) 
        } 
      }
    };
    window.addEventListener("keydown", handleKeyPress);
    return () => {
      window.removeEventListener("keydown", handleKeyPress);
    };
  }, [selectedSender, sendMoneyData]);

  return (
    <div className="select-sender">
      <div className="efi-feature-header-frame layout-between">
        <div className="single-transaction-back">
          <Button
            className="back-btn"
            onClick={(e) => {
              if (e.key === "Enter") {
                e.preventDefault(); // Prevent the default action when pressing Enter
                return;
              }
              props.setStep(props.step - 1);
            }}
            // disabled={updateAddress.buttonDisable}
            onKeyDown={(e) => {
              if (e.key === "Enter") {
                e.preventDefault();
              }
            }}
            disabled={createTransaction.buttonDisable}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="none"
              viewBox="0 0 12 18"
            >
              <path
                stroke="#000"
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="3"
                d="M10 1.5L2 9l8 7.5"
              ></path>
            </svg>
          </Button>
        </div>
        <div className="single-transaction-back">
          <h3>{t("sender.placeholder")}</h3>
        </div>
        <div className="efi-feature-transation-search-frame">
          <Button
            className="efi-primary-btn"
            onClick={() => navigate("/add-sender")}
            disabled={sendersList.buttonDisable}
          >
            {t("add_sender")}
          </Button>
        </div>
      </div>
      <div className="mb-4">
        <Formik
          initialValues={{
            sender_id: sendMoneyData.sender_id || "",
            quote_id: props.transferData.quote.id,
          }}
          validationSchema={validationSchema}
          onSubmit={handleSubmit}
          innerRef={formRef}
        >
          {({ setFieldValue, values, touched, errors, resetForm }) => (
            <FORM className="efi-bank-default-form w-100">
              <Row>
                <Col md={12}>
                  <Form.Group className="mb-3">
                    <Form.Label className="default-form-label w-100">
                      {t("sender.name")} <span>*</span>
                    </Form.Label>
                    {sendersList.buttonDisable ? <Skeleton width={"100%"} height={40} /> : Object.keys(sendersList.data).length > 0 ? 
                    <Select
                      options={senders}
                      styles={customStyles}
                      value={
                        selectedSender ? 
                          {
                            label: `${selectedSender.first_name + " " + selectedSender.middle_name + " " + selectedSender.last_name}`,
                            value: selectedSender.id 
                          }
                        : null}
                      classNamePrefix="react-select"
                      onChange={(data) => {
                        data !== null &&
                        setSelectedSender(sendersList.data.senders.find((sender) => sender.id === data.value ));
                        setFieldValue("sender_id", data.value);
                      }}
                      placeholder={t("sender.placeholder")}
                      isDisabled={sendersList.buttonDisable}
                      isSearchable
                    /> : <NoDataFoundNew/> }
                    <ErrorMessage
                      component={"div"}
                      name="sender_id"
                      className="errorMsg"
                    />
                  </Form.Group>
                </Col>
                <Col md={12} className="mt-3">
                  {selectedSender ? (
                    <div className="select-detail-box mb-3">
                      <div className="beneficiary-detail-card">
                        <div className="beneficiary-detail-info">
                          <p>{t("unique_id")}</p>
                          <h4>{selectedSender.unique_id}</h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("type.name")}</p>
                          <h4>{selectedSender.type_formatted}</h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("account_holder_name")}</p>
                          <h4>{`${
                            selectedSender.first_name +
                            " " +
                            selectedSender.middle_name +
                            " " +
                            selectedSender.last_name
                          }`}</h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("address")}</p>
                          <h4>{selectedSender.address}</h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("mobile")}</p>
                          <h4>
                            {"+" +
                              selectedSender.mobile_country_code +
                              " " +
                              selectedSender.mobile}
                          </h4>
                        </div>
                        <div className="beneficiary-detail-info">
                          <p>{t("created_at")}</p>
                          <h4>{selectedSender.created_at_formatted}</h4>
                        </div>
                      </div>
                    </div>
                  ) : <div className="single-transaction-box-avater">
                  <CustomLazyLoad
                    className="trans-beneficiary-avater"
                    src={window.location.origin + "/img/beneficiary-select.svg"}
                    type="image/png"
                  />
                </div>}
                </Col>
              </Row>
              <div className="onboarding-auth-footer mt-3">
                <Button
                  className="efi-primary-btn w-100"
                  type="submit"
                  disabled={createTransaction.buttonDisable}
                >
                  {createTransaction.buttonDisable ? <ButtonLoader /> : t("confirm_sender")}
                </Button>
              </div>
            </FORM>
          )}
        </Formik>
      </div>
    </div>
  );
};

export default SelectSender;
