import React, { useEffect, useState, useRef } from "react";
import { Button, Modal, Image } from "react-bootstrap";
import { Link } from "react-router-dom";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import { useTranslation } from "react-multi-lang";

function BusinessSucessModal(props) {

  const t = useTranslation("business.create.success");

  return (
    <Modal
      {...props}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="business-modal"
      backdrop="static"
    >
      <Modal.Header></Modal.Header>
      <Modal.Body className="pt-0">
        <div className="change-password-form-heading mb-3">
          <LazyLoadImage
            effect="blur"
            className="modal-popup-icon"
            src={window.location.origin + "/img/businessinfo.svg"}
            type="image/png"
          />
        </div>
        <h3 className="change-password-heading mb-2 text-center">
          {t("title")}
        </h3>
        <p className="text-center">
          {t("new_employee")} “
          {props.show.title +
            " " +
            props.show.first_name +
            " " +
            props.show.last_name}
          ” {t("created_success")}
        </p>
        <div className="efi-bank-auth-form-box mt-4">
          <div className="efi-bank-default-form">
            <div className="efi-auth-signin p-0">
              <Link to="/employee-list" className="efi-primary-btn">
               {t("continue")}
              </Link>
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
}

export default BusinessSucessModal;
