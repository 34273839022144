import React, { useEffect, useRef, useState } from "react";
import { InputGroup, Row, Col, Form, Button, Image } from "react-bootstrap";
import { Link, useNavigate } from "react-router-dom";
import { Formik, Form as FORM, Field, ErrorMessage } from "formik";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import DatePicker from "react-multi-date-picker";
import { useTranslation } from "react-multi-lang";
import { mobileCodeListStart, nationalitiesListStart, updatePersonalInformationStart } from "../../../store/slices/AdminSlice";
import { ButtonLoader } from "../../Helper/Loader";
import { countries } from "countries-list";
import Select from "react-select";
import { LazyLoadImage } from "react-lazy-load-image-component";
import "react-lazy-load-image-component/src/effects/blur.css";
import PhoneInput, {
  formatPhoneNumberIntl,
  isValidPhoneNumber,
  isPossiblePhoneNumber,
  getCountries,
} from "react-phone-number-input";
import Skeleton from "react-loading-skeleton";

const PersonalInformation = (props) => {

  const navigate = useNavigate();
  const formRef = useRef();
  const dispatch = useDispatch();
  const t = useTranslation("onboarding.onboarding_form.personal_information");

  const businessInfoUpdate = useSelector((state) => state.business.businessInfoUpdate);
  const updatePersonalInformation = useSelector((state) => state.admin.updatePersonalInformation);
  const profile = useSelector((state) => state.admin.profile);
  const nationalitiesList = useSelector((state) => state.admin.nationalitiesList);
  const mobileCodeList = useSelector((state) => state.admin.mobileCodeList);

  const [skipRender, setSkipRender] = useState(true);
  const [close, setClose] = useState(false);
  const [value, setValue] = useState();
  const [countryOptions, setCountryOptions] = useState([])
  const [filteredCountries, setFilteredCountries] = useState([])

  const validationSchema = Yup.object().shape({
    dob: Yup.string().required(t("required")),
    mobile: Yup.string()
      .required(t("mobile.required"))
      .matches(/^(?=.*[0-9])/, t("mobile.invalid"))
      .min(10, t("mobile.min_length_invalid"))
      .max(15, t("mobile.max_length_invalid")),
    gender: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    nationality: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    service_type: Yup.string().required(t("required")),
    user_type: Yup.string().when("service_type", {
      is: "2",
      then: Yup.string().required(t("required")),
      otherwise: Yup.string(),
    }),
  });

  const businessValidationSchema = Yup.object().shape({
    dob: Yup.string().required(t("required")),
    gender: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
    nationality: Yup.string()
      .required(t("required"))
      .matches(/^\S.*$/, t("required"))
      .trim(),
  });

  const customStyles = {
    menuPortal: (provided) => ({ ...provided, zIndex: 9999 }),
    menu: (provided) => ({
      ...provided,
      zIndex: 9999,
      left: "0px",
      borderRadius: "8px",
      overflow: "hidden",
    }),
    menuList: (provided) => ({
      ...provided,
      padding: 0,
      minWidth: 450,
      fontSize: "0.85em",
      "&::-webkit-scrollbar-track": {
        boxShadow: "inset 0 0 6px rgba(0,0,0,0.3)",
        borderRadius: "3px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar": {
        width: "4px",
        backgroundColor: "#fff",
      },
      "&::-webkit-scrollbar-thumb": {
        borderRadius: "3px",
        boxShadow: "inset 0 0 6px rgba(0, 0, 0, .3)",
        backgroundColor: "#555",
      },
    }),
    container: (provided) => ({ ...provided, width: "auto" }),
    control: (provided) => ({
      ...provided,
      backgroundColor: "transparent!important",
      border: "1px solid #E7E7E7!important",
      borderRadius: "8px!important",
      boxShadow: "none!important",
      height: "45px",
      display: "flex",
      alignItems: "center",
      alignItemsContent: "center",
      cursor: "pointer",
      fontSize: "0.9em",
    }),
    placeholder: (provided) => ({
      ...provided,
      color: "#757575",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    singleValue: (provided) => ({
      ...provided,
      color: "#000",
      display: "flex",
      alignItems: "center",
      gap: "0.5em",
      fontSize: "0.9em",
      fontWeight: "400",
    }),
    indicatorContainer: (provided) => ({
      ...provided,
      color: "#0194ff!important",
    }),
    indicatorSeparator: (base) => ({
      ...base,
      display: "none",
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      svg: {
        fill: "#111",
      },
    }),
    option: (provided, state) => ({
      ...provided,
      backgroundColor: state.isFocused || state.isSelected ? "#0194ff" : "#fff",
      color: state.isFocused || state.isSelected ? "#fff" : "#000",
      ":hover": {
        backgroundColor: "#0194ff",
        color: "#fff",
      },
    }),
  };

  const validateMobileNumber = (value) => {
    if (value) {
      if (isPossiblePhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else if (isValidPhoneNumber(value) == false) {
        formRef.current.setFieldError("mobile", t("mobile.invalid"));
        return false;
      } else {
        return true;
      }
    } else {
      formRef.current.setFieldError("mobile", t("mobile.required"));
      return false;
    }
  };

  const handleSubmit = (values) => {
    if (validateMobileNumber(values.mobile)) {
      const intlNo = formatPhoneNumberIntl(values.mobile);
      const countryCode = intlNo.substring(
        intlNo.indexOf("+") + 1,
        intlNo.indexOf(" ")
      );
      const mobile = intlNo
        .substring(intlNo.indexOf(" "), intlNo.length)
        .replaceAll(" ", "");

      // const invalidCodes = [
      //   "93", // Afghanistan (AF)
      //   "375", // Belarus (BY)
      //   "236", // Central African Republic (CF)
      //   "86", // China (CN)
      //   "53", // Cuba (CU)
      //   "243", // Democratic Republic of the Congo (CD)
      //   "251", // Ethiopia (ET)
      //   "91", // India (IN)
      //   "98", // Iran (IR)
      //   "964", // Iraq (IQ)
      //   "218", // Libya (LY)
      //   "223", // Mali (ML)
      //   "505", // Nicaragua (NI)
      //   "850", // North Korea (KP)
      //   "252", // Somalia (SO)
      //   "211", // South Sudan (SS)
      //   "249", // Sudan (SD)
      //   "963", // Syria (SY)
      //   "1", // United States (US)
      //   "58", // Venezuela (VE)
      //   "967", // Yemen (YE)
      //   "263", // Zimbabwe (ZW)
      // ];

      // if (invalidCodes.includes(countryCode)) {
      //   formRef.current.setFieldError("mobile", t("mobile.invalid"));
      //   return;
      // }

      dispatch(
        updatePersonalInformationStart(
          values.service_type == 1
            ? {
                ...values,
                dob: values.dob.split("-").reverse().join("-"),
                mobile: mobile,
                mobile_country_code: countryCode,
                user_type: 1,
              }
            : {
                ...values,
                dob: values.dob.split("-").reverse().join("-"),
                mobile: mobile,
                mobile_country_code: countryCode,
              }
        )
      );
    }
  };

  useEffect(() => {
    if (
      !skipRender &&
      !updatePersonalInformation.loading &&
      Object.keys(updatePersonalInformation.data).length > 0
    ) {
      props.setStep(props.step + 1);
    }
    setSkipRender(false);
  }, [updatePersonalInformation]);

  const today = new Date();
  const maxDate = new Date(
    today.getFullYear() - 18,
    today.getMonth(),
    today.getDate()
  );
  maxDate.setDate(maxDate.getDate() - 1);
  maxDate.setHours(23, 59, 59, 999);

  const customFilterOption = (option, inputValue) => {
    return option.label.toLowerCase().startsWith(inputValue.toLowerCase());
  };

  const eighteenYearsAgo = new Date();
  eighteenYearsAgo.setFullYear(eighteenYearsAgo.getFullYear() - 18);

  useEffect(()=> {
    dispatch(nationalitiesListStart({
      all: 1,
    }));
    dispatch(mobileCodeListStart())
  }, []);

  useEffect(()=> {
    if(!skipRender && !nationalitiesList.buttonDisable && Object.keys(nationalitiesList.data).length > 0){
      if(nationalitiesList.data.nationalities.length > 0){
        setCountryOptions(nationalitiesList.data.nationalities.map((item)=> ({
          value: item.alpha_2_code,
          label: item.country_name,
        })))
      }
    }
    setSkipRender(false);
  }, [nationalitiesList]);

  useEffect(()=> {
    if(!mobileCodeList.buttonDisable && Object.keys(mobileCodeList.data).length > 0){
      if(mobileCodeList.data.mobile_country_codes.length > 0){
        setFilteredCountries(mobileCodeList.data.mobile_country_codes)
      }
    }
  }, [mobileCodeList])
  
  return (
    <div className="onboarding-auth">
      <div className="onboarding-auth-header resp-mrg-btm-xs">
        <div className="onboarding-auth-titles">
          <h3>{t("heading")}</h3>
          <p>{t("content")}</p>
        </div>
        <Button
          className="efi-overlay-btn"
          onClick={() => navigate("/")}
          disabled={businessInfoUpdate.buttonDisable}
        >
          {t("skip_onboarding")}
        </Button>
      </div>
      <div className="feature-grid-layout-two">
        <div>
          <Formik
            initialValues={{
              dob: profile.data.dob.split("-").reverse().join("-") || "",
              gender: profile.data.gender,
              nationality: profile.data.nationality,
              service_type: profile.data.service_type || 2,
              user_type: profile.data.user_type || "",
              mobile: `+${profile.data.mobile_country_code+ profile.data.mobile}` || ""
            }}
            validationSchema={
              profile.data.is_sub_user == 0
                ? validationSchema
                : businessValidationSchema
            }
            onSubmit={handleSubmit}
            innerRef={formRef}
          >
            {({ setFieldValue, values, touched, errors }) => (
              <FORM className="efi-bank-default-form w-100">
                <Row>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("gender")} <span>*</span>
                      </Form.Label>
                      <div className="gender-check-box">
                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            name="flexRadioDefault"
                            id="male"
                            checked={values.gender == "M"}
                            onChange={() => setFieldValue("gender", "M")}
                          />
                          <div className="state p-primary-o">
                            <label> {t("male")}</label>
                          </div>
                        </div>

                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            name="flexRadioDefault"
                            id="female"
                            checked={values.gender == "F"}
                            onChange={() => setFieldValue("gender", "F")}
                          />
                          <div className="state p-primary-o">
                            <label> {t("female")}</label>
                          </div>
                        </div>
                        <div className="pretty p-default p-round">
                          <input
                            type="radio"
                            name="flexRadioDefault"
                            id="other"
                            checked={values.gender == "O"}
                            onChange={() => setFieldValue("gender", "O")}
                          />
                          <div className="state p-primary-o">
                            <label> {t("others")}</label>
                          </div>
                        </div>
                      </div>
                      <ErrorMessage
                        component={"div"}
                        name="gender"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    {mobileCodeList.loading ? <div className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("mobile.label")} <span>*</span>
                      </Form.Label>
                      <Skeleton height={40} width="100%" />
                      </div> : (Object.keys(mobileCodeList.data).length > 0 && mobileCodeList.data.mobile_country_codes.length > 0) ?
                      <Form.Group className="mb-3 efi-bank-auth-input-group">
                      <Form.Label className="default-form-label w-100">
                        {t("mobile.label")} <span>*</span>
                      </Form.Label>
                      <InputGroup
                        className={`personal-information-form form-control`}
                      >
                        <PhoneInput
                          name="mobile"
                          value={values.mobile}
                          placeholder={t("mobile.placeholder")}
                          onChange={(value) => {
                            setFieldValue("mobile", value);
                          }}
                          // onBlur={() => formRef.current.submitForm()}
                          international
                          countries={mobileCodeList?.data?.mobile_country_codes}
                          className={`${
                            touched.mobile && errors.mobile ? "is-invalid" : ""
                          }`}
                          // maxLength={17}
                        />
                      </InputGroup>
                      <ErrorMessage
                        component={"div"}
                        name="mobile"
                        className="errorMsg"
                      />
                    </Form.Group>
                    : null}
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("dob.name")} <span>*</span>
                      </Form.Label>
                      <div className="single-date-picker">
                        <InputGroup className="mb-0">
                          <DatePicker
                            minDate={new Date("1900-01-01")}
                            maxDate={maxDate}
                            value={values.dob}
                            onChange={(e) => {
                              setFieldValue(
                                "dob",
                                e
                                  ? `${e.day < 10 ? "0" + e.day : e.day}-${
                                      e.month < 10 ? "0" + e.month : e.month
                                    }-${e.year}`
                                  : ""
                              );
                              setClose(true);
                            }}
                            onOpen={() =>
                              setFieldValue(
                                "dob",
                                values.dob
                                  ? values.dob
                                  : new Date(maxDate)
                                      .toISOString()
                                      .split("T")[0]
                                      .toString()
                                      .split("-")
                                      .reverse()
                                      .join("-")
                              )
                            }
                            placeholder={t("dob.placeholder")}
                            width="100%"
                            format="DD-MM-YYYY"
                            id="date-picker-input"
                            editable={false}
                            onOpenPickNewDate={false}
                            onFocus={(e) => e.target.blur()}
                            className="hide-caret"
                          ></DatePicker>

                          <InputGroup.Text
                            onClick={() => {
                              const input =
                                document.getElementById("date-picker-input");
                              if (input) {
                                input.focus();
                              }
                            }}
                          >
                            {close ? (
                              <>
                                <button
                                  type="button"
                                  className="search-btn date-cancel"
                                  onClick={() => {
                                    setFieldValue("dob", "");
                                    setClose(false);
                                  }}
                                >
                                  <svg
                                    height="20"
                                    width="20"
                                    viewBox="0 0 20 20"
                                    aria-hidden="true"
                                    focusable="false"
                                    class="css-tj5bde-Svg"
                                  >
                                    <path d="M14.348 14.849c-0.469 0.469-1.229 0.469-1.697 0l-2.651-3.030-2.651 3.029c-0.469 0.469-1.229 0.469-1.697 0-0.469-0.469-0.469-1.229 0-1.697l2.758-3.15-2.759-3.152c-0.469-0.469-0.469-1.228 0-1.697s1.228-0.469 1.697 0l2.652 3.031 2.651-3.031c0.469-0.469 1.228-0.469 1.697 0s0.469 1.229 0 1.697l-2.758 3.152 2.758 3.15c0.469 0.469 0.469 1.229 0 1.698z"></path>
                                  </svg>
                                </button>
                              </>
                            ) : (
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="18"
                                height="18"
                                fill="none"
                                viewBox="0 0 18 18"
                              >
                                <g clipPath="url(#clip0_47_6)">
                                  <path
                                    fill="#8F9899"
                                    d="M14.525 1.386h-.672v-.68a.703.703 0 00-1.405 0v.68H5.552v-.68a.703.703 0 10-1.405 0v.68h-.672A3.48 3.48 0 000 4.861v9.66a3.48 3.48 0 003.475 3.475h11.05A3.48 3.48 0 0018 14.521V4.86a3.48 3.48 0 00-3.475-3.475zM3.475 2.792h.672v1.37a.703.703 0 101.405 0v-1.37h6.896v1.37a.703.703 0 101.405 0v-1.37h.672c1.141 0 2.07.928 2.07 2.07v.67H1.404v-.67c0-1.142.929-2.07 2.07-2.07zm11.05 13.798H3.475a2.072 2.072 0 01-2.07-2.07V6.939h15.19v7.583c0 1.141-.929 2.07-2.07 2.07zM6.243 9.703a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.406h1.383c.388 0 .702.315.702.703zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.406h1.382c.388 0 .703.315.703.703zm-4.147 4.146a.703.703 0 01-.702.703H4.158a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm8.301 0a.703.703 0 01-.702.703h-1.383a.703.703 0 110-1.405h1.383c.388 0 .702.314.702.702zm-4.154 0a.703.703 0 01-.703.703H8.305a.703.703 0 110-1.405h1.382c.388 0 .703.314.703.702z"
                                  ></path>
                                </g>
                                <defs>
                                  <clipPath id="clip0_47_6">
                                    <path fill="#fff" d="M0 0H18V18H0z"></path>
                                  </clipPath>
                                </defs>
                              </svg>
                            )}
                          </InputGroup.Text>
                        </InputGroup>
                      </div>
                      <ErrorMessage
                        component={"div"}
                        name="dob"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.Group className="mb-3">
                      <Form.Label className="default-form-label w-100">
                        {t("nationality.name")} <span>*</span>
                      </Form.Label>
                      <Select
                        options={countryOptions}
                        styles={customStyles}
                        filterOption={customFilterOption}
                        classNamePrefix="react-select"
                        onChange={(selectedOption) =>
                          setFieldValue("nationality", selectedOption.value)
                        }
                        value={countryOptions.find(
                          (option) => option.value === values.nationality
                        )}
                        isSearchable
                        placeholder={t("nationality.placeholder")}
                      />
                      <ErrorMessage
                        component={"div"}
                        name="nationality"
                        className="errorMsg"
                      />
                    </Form.Group>
                  </Col>
                  {profile.data.is_sub_user == 0 ? (
                    <React.Fragment>
                      <Col md={12}>
                        <Form.Group className="mb-3">
                          <Form.Label className="default-form-label w-100">
                            {t("service_type")} <span>*</span>
                          </Form.Label>
                          <div className="services-card-wrapped">
                            {/* <div class="form-check p-0">
                        <span>{t("card")}</span>
                        <label class="form-check-label" for="1">
                          <input
                            class="form-check-input"
                            type="radio"
                            name="service_type"
                            id="1"
                            disabled={profile.data.service_type}
                            checked={values.service_type == 1}
                            onChange={() => setFieldValue("service_type", 1)}
                          />

                          <div className="service-card-custome">
                            <div className="check-tick">
                              <svg
                                xmlns="http://www.w3.org/2000/svg"
                                width="24"
                                height="24"
                                viewBox="0 0 520 520"
                              >
                                <path
                                  d="M79.423 240.755a47.529 47.529 0 00-36.737 77.522l120.73 147.894a43.136 43.136 0 0036.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 01.128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 00-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 01-15.585.843l-83.94-76.386a47.319 47.319 0 00-31.939-12.438z"
                                  data-name="7-Check"
                                ></path>
                              </svg>
                            </div>
                            <Image
                              src={
                                window.location.origin + "/img/feature/card.svg"
                              }
                              className="card-avater"
                            />
                          </div>
                        </label>
                      </div> */}
                            <div class="form-check p-0">
                              <span>{t("bank")}</span>
                              <label class="form-check-label" for="2">
                                <input
                                  class="form-check-input"
                                  type="radio"
                                  name="service_type"
                                  id="2"
                                  disabled={profile.data.service_type}
                                  checked={values.service_type == 2}
                                  onChange={() =>
                                    setFieldValue("service_type", 2)
                                  }
                                />
                                <div className="service-card-custome">
                                  <div className="check-tick">
                                    <svg
                                      xmlns="http://www.w3.org/2000/svg"
                                      width="24"
                                      height="24"
                                      viewBox="0 0 520 520"
                                    >
                                      <path
                                        d="M79.423 240.755a47.529 47.529 0 00-36.737 77.522l120.73 147.894a43.136 43.136 0 0036.066 16.009c14.654-.787 27.884-8.626 36.319-21.515L486.588 56.773a6.13 6.13 0 01.128-.2c2.353-3.613 1.59-10.773-3.267-15.271a13.321 13.321 0 00-19.362 1.343q-.135.166-.278.327L210.887 328.736a10.961 10.961 0 01-15.585.843l-83.94-76.386a47.319 47.319 0 00-31.939-12.438z"
                                        data-name="7-Check"
                                      ></path>
                                    </svg>
                                  </div>
                                  <Image
                                    src={
                                      window.location.origin +
                                      "/img/feature/bank.svg"
                                    }
                                    className="card-avater"
                                  />
                                </div>
                              </label>
                            </div>
                          </div>
                          <ErrorMessage
                            component={"div"}
                            name="service_type"
                            className="errorMsg"
                          />
                        </Form.Group>
                      </Col>
                      {values.service_type == 2 ? (
                        <Col md={12}>
                          <Form.Group className="mb-3">
                            <Form.Label className="default-form-label w-100">
                              {t("account_type")} <span>*</span>
                            </Form.Label>
                            <div className="gender-check-box">
                              <div className="pretty p-default p-round">
                                <Field
                                  name="user_type"
                                  type="radio"
                                  disabled={profile.data.user_type}
                                  checked={values.user_type == 1}
                                  onChange={() =>
                                    setFieldValue("user_type", "1")
                                  }
                                />
                                <div className="state p-primary-o">
                                  <label>{t("individual")}</label>
                                </div>
                              </div>
                              <div className="pretty p-default p-round">
                                <Field
                                  name="user_type"
                                  type="radio"
                                  disabled={profile.data.user_type}
                                  checked={values.user_type == 2}
                                  onChange={() =>
                                    setFieldValue("user_type", "2")
                                  }
                                />
                                <div className="state p-primary-o">
                                  <label>{t("business")}</label>
                                </div>
                              </div>
                            </div>
                            <ErrorMessage
                              component={"div"}
                              name="user_type"
                              className="errorMsg mt-2"
                            />
                          </Form.Group>
                        </Col>
                      ) : null}
                    </React.Fragment>
                  ) : null}
                </Row>
                <div className="onboarding-auth-footer pt-3">
                  <div className="space-tow">
                    <Button
                      className="efi-primary-btn"
                      type="submit"
                      disabled={updatePersonalInformation.buttonDisable}
                      // onClick={() => props.setStep(3)}
                    >
                      {updatePersonalInformation.buttonDisable ? (
                        <ButtonLoader varient="black" />
                      ) : (
                        t("continue")
                      )}
                    </Button>
                  </div>
                </div>
              </FORM>
            )}
          </Formik>
        </div>
        <div className="efi-bank-feature-form-avater">
          <div className="where-can-we-apply-left-sec">
            <LazyLoadImage
              effect="blur"
              src={
                window.location.origin +
                "/img/feature/onboarding/illustration-one.svg"
              }
              className="illustration-avater"
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PersonalInformation;
