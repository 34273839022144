import { createSlice } from "@reduxjs/toolkit";
import {
  getCommonInitialState,
  getCommonStart,
  getCommonSuccess,
  getCommonFailure,
} from "../utils";

const initialState = {
  sendersList: getCommonInitialState(),
  senderCreate: getCommonInitialState(),
  senderView: getCommonInitialState(),
  b2cTransactionsList: getCommonInitialState(),
  b2cTransactionCreate: getCommonInitialState(),
  b2cTransactionView: getCommonInitialState(),
  senderUpdate: getCommonInitialState(),
  senderDelete: getCommonInitialState(),
  senderDetails: {}
};

const SendersSlice = createSlice({
  name: "senders",
  initialState,
  reducers: {

    sendersListStart: (state) => { state.sendersList = getCommonStart() },
    sendersListSuccess: (state, action) => { state.sendersList = getCommonSuccess(action.payload) },
    sendersListFailure: (state, action) => { state.sendersList = getCommonFailure(action.payload) },

    senderCreateStart: (state) => { state.senderCreate = getCommonStart() },
    senderCreateSuccess: (state, action) => { state.senderCreate = getCommonSuccess(action.payload) },
    senderCreateFailure: (state, action) => { state.senderCreate = getCommonFailure(action.payload) },

    senderViewStart: (state) => { state.senderView = getCommonStart() },
    senderViewSuccess: (state, action) => { state.senderView = getCommonSuccess(action.payload) },
    senderViewFailure: (state, action) => { state.senderView = getCommonFailure(action.payload) },

    b2cTransactionsListStart: (state) => { state.b2cTransactionsList = getCommonStart() },
    b2cTransactionsListSuccess: (state, action) => { state.b2cTransactionsList = getCommonSuccess(action.payload) },
    b2cTransactionsListFailure: (state, action) => { state.b2cTransactionsList = getCommonFailure(action.payload) },

    b2cTransactionCreateStart: (state) => { state.b2cTransactionCreate = getCommonStart() },
    b2cTransactionCreateSuccess: (state, action) => { state.b2cTransactionCreate = getCommonSuccess(action.payload) },
    b2cTransactionCreateFailure: (state, action) => { state.b2cTransactionCreate = getCommonFailure(action.payload) },

    b2cTransactionViewStart: (state) => { state.b2cTransactionView = getCommonStart() },
    b2cTransactionViewSuccess: (state, action) => { state.b2cTransactionView = getCommonSuccess(action.payload) },
    b2cTransactionViewFailure: (state, action) => { state.b2cTransactionView = getCommonFailure(action.payload) },

    senderUpdateStart: (state) => {
      state.senderUpdate = getCommonStart();
    },
    senderUpdateSuccess: (state, action) => {
      state.senderUpdate = getCommonSuccess(action.payload);
    },
    senderUpdateFailure: (state, action) => {
      state.senderUpdate = getCommonFailure(action.payload);
    },

    senderDeleteStart: (state) => {
      state.senderDelete = getCommonStart();
    },
    senderDeleteSuccess: (state, action) => {
      state.senderDelete = getCommonSuccess(action.payload);
    },
    senderDeleteFailure: (state, action) => {
      state.senderDelete = getCommonFailure(action.payload);
    },

    senderDetails: (state, action) => {
      state.senderDetails = action.payload;
    },

  },
});

export const {
  sendersListStart,
  sendersListSuccess,
  sendersListFailure,
  senderCreateFailure,
  senderCreateStart,
  senderCreateSuccess,
  senderViewFailure,
  senderViewStart,
  senderViewSuccess,
  b2cTransactionsListFailure,
  b2cTransactionsListStart,
  b2cTransactionsListSuccess,
  b2cTransactionCreateFailure,
  b2cTransactionCreateStart,
  b2cTransactionCreateSuccess,
  b2cTransactionViewFailure,
  b2cTransactionViewStart,
  b2cTransactionViewSuccess,
  senderUpdateStart,
  senderUpdateSuccess,
  senderUpdateFailure,
  senderDeleteStart,
  senderDeleteSuccess,
  senderDeleteFailure,
  senderDetails
} = SendersSlice.actions;

export default SendersSlice.reducer;
